import React from "react";
import {
  IonCol,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonCheckbox
} from "@ionic/react";
import { useAuthContext } from "../../context/AuthProvider";
import {
  CalendarType,
  CalendarView,
  PlUrlProps,
  ShowDates
} from "./useCalendar";
import { getEnumValues } from "../../data/numberHelpers";
import useTranslation from "../../context/LanguageProvider";
import { ProductionLogStatus } from "../../models/Case";
import useLab from "../../context/LabProvider";

interface Props {
  calendarType: CalendarType;
  setCalendarType: (v: CalendarType) => void;
  showDates: ShowDates;
  onShowDatesChange: (v: ShowDates) => void;
  showGroupEvents: boolean;
  setShowGroupEvents: (v: boolean) => void;
  plUrlProps: PlUrlProps;
  setPlUrlProps: (v: PlUrlProps) => void;
  view: CalendarView;
  setView: (v: CalendarView) => void;
}

const CalendarSettings: React.FC<Props> = ({
  calendarType,
  setCalendarType,
  showDates,
  onShowDatesChange,
  showGroupEvents,
  setShowGroupEvents,
  plUrlProps,
  setPlUrlProps,
  view,
  setView
}) => {
  const { user } = useAuthContext();
  const { employees, tasks } = useLab();
  const { t, tProductionLogStatus } = useTranslation();

  const value = showDates.appointmentDate ? "ad" : "sd";

  return (
    <>
      {user?.doctorId === undefined && (
        <IonRow>
          <IonCol>
            <IonItem className="ion-no-padding">
              <IonLabel position="stacked">{t("display")}</IonLabel>
              <IonSelect
                interface="action-sheet"
                value={calendarType}
                onIonChange={e => setCalendarType(e.detail.value)}
              >
                <IonSelectOption value="cases">
                  {t("calendar.tryoutsAndFinishDates")}
                </IonSelectOption>
                <IonSelectOption value="productionLogs">
                  {t("workLog.title")}
                </IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonCol>
          <IonCol hidden={calendarType !== "cases"}>
            <IonItem className="ion-no-padding">
              <IonLabel position="stacked">
                {t("dates.datesToDisplay")}
              </IonLabel>
              <IonSelect
                interface="action-sheet"
                value={value}
                onIonChange={e => {
                  if (value !== e.target.value) {
                    switch (e.target.value) {
                      case "sd":
                        return onShowDatesChange({
                          appointmentDate: false,
                          shipDate: true
                        });

                      case "ad":
                        return onShowDatesChange({
                          appointmentDate: true,
                          shipDate: false
                        });
                    }
                  }
                }}
              >
                <IonSelectOption value="sd">
                  {t("dates.shipDate")}
                </IonSelectOption>
                <IonSelectOption value="ad">
                  {t("dates.appointmentDate")}
                </IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonCol>
          <IonCol hidden={calendarType !== "cases"}>
            <IonItem>
              <IonCheckbox
                slot="start"
                color="secondary"
                checked={showGroupEvents}
                onIonChange={e => {
                  setShowGroupEvents(e.target.checked);
                }}
              />
              <IonLabel> {t("calendar.groupByTime")}</IonLabel>
            </IonItem>
          </IonCol>
          <IonCol hidden={calendarType === "cases"}>
            <IonItem lines="none" className="ion-margin-none ion-no-padding">
              <IonLabel position="stacked">{t("employee")}</IonLabel>
              <IonSelect
                interface="action-sheet"
                cancelText={t("cancel")}
                value={plUrlProps.employeeId ?? null}
                onIonChange={e => {
                  if (calendarType === "productionLogs")
                    setPlUrlProps({
                      ...plUrlProps,
                      employeeId: e.detail.value
                    });
                }}
              >
                <IonSelectOption key={0} value={null}>
                  {t("all")}
                </IonSelectOption>
                {employees?.map(e => (
                  <IonSelectOption key={e.id} value={e.id}>
                    {`${e.firstName} ${e.lastName}`}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          </IonCol>

          {calendarType === "productionLogs" && tasks && (
            <>
              <IonCol>
                <IonItem
                  lines="none"
                  className="ion-margin-none ion-no-padding"
                >
                  <IonLabel position="stacked">{t("task.title")}</IonLabel>
                  <IonSelect
                    interface="action-sheet"
                    cancelText={t("cancel")}
                    value={plUrlProps?.taskId ? plUrlProps.taskId : null}
                    onIonChange={e =>
                      setPlUrlProps({
                        ...plUrlProps,
                        taskId: e.detail.value
                      })
                    }
                  >
                    <IonSelectOption value={null}>{t("all")}</IonSelectOption>
                    {tasks?.map(step => (
                      <IonSelectOption key={step.id} value={step.id}>
                        {step.name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              </IonCol>

              <IonCol>
                <IonItem lines="none" className="ion-margin-none">
                  <IonLabel position="stacked">{t("status")}</IonLabel>
                  <IonSelect
                    interface="action-sheet"
                    cancelText={t("cancel")}
                    value={plUrlProps?.status ? plUrlProps.status : null}
                    onIonChange={e =>
                      setPlUrlProps({
                        ...plUrlProps,
                        status: e.detail.value!
                      })
                    }
                  >
                    <IonSelectOption value={null}>{t("all")}</IonSelectOption>
                    {getEnumValues(ProductionLogStatus).map(status => (
                      <IonSelectOption key={status} value={status}>
                        {tProductionLogStatus(status)}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              </IonCol>
            </>
          )}
          <IonCol>
            <IonItem className="ion-no-padding">
              <IonLabel position="stacked">{t("calendar.view")}</IonLabel>
              <IonSelect
                interface="action-sheet"
                value={view}
                onIonChange={e => {
                  if (view !== e.target.value) {
                    setView(e.target.value);
                  }
                }}
              >
                <IonSelectOption value="timeGridWeek">
                  {t("calendar.week")}
                </IonSelectOption>
                <IonSelectOption value="dayGridMonth">
                  {t("calendar.month")}
                </IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonCol>
        </IonRow>
      )}
    </>
  );
};

export default CalendarSettings;
