import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonRadio,
  IonRadioGroup,
  IonIcon,
  IonButton,
  IonProgressBar
} from "@ionic/react";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { cashOutline, logoPaypal } from "ionicons/icons";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useNotificationContext } from "../../context/NotificationProvider";
import useApi from "../../data/Api";
import useTranslation from "../../context/LanguageProvider";
import withPermission from "../../data/withPermission";
import {
  formatCurrencyValue,
  formatWithCurrency
} from "../../hooks/useCurrency";
import useDate from "../../hooks/useDate";
import useLab from "../../context/LabProvider";
import useUrlSearchParams from "../../hooks/useUrlSearchParams";
import {
  LicenseType,
  SubscriptionBillingMethod,
  SubscriptionPlanDto
} from "../../models/Lab";
import { Permission } from "../../models/Permissions";
import Icon from "../Icon";
import { getCurrencySymbol } from "../../models/Currency";

const AppPayment: React.FC = () => {
  const { t, tInterpolated, tSubscriptionPlan } = useTranslation();
  const [ammount, setAmmount] = useState(0);
  const [sending, setSending] = useState(false);
  const [license, setLicense] = useState<LicenseType>();
  const [annual, setAnnual] = useState(false);
  const [currencySign, setCurrencySign] = useState<string>();
  const [expirationDate, setExpirationDate] = useState<Date>();
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlanDto>();
  const [selectedPayment, setSelectedPayment] =
    useState<SubscriptionBillingMethod>();
  const { subscription, fetchSubscription } = useLab();
  const { apiGet, apiPost } = useApi();
  const { handleError, showAlert } = useNotificationContext();
  const { toDateString } = useDate();
  const history = useHistory();
  const licenseParam = useUrlSearchParams("license");
  const annualParam = useUrlSearchParams("annual");

  useEffect(() => {
    setAnnual(annualParam === "true");
    licenseParam && setLicense(parseInt(licenseParam) as LicenseType);
  }, [annualParam, licenseParam]);

  useEffect(() => {
    setSelectedPayment(subscription?.billingMethod);
  }, [subscription]);

  useEffect(() => {
    if (!license) return;

    apiGet<SubscriptionPlanDto>(`appPayment/getPlan?license=${license}`)
      .then(p => {
        const plan = { ...tSubscriptionPlan(license), ...p };
        setSelectedPlan(plan);
        setCurrencySign(getCurrencySymbol(plan.currency));
      })
      .catch(handleError);
  }, [license, apiGet, handleError, tSubscriptionPlan]);

  useEffect(() => {
    if (!selectedPlan) return;

    const amount = annual
      ? 12 * selectedPlan.annualPrice
      : selectedPlan.monthlyPrice;
    setAmmount(amount);
  }, [annual, selectedPlan]);

  useEffect(() => {
    if (subscription?.expiryDate) {
      const exp = new Date(subscription.expiryDate);
      const year = exp.getFullYear() + (annual ? 1 : 0);
      const month = exp.getMonth() + (annual ? 0 : 1);
      const day = exp.getDate();
      setExpirationDate(new Date(year, month, day));
    }
  }, [subscription?.expiryDate, annual]);

  const showSuccessMessage = useCallback(
    (message: string) => () => {
      fetchSubscription();
      showAlert({
        title: t("success"),
        message: t(`appPayment.${message}`),
        buttons: [
          {
            text: t("ok"),
            handler: () => {
              history.push("/change-subscription");
            }
          }
        ]
      });
    },
    [history, fetchSubscription, showAlert, t]
  );

  const sendInvoice = useCallback(() => {
    setSending(true);
    apiPost(`appPayment/bankTransfer?license=${license}&annual=${annual}`, {})
      .then(showSuccessMessage("invoiceSent"))
      .catch(handleError)
      .finally(() => setSending(false));
  }, [license, annual, apiPost, showSuccessMessage, handleError]);

  const sendPaypal = useCallback(
    (body: any) => {
      // Your code here after create the order
      setSending(true);
      apiPost<number>(
        `appPayment/paypal?license=${license}&annual=${annual}`,
        body
      )
        .then(showSuccessMessage("paypalReceived"))
        // we show success even for the failed post (PayPal has done their job, that's the only important thing)
        .catch(e => {
          console.log(e);
          showSuccessMessage("paypalReceived");
        })
        .finally(() => setSending(false));
    },
    [license, annual, apiPost, showSuccessMessage]
  );

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/change-subscription" />
          </IonButtons>
          <IonTitle>{t("appPayment.title")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          {selectedPlan && (
            <>
              <IonRow>
                <IonCol size-xs="12" size-md="6" offset-md="2">
                  <h3>{t("subscription.current")}</h3>
                  <IonCard button>
                    <IonCardHeader color="primary">
                      <IonCardTitle>{selectedPlan.title}</IonCardTitle>
                      <IonCardSubtitle>
                        {tInterpolated("subscription.cases", {
                          cases: selectedPlan.cases
                        })}
                      </IonCardSubtitle>
                    </IonCardHeader>
                    <IonCardContent>
                      <IonItem>
                        <IonLabel slot="start">{t("amount")}</IonLabel>
                        <IonLabel>
                          {formatWithCurrency(
                            ammount,
                            selectedPlan.currency,
                            currencySign,
                            0
                          )}
                        </IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel slot="start">
                          {t("appPayment.starting")}
                        </IonLabel>
                        {subscription?.expiryDate && (
                          <IonLabel>
                            <b>{toDateString(subscription.expiryDate)}</b>
                          </IonLabel>
                        )}
                      </IonItem>
                      <IonItem>
                        <IonLabel slot="start">
                          {t("appPayment.validUntil")}
                        </IonLabel>
                        {expirationDate && (
                          <IonLabel>
                            {toDateString(expirationDate.toISOString())}
                          </IonLabel>
                        )}
                      </IonItem>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size-xs="12" size-md="6" offset-md="2">
                  <IonCard>
                    <IonRadioGroup
                      value={selectedPayment}
                      onIonChange={e => setSelectedPayment(e.detail.value)}
                    >
                      <IonItem>
                        <IonRadio
                          slot="start"
                          value={SubscriptionBillingMethod.Paypal}
                        />
                        <IonIcon
                          icon={logoPaypal}
                          slot="start"
                          color="secondary"
                        />
                        <IonLabel>{t("appPayment.paypal")}</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonRadio
                          slot="start"
                          value={SubscriptionBillingMethod.BankTransfer}
                        />
                        <IonIcon
                          icon={cashOutline}
                          slot="start"
                          color="secondary"
                        />
                        <IonLabel>{t("appPayment.bank")}</IonLabel>
                      </IonItem>
                    </IonRadioGroup>
                  </IonCard>
                </IonCol>
              </IonRow>
              <IonRow hidden={!selectedPayment}>
                <IonCol size-xs="12" size-md="6" offset-md="2">
                  <IonCard className="ion-padding">
                    {selectedPayment ===
                      SubscriptionBillingMethod.BankTransfer && (
                      <>
                        <p>{t("appPayment.bankDescription")}</p>
                        <IonButton
                          expand="block"
                          fill="solid"
                          shape="round"
                          color="success"
                          onClick={sendInvoice}
                          disabled={sending}
                        >
                          {sending ? (
                            <Icon spin icon={faSpinner} />
                          ) : (
                            <IonIcon icon={cashOutline} slot="start" />
                          )}
                          {t("appPayment.sendBank")}
                        </IonButton>
                      </>
                    )}
                    {selectedPayment === SubscriptionBillingMethod.Paypal &&
                      !sending && (
                        <PayPalButtons
                          style={{ layout: "horizontal", shape: "pill" }}
                          createOrder={(data, actions) =>
                            // This function sets up the details of the transaction, including the amount and line item details.
                            actions.order
                              .create({
                                purchase_units: [
                                  {
                                    amount: {
                                      currency_code: selectedPlan.currency,
                                      value: formatCurrencyValue(ammount, 0)
                                    }
                                  }
                                ]
                              })
                              .then(orderId => {
                                // Your code here after create the order
                                return orderId;
                              })
                          }
                          onApprove={(data, actions) =>
                            // The method is called after the buyer approves the transaction on paypal.com
                            actions.order.capture().then(sendPaypal)
                          }
                          onCancel={(data, actions) => {
                            // Show a cancel page, or return to cart
                            console.log(data);
                          }}
                          onError={err => {
                            // For example, redirect to a specific error page
                            console.error(
                              "error from the onError callback",
                              err
                            );
                          }}
                        />
                      )}
                    {selectedPayment === SubscriptionBillingMethod.Paypal &&
                      sending && (
                        <IonProgressBar
                          type="indeterminate"
                          buffer={1}
                          value={0.5}
                        />
                      )}
                  </IonCard>
                </IonCol>
              </IonRow>
            </>
          )}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default withPermission(AppPayment, Permission.SubscriptionsUpdate);
