import React, { useEffect, useState } from "react";
import {
  AlertInput,
  IonAlert,
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonRouterLink,
  IonRow,
  IonSkeletonText
} from "@ionic/react";
import { CaseViewDto, CaseStatus } from "../../models/Case";
import ValueLabel from "../ValueLabel";
import Icon from "../Icon";
import {
  faCheck,
  faCheckCircle,
  faExternalLinkAlt,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import useTranslation from "../../context/LanguageProvider";
import useDate from "../../hooks/useDate";
import Can from "../Can";
import { Permission } from "../../models/Permissions";
import { useAuthContext } from "../../context/AuthProvider";
import Has from "../Has";
import NoResults from "../NoResults";
import ProductionLog from "../productionLog/ProductionLog";
import TryoutDto from "../../models/Tryout";
import TryoutUpsertModal from "./TryoutUpsertModal";
import ScheduledDate from "../ScheduledDate";
import ArchesView from "../arches/ArchesView";
import CaseStatusIcon from "./CaseStatusIcon";
import ButtonTextIcon from "../ButtonTextIcon";
import CaseAttachments from "./CaseAttachments";
import AcceptDeclineCase from "./AcceptDeclineCase";

const getTryoutColor = (d: TryoutDto) => {
  if (d.received) return "successColor";
  if (d.shipped) return "warningColor";

  return "mediumColor";
};

interface Props {
  $case: CaseViewDto;
  onChange: () => void;
  showEdit: () => void;
  exposeGetSvg: (getSvg: () => string | undefined) => void;
}
const CaseInfoView: React.FC<Props> = ({
  $case,
  onChange,
  showEdit,
  exposeGetSvg
}) => {
  const { apiPost } = useApi();
  const { handleError } = useNotificationContext();
  const { t, tInterpolated } = useTranslation();
  const { toDateTimeString, toDateString } = useDate();
  const { user } = useAuthContext();

  const [showTryoutUpsertModal, setShowTryoutUpsertModal] = useState(false);
  const [showSendToTryout, setShowSendToTryout] = useState(false);
  const [tryout, setTryout] = useState<TryoutDto>();
  const [tryoutsForShipping, setTryoutsForShipping] = useState<AlertInput[]>();
  const [showReceivedFromClient, setShowReceiveFromTryout] = useState(false);

  useEffect(() => {
    if ($case) {
      // set default values for new tryout
      setTryout({
        id: 0,
        caseId: $case.id,
        doctorId: $case.doctorId,
        name: ""
      });
      // filter out already shipped tryouts
      const tryoutsForShipping: AlertInput[] = $case.tryouts
        .filter(t => !t.shipped)
        .map((d, i) => ({
          name: "id",
          checked: !i,
          type: "radio",
          value: d.id,
          label: `${d.name} ${toDateString(
            d.shipDate ?? d.appointmentDate ?? ""
          )}`
        }));

      setTryoutsForShipping(tryoutsForShipping);
    }
  }, [$case, toDateString]);

  const editTryout = (data: TryoutDto) => {
    if ($case && $case.status !== CaseStatus.Finished) {
      setTryout(data);
      setShowTryoutUpsertModal(true);
    }
  };

  const renderDate = (date: string | undefined, scheduled: boolean) =>
    date ? (
      scheduled ? (
        <ScheduledDate
          date={date}
          format="datetime"
          ignoreColor={[CaseStatus.Finished, CaseStatus.Cancelled].includes(
            $case.status
          )}
        />
      ) : (
        toDateTimeString(date)
      )
    ) : (
      <span className="warningColor">{t("notDefined")}</span>
    );

  return (
    <IonGrid>
      <IonRow>
        {$case.status === CaseStatus.PendingAccept &&
          user!.doctorId === undefined && (
            <IonCol size="12" size-sm="12" size-xl="12" class="box">
              <AcceptDeclineCase $case={$case} onChange={onChange} />
            </IonCol>
          )}
        {$case.status === CaseStatus.OutOfLab && user!.doctorId !== undefined && (
          <IonCol size="12" size-sm="12" size-xl="12" class="box">
            <div className="ion-text-center ion-margin-top">
              <h2>{t("cases.tryoutFinished")}</h2>
              <IonButton
                color="primary"
                onClick={() => {
                  apiPost<boolean>(
                    `case/changeStatus?id=${$case.id}&status=${CaseStatus.ReadyForShippingToLab}`,
                    {}
                  )
                    .then(onChange)
                    .catch(handleError);
                }}
              >
                {t("cases.markReadyForShippingToLab")}
              </IonButton>
            </div>
          </IonCol>
        )}
        {($case.status === CaseStatus.OutOfLab ||
          $case.status === CaseStatus.ReadyForShippingToLab) &&
          user!.doctorId === undefined && (
            <IonCol size="12" size-sm="12" size-xl="12" class="box">
              <div className="ion-text-center ion-margin-top">
                <h2>{t("cases.tryoutFinished")}</h2>
                <IonButton
                  color="primary"
                  onClick={() => {
                    setShowReceiveFromTryout(true);
                  }}
                >
                  {t("cases.markCaseReceived")}
                </IonButton>
              </div>
            </IonCol>
          )}

        <IonCol
          size="12"
          size-sm="12"
          size-xl="12"
          class="box"
          hidden={!$case.products.length && !$case.teethCondition.length}
        >
          <ArchesView $case={$case} exposeGetSvg={exposeGetSvg} />
        </IonCol>
        <IonCol size="12" size-sm="12" size-xl="12" class="box">
          <IonRow className="ion-no-padding case-details-row">
            <IonCol>
              <ValueLabel title={t("status")}>
                <CaseStatusIcon {...$case} onChange={onChange} />
              </ValueLabel>
            </IonCol>
            <Can permission={Permission.DoctorNameRead}>
              <IonCol>
                <ValueLabel title={t("doctor")} large bold>
                  {user?.hasPermission(Permission.DoctorRead) ? (
                    <IonRouterLink
                      title={t("cases.goToDoctor")}
                      routerLink={"/doctor/" + $case.doctor.id}
                      color="default"
                    >
                      {$case.doctor.name}{" "}
                      <Icon
                        className="mediumColor"
                        size="xs"
                        icon={faExternalLinkAlt}
                      />
                    </IonRouterLink>
                  ) : (
                    $case.doctor.name
                  )}
                </ValueLabel>
              </IonCol>
            </Can>
            <Can permission={Permission.PatientNameRead}>
              <IonCol>
                <ValueLabel
                  title={t("patient")}
                  notAvailable={!$case.patient}
                  bold={$case.patient !== undefined}
                >
                  {$case.patient ?? (
                    <Has
                      permission={Permission.CaseUpdate}
                      yes={() => (
                        <span className="pointer" onClick={showEdit}>
                          {t("notSelected")}
                        </span>
                      )}
                      no={() => t("notSelected")}
                    />
                  )}
                </ValueLabel>
              </IonCol>
            </Can>
            <IonCol hidden={!$case.pan}>
              <ValueLabel title={t("cases.pan")}>{$case.pan}</ValueLabel>
            </IonCol>

            <IonCol>
              <ValueLabel
                title={t("dates.received")}
                notAvailable={!$case.received}
              >
                {renderDate($case.received, false)}
              </ValueLabel>
            </IonCol>
            <IonCol>
              <ValueLabel
                title={t("dates.shipDate")}
                notAvailable={!$case.shipDate}
              >
                <Has
                  permission={Permission.CaseUpdate}
                  yes={() => (
                    <span className="pointer" onClick={showEdit}>
                      {renderDate($case.shipDate, true)}
                    </span>
                  )}
                  no={() => renderDate($case.shipDate, true)}
                />
              </ValueLabel>
            </IonCol>
            <IonCol>
              <ValueLabel
                title={t("dates.finishDate")}
                notAvailable={!$case.appointmentDate}
              >
                <Has
                  permission={Permission.CaseUpdate}
                  yes={() => (
                    <span className="pointer" onClick={showEdit}>
                      {renderDate($case.appointmentDate, true)}
                    </span>
                  )}
                  no={() => renderDate($case.appointmentDate, true)}
                />
              </ValueLabel>
            </IonCol>

            <IonCol size="auto">
              <ValueLabel title={t("notes")} notAvailable={!$case.notes}>
                <Has
                  permission={Permission.CaseUpdate}
                  yes={() => (
                    <span className="pointer" onClick={showEdit}>
                      {!$case.notes ? t("notDefined") : $case.notes}
                    </span>
                  )}
                  no={() => t("notDefined")}
                />
              </ValueLabel>
            </IonCol>
          </IonRow>
        </IonCol>

        <IonCol
          size-sm="12"
          size-xl="12"
          class="box"
          hidden={$case?.status === CaseStatus.PendingAccept}
        >
          <CaseAttachments $case={$case} />
        </IonCol>

        <Can permission={Permission.ProductionLogRead}>
          <IonCol
            size-sm="12"
            size-xl="12"
            class="box"
            hidden={$case?.status === CaseStatus.PendingAccept}
          >
            <ProductionLog $case={$case} caseStatus={$case.status} />
          </IonCol>
        </Can>
        <Can permission={Permission.TryoutRead}>
          <IonCol
            size-sm="12"
            size-xl="12"
            class="box"
            hidden={$case?.status === CaseStatus.PendingAccept}
          >
            <h3 className="ion-margin-start">{t("tryoutDates")}</h3>
            <IonList>
              {$case.tryouts ? (
                $case.tryouts.map(d => (
                  <IonItem
                    key={d.id}
                    // detail={$case.status !== CaseStatus.Finished}
                  >
                    <Icon
                      className={getTryoutColor(d)}
                      slot="start"
                      icon={faCheckCircle}
                    />
                    <IonLabel>
                      {d.name}
                      {d.shipped && d.received === undefined && (
                        <span className="warningColor additional-status-label">
                          {t("cases.outOfLab")}
                        </span>
                      )}
                      {d.shipped && d.received && (
                        <span className="successColor additional-status-label">
                          {t("cases.received")}
                        </span>
                      )}
                      {d.shipped && (
                        <p color="primary" className="smaller-font">
                          {tInterpolated("cases.shippedOn", {
                            date: toDateString(d.shipped)
                          })}
                        </p>
                      )}
                      {d.received && (
                        <p className="smaller-font">
                          {tInterpolated("cases.receivedOn", {
                            date: toDateString(d.received)
                          })}
                        </p>
                      )}
                      {d.shipDate && (
                        <div className="ion-hide-sm-up smaller-font">
                          <ValueLabel title={t("dates.shipDate")}>
                            <ScheduledDate
                              date={d.shipDate}
                              format="datetime"
                              ignoreColor={d?.shipped !== undefined}
                            />
                          </ValueLabel>
                        </div>
                      )}
                      {d.appointmentDate && (
                        <div className="ion-hide-sm-up smaller-font">
                          <ValueLabel title={t("dates.appointmentDate")}>
                            <ScheduledDate
                              date={d.appointmentDate}
                              format="datetime"
                              ignoreColor={d?.shipped !== undefined}
                            />
                          </ValueLabel>
                        </div>
                      )}
                    </IonLabel>
                    <Can permission={Permission.TryoutUpdate}>
                      {!d.shipped &&
                        !d.received &&
                        [
                          CaseStatus.InProgress,
                          CaseStatus.OnHold,
                          CaseStatus.OutOfLab
                        ].includes($case.status) && (
                          <IonButton
                            slot="end"
                            fill="clear"
                            onClick={() => editTryout(d)}
                          >
                            <ButtonTextIcon button="edit" />
                          </IonButton>
                        )}
                    </Can>
                    {d.shipDate && (
                      <div className="ion-hide-sm-down smaller-font">
                        <ValueLabel title={t("dates.shipDate")}>
                          <ScheduledDate
                            date={d.shipDate}
                            format="datetime"
                            ignoreColor={d?.shipped !== undefined}
                          />
                        </ValueLabel>
                      </div>
                    )}
                    {d.appointmentDate && (
                      <div className="ion-hide-sm-down smaller-font">
                        <ValueLabel title={t("dates.appointmentDate")}>
                          <ScheduledDate
                            date={d.appointmentDate}
                            format="datetime"
                            ignoreColor={d?.shipped !== undefined}
                          />
                        </ValueLabel>
                      </div>
                    )}
                  </IonItem>
                ))
              ) : (
                <IonSkeletonText animated />
              )}
            </IonList>
            <NoResults
              title={!$case.tryouts.length ? t("cases.noTryouts") : ""}
            />
            {tryout &&
              $case.status !== CaseStatus.Finished &&
              $case.status !== CaseStatus.Cancelled && (
                <>
                  <div className="ion-text-center ion-margin-top">
                    <Can permission={Permission.TryoutCreate}>
                      <IonButton
                        color="secondary"
                        class="ion-margin-start"
                        onClick={() => {
                          setTryout({
                            id: 0,
                            caseId: $case.id,
                            doctorId: $case.doctorId,
                            name: ""
                          });
                          setShowTryoutUpsertModal(true);
                        }}
                      >
                        <Icon icon={faPlus} />
                        {t("cases.addTryout")}
                      </IonButton>
                    </Can>
                    <Can permission={Permission.TryoutUpdate}>
                      <IonButton
                        hidden={
                          $case.status === CaseStatus.OutOfLab ||
                          !tryoutsForShipping ||
                          !tryoutsForShipping.length
                        }
                        onClick={() => setShowSendToTryout(true)}
                      >
                        {t("cases.sendToTryout")}
                      </IonButton>
                      {$case.status === CaseStatus.OutOfLab && (
                        <IonButton
                          onClick={() => {
                            setShowReceiveFromTryout(true);
                          }}
                        >
                          <Icon icon={faCheck} />
                          {t("cases.markAsReceived")}
                        </IonButton>
                      )}{" "}
                    </Can>
                  </div>

                  <TryoutUpsertModal
                    showTryoutUpsertModal={showTryoutUpsertModal}
                    initialData={tryout}
                    onSuccess={() => {
                      setShowTryoutUpsertModal(false);
                      onChange();
                    }}
                    onCancel={() => setShowTryoutUpsertModal(false)}
                  />
                  {showSendToTryout && (
                    <IonAlert
                      isOpen={showSendToTryout}
                      onDidDismiss={() => setShowSendToTryout(false)}
                      header={t("cases.sendToTryout")}
                      message={t("cases.sendToTryoutMessage")}
                      inputs={tryoutsForShipping}
                      buttons={[
                        {
                          text: t("cancel"),
                          role: "cancel",
                          cssClass: "secondary"
                        },
                        {
                          text: t("cases.sendToTryout"),
                          handler: id => {
                            if (id)
                              apiPost<boolean>(
                                `tryout/ship?id=${id}&caseId=${$case.id}`,
                                {}
                              )
                                .then(onChange)
                                .catch(handleError);
                          }
                        }
                      ]}
                    />
                  )}
                  {showReceivedFromClient && (
                    <IonAlert
                      isOpen={showReceivedFromClient}
                      onDidDismiss={() => setShowReceiveFromTryout(false)}
                      header={t("cases.markAsReceivedHeader")}
                      message={t("cases.markAsReceivedMessage")}
                      buttons={[
                        {
                          text: t("cancel"),
                          role: "cancel",
                          cssClass: "secondary"
                        },
                        {
                          text: t("cases.received"),
                          handler: () => {
                            apiPost<boolean>(
                              `tryout/receive?caseId=${$case.id}`,
                              {}
                            )
                              .then(onChange)
                              .catch(handleError);
                          }
                        }
                      ]}
                    />
                  )}
                </>
              )}
          </IonCol>
        </Can>
      </IonRow>
    </IonGrid>
  );
};

export default CaseInfoView;
