import React from "react";
import { IonCheckbox } from "@ionic/react";
import { RegisterOptions } from "react-hook-form";
import FormItem from "./FormItem";

export interface Props {
  name: string;
  label: string;
  rules?: RegisterOptions;
  hidden?: boolean;
  disabled?: boolean;
  className?: string;
}

const FormIonCheckbox: React.FC<Props> = ({
  name,
  label,
  rules,
  hidden,
  disabled,
  ...props
}) => (
  <FormItem
    name={name}
    label={label}
    labelPosition="fixed"
    rules={rules}
    hidden={hidden}
    disabled={disabled}
    input={({ onChange, onBlur, value, ...rest }) => (
      <IonCheckbox
        slot="start"
        onIonChange={e => onChange(e.detail.checked)}
        onIonBlur={onBlur}
        checked={value}
        {...rest}
        {...props}
      />
    )}
  />
);

export default FormIonCheckbox;
