import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonList,
  IonItem,
  IonLabel,
  IonImg
} from "@ionic/react";
import React, { useCallback, useState } from "react";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import LabForm, { convertToFormData } from "./LabForm";
import useTranslation from "../../context/LanguageProvider";
import LabDto, { BillingIntegrationType } from "../../models/Lab";
import { Permission } from "../../models/Permissions";
import withPermission from "../../data/withPermission";
import useLab, { useLabIntegrations } from "../../context/LabProvider";
import MiddleBox from "../MiddleBox";
import useDate from "../../hooks/useDate";
import BillingIntegrationModal from "./BillingIntegrationModal";
import SmartBillButton from "./SmartBillButton";
import ShowForLanguage from "../navigation/ShowForLanguage";

const EditLab: React.FC = () => {
  const { apiPostMultiForm } = useApi();
  const { resetLab } = useLab();
  const {
    integrations,
    loading: loadingIntegrations,
    fetchIntegrations
  } = useLabIntegrations();
  const { handleError, showSuccessToast } = useNotificationContext();
  const { t } = useTranslation();
  const { toDateString } = useDate();
  const [editIntegration, setEditIntegration] =
    useState<BillingIntegrationType>();

  const editLab = useCallback(
    (lab: LabDto) =>
      apiPostMultiForm<boolean>("lab/update", convertToFormData(lab))
        .then(() => {
          resetLab(lab);
          showSuccessToast(t("editLab.updated"));
        })
        .catch(handleError),
    [apiPostMultiForm, handleError, resetLab, showSuccessToast, t]
  );

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/settings" />
          </IonButtons>
          <IonTitle>{t("editLab.title")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <MiddleBox>
          <LabForm submitButtonText={t("save")} onSubmit={editLab} />
        </MiddleBox>
        <ShowForLanguage languages={["ro"]} currencies={["RON"]}>
          <MiddleBox className="box" hidden={loadingIntegrations}>
            <IonList>
              <h3 className="content-font">{t("integrations.title")}</h3>

              {integrations.map(li => (
                <IonItem
                  key={li.provider}
                  onClick={() => setEditIntegration(li.provider)}
                  className={
                    li.provider === BillingIntegrationType.SmartBill
                      ? "smartbill-color"
                      : ""
                  }
                >
                  {li.provider === BillingIntegrationType.SmartBill ? (
                    <IonImg src={"assets/icons/smartbill.svg"} alt="" />
                  ) : (
                    <IonLabel slot="start">
                      {" "}
                      {t(li.provider.toString())}
                    </IonLabel>
                  )}
                  <IonLabel slot="end"> {toDateString(li.created)}</IonLabel>
                </IonItem>
              ))}
              {integrations.length === 0 && (
                <SmartBillButton
                  onClick={() =>
                    setEditIntegration(BillingIntegrationType.SmartBill)
                  }
                />
              )}
            </IonList>
            <BillingIntegrationModal
              onSuccess={() => {
                fetchIntegrations();
                setEditIntegration(undefined);
              }}
              onCancel={() => setEditIntegration(undefined)}
              integrations={integrations}
              provider={editIntegration}
            />
          </MiddleBox>
        </ShowForLanguage>
      </IonContent>
    </IonPage>
  );
};

export default withPermission(EditLab, Permission.LabUpdate);
