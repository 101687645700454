import React from "react";
import { IonSegmentButton, IonLabel } from "@ionic/react";
import Icon from "./Icon";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
  value: string;
  title: string;
  icon: IconProp;
  count?: number;
  iconClass?: string;
}

const SegmentButtonTextIcon: React.FC<Props> = ({
  value,
  title,
  icon,
  count,
  iconClass
}) => (
  <IonSegmentButton value={value}>
    <IonLabel>
      <Icon icon={icon} className={`segment-button-icon ${iconClass}`} />
      <IonLabel className="ion-hide-sm-down">{title}</IonLabel>
      {count !== undefined && count > 0 && (
        <span className="content-font"> ({count}) </span>
      )}
    </IonLabel>
  </IonSegmentButton>
);

export default SegmentButtonTextIcon;
