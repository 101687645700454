import React, { useCallback, useEffect, useState } from "react";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  RefresherEventDetail,
  IonSkeletonText
} from "@ionic/react";
import CaseFormDto, { CaseFlag, CaseStatus } from "../../models/Case";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import useTranslation from "../../context/LanguageProvider";
import ButtonTextIcon from "../ButtonTextIcon";
import { useAuthContext } from "../../context/AuthProvider";
import CaseForm from "./CaseForm";
import Icon from "../Icon";
import { faEdit, faSpinner } from "@fortawesome/free-solid-svg-icons";
import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";
import { RouteComponentProps, useHistory } from "react-router";
import Refresher from "../Refresher";

interface Props
  extends RouteComponentProps<{
    id: string;
  }> {
  id: 0;
}

const EditCase: React.FC<Props> = ({ match }) => {
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);

  const [$case, setCase] = useState<CaseFormDto>({
    id: 0,
    doctorId: 0,
    status: CaseStatus.InProgress,
    name: "",
    received: new Date().toISOString(),
    flag: CaseFlag.None,
    products: [],
    teethCondition: [],
    autoName: true
  });

  const { apiPost, apiGet } = useApi();
  const { handleError, showSuccessToast, showErrorToast } =
    useNotificationContext();
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (user?.doctorId && $case.doctorId && $case.doctorId !== user.doctorId)
      history.replace("/");
  }, [$case.doctorId, history, user?.doctorId]);

  const fetchCase = useCallback(
    (refreshEvent?: CustomEvent<RefresherEventDetail>) => {
      setLoading(true);
      return apiGet<CaseFormDto>(`case/get?id=${match.params.id}`)
        .then(setCase)
        .catch(handleError)
        .finally(() => {
          setLoading(false);
          if (refreshEvent && refreshEvent.detail)
            refreshEvent.detail.complete();
        });
    },
    [apiGet, match.params.id, handleError]
  );

  useEffect(() => {
    match.params.id && fetchCase();
  }, [match.params.id, fetchCase]);

  const upsertCase = useCallback(() => {
    if (!$case.name) {
      showErrorToast(t("cases.noNameError"));
      return;
    }
    if (!$case.doctorId) {
      showErrorToast(t("cases.noDoctorError"));
      return;
    }
    setLoading(true);
    apiPost<number>("case/upsert", $case)
      .then(id => {
        showSuccessToast(t("saved"));
        history.replace(`/case/${id}`);
      })
      .catch(handleError)
      .finally(() => setLoading(false));
  }, [
    $case,
    history,
    apiPost,
    handleError,
    showErrorToast,
    showSuccessToast,
    t
  ]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>{t("cases.edit")}</IonTitle>
          <IonButtons slot="primary">
            <IonButton disabled={loading} onClick={upsertCase}>
              <ButtonTextIcon button="edit" loading={loading} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Refresher onRefresh={fetchCase} />
        {!loading && (
          <CaseForm
            onCaseChange={data => setCase(c => ({ ...c, ...data }))}
            $case={$case}
          />
        )}
        {loading && <IonSkeletonText animated title={t("loading")} />}

        <IonButton
          color="success"
          expand="block"
          onClick={upsertCase}
          disabled={loading}
          className="ion-margin-top"
        >
          {loading ? <Icon spin icon={faSpinner} /> : <Icon icon={faEdit} />}
          {t("edit")}
        </IonButton>
      </IonContent>
    </IonPage>
  );
};

export default withPermission(EditCase, Permission.CaseUpdate);
