import React, { useEffect, useState } from "react";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSkeletonText,
  IonText,
  IonTitle,
  IonToggle,
  IonToolbar
} from "@ionic/react";
import useLab from "../../context/LabProvider";
import { LicenseType, SubscriptionPlanDto } from "../../models/Lab";
import useTranslation from "../../context/LanguageProvider";
import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";
import useDate from "../../hooks/useDate";
import ValueLabel from "../ValueLabel";
import MiddleBox from "../MiddleBox";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import { getCurrencySymbol } from "../../models/Currency";
import { formatWithCurrency } from "../../hooks/useCurrency";

interface PlanProps extends SubscriptionPlanDto {
  annual: boolean;
  currentLicense?: LicenseType;
}

const SubscriptionPlan: React.FC<PlanProps> = ({
  title,
  license,
  cases,
  currency,
  monthlyPrice,
  annualPrice,
  annual,
  currentLicense
}) => {
  const { tInterpolated, t } = useTranslation();
  const [currencySymbol, setCurrencySymbol] = useState<string>();
  useEffect(() => {
    setCurrencySymbol(getCurrencySymbol(currency));
  }, [currency]);

  const color = "secondary";

  return (
    <IonCol size-xs="12" size-md="4">
      <IonCard className="licence_card">
        <IonCardHeader color={color} className="ion-text-center">
          <IonCardTitle>{title}</IonCardTitle>
          <IonCardSubtitle></IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <IonItem className="ion-text-center">
            <IonLabel className="ion-text-wrap">
              {tInterpolated("subscription.cases", { cases })}
            </IonLabel>
          </IonItem>
          <div className="ion-text-center">
            <IonText className="pricing_text">
              {!annual && (
                <b>
                  {formatWithCurrency(
                    monthlyPrice,
                    currency,
                    currencySymbol,
                    0
                  )}{" "}
                  {t("subscription.month")}
                </b>
              )}
              {annual && (
                <>
                  <span
                    style={{
                      textDecoration: "line-through solid red"
                    }}
                  >
                    {formatWithCurrency(
                      monthlyPrice * 12,
                      currency,
                      currencySymbol,
                      0
                    )}
                  </span>
                  <br />
                  <b>
                    {formatWithCurrency(
                      annualPrice * 12,
                      currency,
                      currencySymbol,
                      0
                    )}
                  </b>{" "}
                  {t("subscription.annualy")}
                  <br />
                  {t("subscription.save")}{" "}
                  <b>
                    {formatWithCurrency(
                      (monthlyPrice - annualPrice) * 12,
                      currency,
                      currencySymbol,
                      0
                    )}
                  </b>
                </>
              )}
            </IonText>
          </div>
        </IonCardContent>
        <IonButton
          expand="block"
          fill="solid"
          shape="round"
          className="ion-margin"
          color={color}
          routerLink={`/app-payment?license=${license}&annual=${annual}`}
        >
          {t("subscription.select")}
        </IonButton>
      </IonCard>
    </IonCol>
  );
};

const ChangeSubscription: React.FC = () => {
  const { subscription } = useLab();
  const { t, tInterpolated, tSubscriptionPlan } = useTranslation();
  const [annual, setAnnual] = useState(true);
  const [showPlans, setShowPlans] = useState(false);
  const [currentPlan, setCurrentPlan] = useState<SubscriptionPlanDto>();
  const [plans, setPlans] = useState<SubscriptionPlanDto[]>();
  const { toDateString } = useDate();
  const { apiGet } = useApi();
  const { handleError } = useNotificationContext();

  useEffect(() => {
    if (!subscription || !plans) {
      setCurrentPlan(undefined);
    } else if (plans.find(plan => plan.license === subscription.license)) {
      const plan = plans.find(plan => plan.license === subscription.license);
      setCurrentPlan(plan ?? tSubscriptionPlan(subscription.license));
    } else {
      setCurrentPlan(tSubscriptionPlan(subscription.license));
    }
  }, [subscription, plans, tSubscriptionPlan]);

  useEffect(() => {
    apiGet<SubscriptionPlanDto[]>(`/appPayment/getPrices`)
      .then(plans => {
        const translatedPlans = plans.map(p => {
          const f = tSubscriptionPlan(p.license);
          // first the translation, then overwrite pricing data
          return { ...f, ...p };
        });
        setPlans(translatedPlans);
      })
      .catch(handleError);
  }, [apiGet, handleError, tSubscriptionPlan]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/edit-lab" />
          </IonButtons>
          <IonTitle>{t("subscription.title")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {subscription && currentPlan && (
          <MiddleBox className="box">
            <ValueLabel large title={t("subscription.licence")}>
              {currentPlan.title}
            </ValueLabel>
            <ValueLabel large title={t("subscription.limit")}>
              {tInterpolated("subscription.cases", {
                cases: subscription.caseCount
              })}
            </ValueLabel>
            {subscription.expiryDate && (
              <ValueLabel title={t("subscription.expiryDate")}>
                {toDateString(subscription.expiryDate)}
              </ValueLabel>
            )}
            <IonButton
              expand="block"
              fill="outline"
              className="ion-margin-horizontal"
              onClick={() => setShowPlans(s => !s)}
            >
              {t(showPlans ? "subscription.hide" : "change")}
            </IonButton>
          </MiddleBox>
        )}
        <IonGrid>
          {showPlans && (
            <>
              <h3 className="ion-margin ion-text-center">
                {t("subscription.pick")}
              </h3>

              <IonItem style={{ maxWidth: 180, margin: "0 auto" }}>
                {/* <IonLabel>{t("subscription.monthly")}</IonLabel> */}
                <IonLabel>{t("subscription.annual")}</IonLabel>

                <IonToggle
                  // className="ion-margin-horizontal"
                  checked={annual}
                  onIonChange={e => setAnnual(e.detail.checked)}
                />
              </IonItem>
              {plans && (
                <IonRow>
                  {plans.map(plan => (
                    <SubscriptionPlan
                      {...plan}
                      annual={annual}
                      currentLicense={subscription?.license}
                    />
                  ))}
                </IonRow>
              )}
              {!plans && <IonSkeletonText animated title={t("loading")} />}
            </>
          )}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default withPermission(
  ChangeSubscription,
  Permission.SubscriptionsUpdate
);
