import React, { useCallback, useEffect, useReducer, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import useTranslation from "../../context/LanguageProvider";
import InvoiceFormDto, { InvoiceItemDto } from "../../models/Invoice";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import Icon from "../Icon";
import { faPlus, faSave, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { invoiceReducer } from "./invoiceMath";
import ButtonTextIcon from "../ButtonTextIcon";
import useCurrency from "../../hooks/useCurrency";
import InvoiceItemEdit from "./InvoiceItemEdit";
import { CaseViewDto } from "../../models/Case";
import { getRandomId } from "../../data/numberHelpers";
import ModalWrapper from "../ModalWrapper";
import useLab from "../../context/LabProvider";

interface Props {
  onSuccess: (id: number) => void;
  onCancel: () => void;
  showInvoiceModal: boolean;
  id: number;
  $case?: CaseViewDto;
}

const InvoiceUpsertModal: React.FC<Props> = ({
  onSuccess,
  onCancel,
  showInvoiceModal,
  id,
  $case
}) => {
  const { apiPost, apiGet } = useApi();
  const { showSuccessToast, showToast, handleError } = useNotificationContext();
  const { lab } = useLab();
  const { formatWithCurrencySign } = useCurrency();
  const { t, tPlaceholder, tError, tProductType } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);

  const [invoice, dispatch] = useReducer(invoiceReducer, {
    id: id,
    caseId: 0,
    doctorId: 0,
    taxRate: 20,
    taxTotal: 0,
    subTotal: 0,
    total: 0,
    items: [
      {
        id: 1,
        name: "",
        quantity: 1,
        price: 1,
        discount: 0,
        total: 1
      }
    ]
  });

  useEffect(() => {
    // we want to fetch every time the upsert is opened
    if (!showInvoiceModal) return;

    if (id > 0)
      apiGet<InvoiceFormDto>(`invoice/get?id=${id}`)
        .then(data => dispatch({ type: "fetch", data }))
        .catch(handleError);
    else if ($case?.id)
      dispatch({
        type: "caseUpdate",
        data: {
          number: getRandomId([]).toString(),
          taxRate: lab?.taxRate ?? 0,
          caseId: $case.id,
          doctorId: $case.doctorId,
          items: $case.products.length
            ? $case.products
                .flatMap(p => {
                  const item: InvoiceItemDto = {
                    id: 0,
                    name: `${tProductType(p.productTypeId)} ${p.product.name}`,
                    quantity: p.quantity,
                    price: p.product.price ?? 0,
                    discount: 0,
                    total: 0
                  };

                  const extras: InvoiceItemDto[] = p.extras.map(e => ({
                    id: 0,
                    name: `${e.extras.name}`,
                    quantity: e.toothIds.length,
                    price: e.extras.price ?? 0,
                    discount: 0,
                    total: 0
                  }));

                  return [item, ...extras];
                })
                .map((item, i) => ({ ...item, id: i + 1 }))
            : [
                {
                  id: 1,
                  name: "",
                  quantity: 1,
                  price: 1,
                  discount: 0,
                  total: 1
                }
              ]
        }
      });
    else if (lab?.taxRate) dispatch({ type: "setTax", taxRate: lab.taxRate });
  }, [
    id,
    showInvoiceModal,
    $case?.id,
    $case?.doctorId,
    $case?.products,
    lab?.taxRate,
    apiGet,
    handleError,
    tProductType
  ]);

  useEffect(() => {
    dispatch({ type: "countTotal" });
  }, [invoice.items, invoice.taxRate]);

  const addItem = () => dispatch({ type: "add" });
  const deleteItem = (i: number) => () => dispatch({ type: "delete", i });
  const setItem = (i: number) => (item: InvoiceItemDto) =>
    dispatch({ type: "set", i, item });

  const upsert = useCallback(() => {
    if (
      !invoice.items.every(i => i.total > 0 && (!i.discount || i.discount > 0))
    ) {
      showToast({
        message: tError("correctErrors"),
        duration: 5000,
        color: "danger"
      });
      return;
    }

    setSubmitting(true);
    apiPost<number>("invoice/upsert", invoice)
      .then(id => {
        showSuccessToast(t("saved"));
        onSuccess(id);
      })
      .catch(handleError)
      .finally(() => setSubmitting(false));
  }, [invoice, apiPost, handleError, showSuccessToast, showToast, t, tError]);

  return (
    <ModalWrapper
      modalOpened={showInvoiceModal}
      dismiss={onCancel}
      modal="invoiceUpsert"
    >
      <IonModal isOpen={showInvoiceModal} onDidDismiss={onCancel}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                <ButtonTextIcon button="cancel" />
              </IonButton>
            </IonButtons>
            <IonTitle>
              {id > 0 ? t("invoices.edit") : t("invoices.new")}
            </IonTitle>
            <IonButtons slot="primary">
              <IonButton onClick={upsert} disabled={isSubmitting}>
                <ButtonTextIcon button="save" loading={isSubmitting} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid class="ion-no-margin">
            <IonRow>
              <IonCol size-xs="12" size-md="4">
                <IonItem className="ion-no-padding" lines="none">
                  <IonLabel position="stacked">{t("invoices.number")}</IonLabel>
                  <IonInput
                    autocomplete="new-password"
                    placeholder={tPlaceholder("invoices.number")}
                    value={invoice.number}
                    onIonChange={e =>
                      dispatch({ type: "setNumber", number: e.detail.value! })
                    }
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <h3 className="content-font">{t("invoices.items")}</h3>
            {invoice.items.map((item, i) => (
              <InvoiceItemEdit
                key={i}
                item={item}
                onChange={setItem(i)}
                deleteItem={deleteItem(i)}
                showDelete={invoice.items.length > 1}
              />
            ))}
            <IonRow>
              <IonCol class="ion-text-end">
                <IonButton color="secondary" onClick={addItem}>
                  <Icon icon={faPlus} />
                  {t("invoices.addItem")}
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size-xs="12" size-md="8">
                <IonItem className="ion-no-padding" lines="none">
                  <IonLabel position="stacked" />
                  <IonTextarea
                    placeholder={tPlaceholder("invoices.notes")}
                    value={invoice.notes}
                    onIonChange={e =>
                      dispatch({ type: "setNotes", notes: e.detail.value! })
                    }
                  />
                </IonItem>
              </IonCol>
              <IonCol size-xs="12" size-md="4" pushMd="right">
                <IonItem lines="none">
                  <IonLabel className="content-font">
                    {t("subTotal")}: {formatWithCurrencySign(invoice.subTotal)}
                  </IonLabel>
                </IonItem>
                <IonItem lines="none">
                  <IonLabel position="stacked">{t("taxRate")}</IonLabel>
                  <IonInput
                    type="number"
                    value={invoice.taxRate}
                    onIonChange={e =>
                      dispatch({
                        type: "setTax",
                        taxRate: parseInt(e.detail.value!, 10)
                      })
                    }
                  />
                </IonItem>
                <IonItem lines="none">
                  <IonLabel className="content-font">
                    {t("taxTotal")}: {formatWithCurrencySign(invoice.taxTotal)}
                  </IonLabel>
                </IonItem>
                <IonItem lines="none">
                  <h3 className="content-font">
                    {t("total")}: {formatWithCurrencySign(invoice.total)}
                  </h3>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol class="ion-text-end">
                <IonButton
                  onClick={upsert}
                  color="success"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <Icon spin icon={faSpinner} />
                  ) : (
                    <Icon icon={faSave} />
                  )}
                  {t("save")}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>
    </ModalWrapper>
  );
};

export default InvoiceUpsertModal;
