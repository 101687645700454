import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { initTranslation } from "./context/LanguageProvider";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-TV5MDC7"
};

TagManager.initialize(tagManagerArgs);

initTranslation();

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (registration: ServiceWorkerRegistration) => {
    registration.update().then(() => {
      console.log(
        "[SW_updated]: ",
        registration.waiting?.scriptURL,
        registration.waiting?.state
      );
      registration.waiting?.postMessage("SKIP_WAITING");
    });
  }
});
