import { faSave, faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonListHeader,
  IonModal,
  IonSkeletonText,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import React, { useCallback, useEffect, useState } from "react";
import { useNotificationContext } from "../../context/NotificationProvider";
import useApi from "../../data/Api";
import { getEnumValues } from "../../data/numberHelpers";
import useTranslation from "../../context/LanguageProvider";
import useCurrency from "../../hooks/useCurrency";
import ProductDto, { ExtraDto } from "../../models/Product";
import { ProductType } from "../../models/Teeth";
import ButtonTextIcon from "../ButtonTextIcon";
import Icon from "../Icon";
import { OptionsType, OptionTypeBase } from "react-select";
import { Permission } from "../../models/Permissions";
import { useAuthContext } from "../../context/AuthProvider";
import AsyncCreatableSelect from "react-select/async-creatable";
import ExtrasUpsertModal from "./ExtrasUpsertModal";
import Can from "../Can";
import SelectAndButton from "../SelectAndButton";
import ModalWrapper from "../ModalWrapper";

interface SelectOption extends OptionTypeBase {
  label: string;
  value: string;
}

interface Props {
  onSuccess: (id: number) => void;
  onCancel: () => void;
  showModal: boolean;
  initialData: ProductDto;
}

const ProductUpsertModal: React.FC<Props> = ({
  onSuccess,
  onCancel,
  initialData,
  showModal
}) => {
  const { apiGet, apiPost } = useApi();
  const { handleError, showErrorToast } = useNotificationContext();
  const { t, tPlaceholder, tProductType } = useTranslation();
  const { currencySign } = useCurrency();
  const { user } = useAuthContext();

  const [showExtrasUpsertModal, setShowExtrasUpsertModal] = useState(false);
  const [initialExtrasModalData, setInitialExtrasModalData] =
    useState<ExtraDto>({ id: 0, price: 0, name: "" });
  const [isSubmitting, setSubmitting] = useState(false);
  const [product, setProduct] = useState<ProductDto>(initialData);
  const [selectedExtras, setSelectedExtras] = useState<
    OptionsType<SelectOption>
  >([]);
  const [allExtras, setAllExtras] = useState<OptionsType<SelectOption>>();

  const loadExtras = useCallback(
    (search: string) =>
      apiGet<ExtraDto[]>(`extras/getAll?searchTerm=${search}`)
        .then(data => {
          return data.map(e => ({
            label: e.name,
            value: e.id.toString()
          }));
        })
        .catch(e => {
          handleError(e);
          return [];
        }),
    [apiGet, handleError]
  );

  useEffect(() => {
    setProduct(initialData);
  }, [initialData]);

  useEffect(() => {
    loadExtras("").then(allExtras => {
      setAllExtras(allExtras);
      setSelectedExtras(
        initialData.extras.map(
          t => allExtras.find(e => e.value === t.toString())!
        )
      );
    });
  }, [initialData.extras, loadExtras]);

  useEffect(() => {
    setProduct(p => ({
      ...p,
      extras: selectedExtras.map(e => parseInt(e.value))
    }));
  }, [selectedExtras]);

  const onCreateExtra = useCallback(
    (inputValue: string) => {
      if (user?.hasPermission(Permission.ProductsCreate)) {
        setInitialExtrasModalData({ id: 0, price: 0, name: inputValue });
        setShowExtrasUpsertModal(true);
      } else {
        showErrorToast(t("noPermissionError"));
      }
    },
    [showErrorToast, t, user]
  );

  const handleExtraChange = useCallback(
    (newValue: any) => setSelectedExtras(newValue ?? []),
    []
  );

  const upsert = useCallback(() => {
    setSubmitting(true);
    apiPost<number>("product/upsert", product)
      .then(onSuccess)
      .catch(handleError)
      .finally(() => setSubmitting(false));
  }, [apiPost, handleError, product]);

  return (
    <ModalWrapper
      modalOpened={showModal}
      dismiss={onCancel}
      modal="productUpsert"
    >
      <IonModal isOpen={showModal} onDidDismiss={onCancel}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                <ButtonTextIcon button="cancel" />
              </IonButton>
            </IonButtons>
            <IonTitle>
              {initialData.id > 0 ? t("products.edit") : t("products.new")}
            </IonTitle>
            <IonButtons slot="primary">
              <IonButton onClick={upsert} disabled={isSubmitting}>
                <ButtonTextIcon button="save" loading={isSubmitting} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItem lines="none">
            <IonLabel position="stacked">{t("name")} *</IonLabel>
            <IonInput
              placeholder={t("products.placeholder")}
              clearInput
              value={product.name}
              onIonChange={e =>
                setProduct(p => ({ ...p, name: e.detail.value! }))
              }
            />
          </IonItem>
          <IonItem lines="none">
            <IonLabel position="stacked">
              {t("price") + " (" + currencySign + ")"}
            </IonLabel>
            <IonInput
              type="number"
              min="0.0"
              placeholder={tPlaceholder("price")}
              clearInput
              value={product.price ? product.price : undefined}
              onIonChange={e =>
                setProduct(p => ({ ...p, price: parseFloat(e.detail.value!) }))
              }
            />
          </IonItem>
          {initialData.id > 0 && (
            <>
              <SelectAndButton
                label={t("products.productExtras")}
                select={
                  allExtras ? (
                    <AsyncCreatableSelect
                      placeholder={t("products.productExtrasPlaceholder")}
                      classNamePrefix={"rselect"}
                      isClearable
                      isMulti
                      cacheOptions
                      defaultOptions={allExtras}
                      value={selectedExtras}
                      // isLoading={newExtraName !== undefined}
                      loadingMessage={() => t("loading")}
                      loadOptions={loadExtras}
                      onCreateOption={onCreateExtra}
                      allowCreateWhileLoading={false}
                      formatCreateLabel={o =>
                        `${t("permissions.types.create")}: "${o}"`
                      }
                      noOptionsMessage={() => t("noRecordsTypeToAdd")}
                      onChange={handleExtraChange}
                    />
                  ) : (
                    <IonSkeletonText animated />
                  )
                }
                button={
                  <Can permission={Permission.ProductsCreate}>
                    <IonButton
                      color="secondary"
                      fill="outline"
                      onClick={() => setShowExtrasUpsertModal(true)}
                    >
                      <ButtonTextIcon button="createNew" />
                    </IonButton>
                  </Can>
                }
              />
              <IonListHeader className="ion-no-padding">
                {t("products.productCanBeAdded")}
              </IonListHeader>
              {getEnumValues(ProductType).map(pt => (
                <IonItem key={pt}>
                  <IonCheckbox
                    slot="start"
                    color="primary"
                    checked={product.productTypes.includes(pt)}
                    onIonChange={e =>
                      setProduct(p => ({
                        ...p,
                        productTypes: e.detail.checked
                          ? [...p.productTypes, pt]
                          : p.productTypes.filter(t => t !== pt)
                      }))
                    }
                  />
                  <IonLabel> {tProductType(pt)}</IonLabel>
                </IonItem>
              ))}
            </>
          )}
          <IonButton
            class="ion-margin-top"
            color="success"
            expand="block"
            type="submit"
            disabled={!product.name || isSubmitting}
            onClick={upsert}
          >
            {isSubmitting ? (
              <Icon spin icon={faSpinner} />
            ) : (
              <Icon icon={faSave} />
            )}
            {t("save")}
          </IonButton>
          <ExtrasUpsertModal
            showModal={showExtrasUpsertModal}
            initialData={initialExtrasModalData}
            onCancel={() => {
              setInitialExtrasModalData({ id: 0, price: 0, name: "" });
              setShowExtrasUpsertModal(false);
            }}
            onSuccess={async id => {
              try {
                const allExtras = await loadExtras("");
                setAllExtras(allExtras);
                const newOption = allExtras.find(
                  e => e.value === id.toString()
                )!;
                setSelectedExtras(s => [...s, newOption]);
                setInitialExtrasModalData({ id: 0, price: 0, name: "" });
              } finally {
                setShowExtrasUpsertModal(false);
              }
            }}
          />
        </IonContent>
      </IonModal>
    </ModalWrapper>
  );
};

export default ProductUpsertModal;
