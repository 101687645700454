import React from "react";
import useTranslation, {
  Language,
  Languages
} from "../context/LanguageProvider";
import { Select } from "antd";

const { Option } = Select;

interface Props {
  onChange?: (lng: Language) => void;
  darkBg?: boolean;
}

const LanguageSelect: React.FC<Props> = ({ onChange, darkBg }) => {
  const { t, language, changeLanguage } = useTranslation();
  return (
    <Select
      placeholder={t("selectLanguage")}
      size="large"
      className={darkBg ? "whiteColor" : ""}
      showSearch
      value={language}
      onChange={v => {
        changeLanguage(v);
        onChange && onChange(v);
      }}
      filterOption={(input, option) => {
        const lng = Languages.find(l => l.value === option?.value);
        if (!lng) return false;

        return (
          [lng.name, lng.value, ...(lng.alt ?? [])].find(
            e => e.toLowerCase().indexOf(input.toLowerCase()) >= 0
          ) !== undefined
        );
      }}
    >
      {Languages.map(o => (
        <Option value={o.value} key={o.value} title={o.name}>
          <img src={o.flag} alt="" className="flag" />
          {o.name}
        </Option>
      ))}
    </Select>
  );
};

export default LanguageSelect;
