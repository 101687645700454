import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonMenuButton,
  IonGrid,
  IonCol,
  IonRow,
  IonButton,
  IonSkeletonText,
  IonFab,
  IonFabButton,
  IonIcon,
  IonSegment
} from "@ionic/react";
import React, { useCallback, useEffect, useState } from "react";
import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";
import useTranslation from "../../context/LanguageProvider";
import { useAuthContext } from "../../context/AuthProvider";
import { CaseStatus, ProductionLogFormDto } from "../../models/Case";
import NoResults from "../NoResults";
import ProductionLogUpdateModal from "../productionLog/ProductionLogUpdateModal";
import DashboardDto from "../../models/DashboardDto";
import DashboardCard from "./DashboardCard";
import Icon from "../Icon";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Can from "../Can";
import { add } from "ionicons/icons";
import { useNotificationContext } from "../../context/NotificationProvider";
import Refresher from "../Refresher";
import ButtonTextIcon from "../ButtonTextIcon";
import useLab from "../../context/LabProvider";
import DashboardSegmentButton from "./DashboardSegmentButton";
import HideForDoctor from "../navigation/HideForDoctor";
import ShowForDr from "../navigation/ShowForDoctor";

const Dashboard: React.FC = () => {
  const { user } = useAuthContext();
  const { dashboardData: data } = useLab();
  const { showToast } = useNotificationContext();

  const [productionLogData, setProductionLogData] =
    useState<ProductionLogFormDto>({
      id: 0,
      userId: 0,
      caseId: 0,
      taskId: 0,
      status: 0
    });
  const { t } = useTranslation();

  const [segment, setSegment] = useState<
    | "active"
    | "outOfLab"
    | "onHold"
    | "readyForShippingToDr"
    | "readyForShippingToLab"
    | "pendingAccept"
  >("active");

  const [filteredData, setFilteredData] = useState<DashboardDto[]>([]);

  const [showProductionLogModal, setShowProductionLogModal] = useState(false);
  useEffect(() => {
    setShowProductionLogModal(productionLogData.caseId > 0);
  }, [productionLogData]);

  const hasUserData = useCallback(
    (d: DashboardDto) =>
      d.logs.find(l => l.userId === user?.userId) !== undefined,
    [user?.userId]
  );
  const hasNoUserData = useCallback(
    (d: DashboardDto) => !hasUserData(d),
    [hasUserData]
  );

  useEffect(() => {
    let status = CaseStatus.InProgress;
    if (segment === "outOfLab") status = CaseStatus.OutOfLab;
    else if (segment === "pendingAccept") status = CaseStatus.PendingAccept;
    else if (segment === "readyForShippingToLab")
      status = CaseStatus.ReadyForShippingToLab;
    else if (segment === "onHold") status = CaseStatus.OnHold;
    else if (segment === "readyForShippingToDr")
      status = CaseStatus.ReadyForShippingToDr;

    let filtered: DashboardDto[] = [];
    // if doctor
    if (user?.doctorId !== undefined) {
      if (status === CaseStatus.InProgress)
        filtered =
          data?.filter(
            d =>
              d.status === status ||
              d.status === CaseStatus.ReadyForShippingToDr
          ) ?? [];
      else if (status === CaseStatus.OutOfLab)
        filtered =
          data?.filter(
            d =>
              d.status === status ||
              d.status === CaseStatus.ReadyForShippingToLab
          ) ?? [];
      else filtered = data?.filter(d => d.status === status) ?? [];
    }
    // if technician
    else {
      if (status === CaseStatus.OutOfLab)
        filtered =
          data?.filter(
            d =>
              d.status === status ||
              d.status === CaseStatus.ReadyForShippingToLab
          ) ?? [];
      else filtered = data?.filter(d => d.status === status) ?? [];
    }

    // currently active segment has no more items (updated by firestore)
    if (filtered.length === 0) setSegment("active");

    // put My Cases first
    setFilteredData(
      filtered.filter(hasUserData).concat(filtered.filter(hasNoUserData))
    );
  }, [segment, data, user?.doctorId, hasUserData, hasNoUserData]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{t("dashboard.title")}</IonTitle>
          <Can permission={Permission.CaseCreate}>
            <IonButtons slot="primary">
              <IonButton routerLink="/new-case">
                <ButtonTextIcon button="addCase" />
              </IonButton>
            </IonButtons>
          </Can>
        </IonToolbar>
        <IonToolbar className="header_toolbar2">
          <IonSegment
            hidden={
              data &&
              (data.length === 0 ||
                data.filter(d => d.status === CaseStatus.InProgress).length ===
                  data.length)
            }
            onIonChange={e => setSegment(e.detail.value as any)}
            value={segment}
            className="dashboard-segment"
          >
            {/* show in clinic cases first for dr */}
            <ShowForDr>
              <DashboardSegmentButton
                statuses={[
                  CaseStatus.OutOfLab,
                  CaseStatus.ReadyForShippingToLab
                ]}
                value="outOfLab"
                data={data ?? []}
              />
            </ShowForDr>
            <DashboardSegmentButton
              statuses={
                user?.doctorId !== undefined
                  ? [CaseStatus.InProgress, CaseStatus.ReadyForShippingToDr]
                  : [CaseStatus.InProgress]
              }
              value="active"
              data={data ?? []}
            />
            <DashboardSegmentButton
              statuses={[CaseStatus.PendingAccept]}
              value="pendingAccept"
              data={data ?? []}
            />

            <HideForDoctor>
              <DashboardSegmentButton
                statuses={[CaseStatus.ReadyForShippingToDr]}
                value="readyForShippingToDr"
                data={data ?? []}
              />
              <DashboardSegmentButton
                statuses={[
                  CaseStatus.OutOfLab,
                  CaseStatus.ReadyForShippingToLab
                ]}
                value="outOfLab"
                data={data ?? []}
              />

              <DashboardSegmentButton
                statuses={[CaseStatus.OnHold]}
                value="onHold"
                data={data ?? []}
              />
            </HideForDoctor>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Refresher
          onRefresh={e => {
            showToast({
              message: t("noNeedToRefresh"),
              color: "success",
              duration: 3000
            });
            e.detail.complete();
          }}
        />
        {!data && <IonSkeletonText animated title={t("loading")} />}
        {data && data.length === 0 && (
          <NoResults title={t("dashboard.noCases")}>
            <Can permission={Permission.CaseCreate}>
              <div className="ion-text-center ion-margin-top">
                <IonButton color="secondary" routerLink="/new-case">
                  <Icon icon={faPlus} />
                  {t("cases.new")}
                </IonButton>
              </div>
            </Can>
          </NoResults>
        )}
        {filteredData && filteredData.length > 0 && (
          <IonGrid>
            {filteredData.length > 0 && (
              <>
                <IonRow className="dashboard-boxes">
                  {filteredData.map((d, i) => (
                    <IonCol
                      key={i}
                      size-xs="12"
                      size-xl="4"
                      size-lg="6"
                      size-md="6"
                    >
                      <DashboardCard
                        dto={d}
                        setProductionLogInitialData={setProductionLogData}
                      />
                    </IonCol>
                  ))}
                </IonRow>
              </>
            )}
          </IonGrid>
        )}

        <Can permission={Permission.CaseCreate}>
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton routerLink="/new-case" title={t("cases.new")}>
              <IonIcon icon={add} />
            </IonFabButton>
          </IonFab>
        </Can>

        <ProductionLogUpdateModal
          showProductionLogModal={showProductionLogModal}
          initialData={productionLogData}
          onSuccess={() => setShowProductionLogModal(false)}
          onCancel={() => setShowProductionLogModal(false)}
        />
      </IonContent>
    </IonPage>
  );
};

export default withPermission(Dashboard, Permission.DashboardRead);
