import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  useIonViewWillEnter,
  IonLabel,
  IonItem,
  IonMenuButton,
  IonListHeader
} from "@ionic/react";
import React, { useState } from "react";
import {
  faBell,
  faEdit,
  faExchangeAlt,
  faNetworkWired,
  faPaperclip,
  faPuzzlePiece,
  faShoppingCart,
  faTooth,
  faTruckMoving,
  faUserEdit,
  faUserPlus,
  IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { useAuthContext } from "../context/AuthProvider";
import { useNotificationContext } from "../context/NotificationProvider";
import useApi from "../data/Api";
import LabDto, { LicenseType } from "../models/Lab";
import { Permission } from "../models/Permissions";
import Icon from "./Icon";
import Can from "./Can";
import useTranslation from "../context/LanguageProvider";
import LanguageSelect from "./LanguageSelect";
import { faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import useLab from "../context/LabProvider";
import ShowForLabs from "./navigation/ShowForLab";

interface MenuButtonProps {
  url: string;
  icon: IconDefinition;
  title: string;
}

const MenuButton: React.FC<MenuButtonProps> = ({ icon, title, url }) => (
  <IonItem button detail routerLink={url}>
    <Icon slot="start" size="lg" color="white" icon={icon} />
    <IonLabel>{title}</IonLabel>
  </IonItem>
);

const Settings: React.FC = () => {
  const { labId, user } = useAuthContext();
  const { subscription } = useLab();
  const { apiGet } = useApi();
  const { handleError } = useNotificationContext();
  const [labs, setLabs] = useState<LabDto[]>([]);
  const { t } = useTranslation();

  useIonViewWillEnter(() => {
    apiGet<LabDto[]>("lab/getall").then(setLabs).catch(handleError);
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{t("settings")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding settings-menu">
        <IonListHeader>{t("settings.titles.myProfile")}</IonListHeader>

        <MenuButton
          icon={faUserEdit}
          title={t("profile.editProfile")}
          url="/profile"
        />
        <MenuButton
          icon={faBell}
          title={t("notifications.title")}
          url="/notifications"
        />

        {labId && user?.doctorId === undefined && (
          <>
            <IonListHeader>{t("settings.titles.myLab")}</IonListHeader>
            <Can permission={Permission.LabUpdate}>
              <MenuButton
                icon={faEdit}
                title={t("editLab.title")}
                url="/edit-lab"
              />
            </Can>
            <div>
              <ShowForLabs labs={[20, 12, 14]}>
                <Can permission={Permission.LabUpdate}>
                  <MenuButton
                    icon={faTruckMoving}
                    title={t("courier.settingsTitle")}
                    url="/couriers"
                  />
                </Can>
              </ShowForLabs>
            </div>
            {subscription && subscription.license !== LicenseType.Free && (
              <Can permission={Permission.SubscriptionsUpdate}>
                <MenuButton
                  icon={faShoppingCart}
                  title={t("subscription.title")}
                  url="/change-subscription"
                />
              </Can>
            )}
            <Can permission={Permission.TaskRead}>
              <MenuButton
                icon={faNetworkWired}
                title={t("task.titlePlural")}
                url="/tasks"
              />
            </Can>
            {/* <Can permission={Permission.SchedulingTemplateRead}> */}
            <MenuButton
              icon={faNetworkWired}
              title={t("schedulingTemplate.titlePlural")}
              url="/scheduling-templates"
            />
            {/* </Can> */}

            <Can permission={Permission.ProductsRead}>
              <MenuButton
                icon={faTooth}
                title={t("products.title")}
                url="/products"
              />
              <MenuButton
                icon={faPaperclip}
                title={t("extras.title")}
                url="/extras"
              />
              <MenuButton
                icon={faPuzzlePiece}
                title={t("materials.title")}
                url="/materials"
              />
            </Can>
          </>
        )}

        <IonListHeader>{t("settings.titles.other")}</IonListHeader>
        <MenuButton
          icon={faUserPlus}
          title={t("joinLab.title")}
          url="/invitation"
        />
        {user?.doctorId === undefined && (
          <MenuButton
            icon={faPlusSquare}
            title={t("newLab.title")}
            url="/new-lab"
          />
        )}
        {labs.length > 1 && (
          <MenuButton
            icon={faExchangeAlt}
            title={t("changeLab.title")}
            url="/change-lab"
          />
        )}
        <IonListHeader>{t("selectLanguage")}</IonListHeader>

        <div className="ion-margin-horizontal">
          <LanguageSelect />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Settings;
