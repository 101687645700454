import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { useAuthContext } from "../../context/AuthProvider";
import { User } from "../../models/User";
import { allPages } from "./Navigation";

const findRoute = (user?: User) => {
  if (!user) return "/login";

  const initialPage = allPages.find(
    p => !p.permission || user.hasPermission(p.permission)
  );
  if (initialPage) return initialPage.url;

  return "/settings";
};

const InitialPath: React.FC = () => {
  const { user } = useAuthContext();
  const [initialPath, setInitialPath] = useState("");

  useEffect(() => {
    setInitialPath(findRoute(user));
  }, [user]);

  return initialPath ? <Redirect to={initialPath} /> : <></>;
};

export default InitialPath;
