import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  IonList,
  IonItemSliding,
  IonItem,
  IonLabel,
  IonItemOptions,
  IonItemOption,
  IonSkeletonText,
  IonButton
} from "@ionic/react";
import React, { useCallback, useEffect, useState } from "react";
import { useNotificationContext } from "../../context/NotificationProvider";
import useApi from "../../data/Api";
import useTranslation from "../../context/LanguageProvider";
import useCurrency from "../../hooks/useCurrency";
import useDate from "../../hooks/useDate";
import { InvoiceListDto } from "../../models/Invoice";
import { CaseViewDto } from "../../models/Case";
import PagedData from "../../models/PagedData";
import Icon from "../Icon";
import InvoiceUpsertModal from "../invoice/InvoiceUpsertModal";
import Can from "../Can";
import { Permission } from "../../models/Permissions";

interface Props {
  $case: CaseViewDto;
}

const CaseInvoices: React.FC<Props> = ({ $case }) => {
  const { formatWithCurrencySign } = useCurrency();
  const { apiGet } = useApi();
  const { handleError } = useNotificationContext();
  const { t, tInvoiceStatus } = useTranslation();
  const [invoices, setInvoices] = useState<InvoiceListDto[]>([]);
  const [invoiceUpsertId, setInvoiceUpsertId] = useState(0);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { toDateString } = useDate();

  const getInvoices = useCallback(() => {
    setLoading(true);
    return apiGet<PagedData<InvoiceListDto>>(
      `invoice/getAll?page=1&pageSize=150&caseId=${$case.id}`
    )
      .then(d => setInvoices(d.data))
      .catch(handleError)
      .finally(() => setLoading(false));
  }, [$case.id, apiGet, handleError]);

  useEffect(() => {
    getInvoices();
  }, [getInvoices]);

  return (
    <>
      <>
        <IonList class="box ion-no-padding">
          {loading ? (
            <IonSkeletonText animated />
          ) : (
            invoices.map((d, i) => (
              <IonItemSliding key={i}>
                <IonItem
                  lines="none"
                  button
                  detail
                  routerLink={"/invoice/" + d.id}
                >
                  <Icon
                    slot="start"
                    title={tInvoiceStatus(d.status)}
                    size="1x"
                    icon={faCircle}
                    className={"invoiceIcon" + d.status}
                  />
                  <IonLabel>
                    {t("invoice")} #
                    <span className="content-font">{d.number}</span>
                    <p>
                      {toDateString(d.created)}{" "}
                      <span className={"invoiceIcon" + d.status}>
                        {tInvoiceStatus(d.status)}
                      </span>
                    </p>
                  </IonLabel>
                  <div slot="end">{formatWithCurrencySign(d.total)}</div>
                </IonItem>
                <IonItemOptions side="end">
                  <IonItemOption
                    color="success"
                    routerLink={"/invoice/" + d.id}
                  >
                    {t("view")}
                  </IonItemOption>
                  <IonItemOption
                    onClick={() => {
                      setInvoiceUpsertId(d.id);
                      setShowInvoiceModal(true);
                    }}
                  >
                    <Icon icon={faEdit} />
                    {t("edit")}
                  </IonItemOption>
                </IonItemOptions>
              </IonItemSliding>
            ))
          )}
        </IonList>

        <Can permission={Permission.InvoiceCreate}>
          <div className="ion-text-center ion-margin-top" hidden={loading}>
            <h4>{t("invoices.createNewDescription")}</h4>
            <IonButton
              onClick={() => {
                setInvoiceUpsertId(0);
                setShowInvoiceModal(true);
              }}
              color="secondary"
            >
              <Icon icon={faPlus} />
              {t("invoices.create")}
            </IonButton>
          </div>
        </Can>
      </>

      <InvoiceUpsertModal
        id={invoiceUpsertId}
        $case={$case}
        showInvoiceModal={showInvoiceModal}
        onSuccess={() => {
          setShowInvoiceModal(false);
          getInvoices();
        }}
        onCancel={() => setShowInvoiceModal(false)}
      />
    </>
  );
};

export default CaseInvoices;
