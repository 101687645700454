import React, { useCallback, useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { DoctorInLabDto } from "../../models/Doctor";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import useTranslation from "../../context/LanguageProvider";
import ButtonTextIcon from "../ButtonTextIcon";
import ModalWrapper from "../ModalWrapper";
import Icon from "../Icon";
import { faSave, faSpinner } from "@fortawesome/free-solid-svg-icons";

interface Props {
  onSuccess: (id: number) => void;
  onCancel: () => void;
  showDoctorInvitationModal: boolean;
  initialData: DoctorInLabDto;
}
const DoctorInvitationModal: React.FC<Props> = ({
  onSuccess,
  onCancel,
  initialData,
  showDoctorInvitationModal
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const { apiPost } = useApi();
  const { t } = useTranslation();
  const [data, setData] = useState<DoctorInLabDto>({
    userId: 0,
    doctorId: 0,
    name: "",
    email: "",
    allowAccess: true,
    canCreateCase: true,
    canViewInvoices: true,
    canViewProductionLogs: false
  });
  const { handleError } = useNotificationContext();

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  const upsert = useCallback(() => {
    console.log(data);
    setSubmitting(true);
    apiPost<number>(
      data.userId ? "doctor/UpdateLabAccess" : "doctor/invite",
      data
    )
      .then(onSuccess)
      .catch(handleError)
      .finally(() => setSubmitting(false));
  }, [data, apiPost, handleError, onSuccess]);

  return (
    <ModalWrapper
      modalOpened={showDoctorInvitationModal}
      dismiss={onCancel}
      modal="doctorInvitationUpsert"
    >
      <IonModal isOpen={showDoctorInvitationModal} onDidDismiss={onCancel}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                <ButtonTextIcon button="cancel" />
              </IonButton>
            </IonButtons>
            <IonTitle>
              {data.userId
                ? t("doctors.editInvitation")
                : t("doctors.newInvitation")}
            </IonTitle>
            <IonButtons slot="primary">
              <IonButton onClick={upsert} disabled={isSubmitting}>
                <ButtonTextIcon button="save" loading={isSubmitting} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItem lines="none" hidden={data.userId > 0}>
            <IonLabel position="stacked">{t("name")} *</IonLabel>
            <IonInput
              autocomplete="new-password"
              clearInput
              value={data.name}
              onIonChange={e => setData(d => ({ ...d, name: e.detail.value! }))}
            />
          </IonItem>
          <IonItem lines="none" hidden={data.userId > 0}>
            <IonLabel position="stacked">{t("email")} *</IonLabel>
            <IonInput
              autocomplete="new-password"
              clearInput
              value={data.email}
              onIonChange={e =>
                setData(d => ({ ...d, email: e.detail.value! }))
              }
            />
          </IonItem>
          <IonItem hidden={data.userId === 0}>
            <IonCheckbox
              slot="start"
              color="primary"
              checked={data.allowAccess}
              onIonChange={e =>
                setData(d => ({ ...d, allowAccess: e.detail.checked }))
              }
            />
            <IonLabel> {t("doctors.allowAccess")}</IonLabel>
          </IonItem>
          <IonItem>
            <IonCheckbox
              slot="start"
              color="primary"
              checked={data.canCreateCase}
              onIonChange={e =>
                setData(d => ({ ...d, canCreateCase: e.detail.checked }))
              }
            />
            <IonLabel> {t("doctors.allowCreateCase")}</IonLabel>
          </IonItem>
          <IonItem>
            <IonCheckbox
              slot="start"
              color="primary"
              checked={data.canViewInvoices}
              onIonChange={e =>
                setData(d => ({ ...d, canViewInvoices: e.detail.checked }))
              }
            />
            <IonLabel> {t("doctors.allowViewInvoices")}</IonLabel>
          </IonItem>
          <IonItem>
            <IonCheckbox
              slot="start"
              color="primary"
              checked={data.canViewProductionLogs}
              onIonChange={e =>
                setData(d => ({
                  ...d,
                  canViewProductionLogs: e.detail.checked
                }))
              }
            />
            <IonLabel> {t("doctors.allowViewProductionLogs")}</IonLabel>
          </IonItem>
          <IonButton
            class="ion-margin-top"
            color="success"
            expand="block"
            type="submit"
            disabled={!data.name || !data.email || isSubmitting}
            onClick={upsert}
          >
            {isSubmitting ? (
              <Icon spin icon={faSpinner} />
            ) : (
              <Icon icon={faSave} />
            )}
            {t(data.userId ? "edit" : "save")}
          </IonButton>
        </IonContent>
      </IonModal>
    </ModalWrapper>
  );
};

export default DoctorInvitationModal;
