import React, { useCallback, useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import useTranslation from "../../context/LanguageProvider";
import ButtonTextIcon from "../ButtonTextIcon";
import { ProductionLogFormDto, ProductionLogStatus } from "../../models/Case";
import TaskUpsertModal from "../task/TaskUpsertModal";
import Icon from "../Icon";
import InfoBox from "../InfoBox";
import Can from "../Can";
import { Permission } from "../../models/Permissions";
import ModalWrapper from "../ModalWrapper";
import useLab from "../../context/LabProvider";

interface Props {
  onSuccess: (id: number) => void;
  onCancel: () => void;
  showModal: boolean;
  caseId: number;
}

const ProductionLogCreateModal: React.FC<Props> = ({
  onSuccess,
  onCancel,
  caseId,
  showModal
}) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<ProductionLogFormDto>({
    id: 0,
    userId: 0,
    caseId: caseId,
    taskId: null,
    notes: "",
    status: ProductionLogStatus.Started
  });
  const { tasks } = useLab();
  const { apiPost } = useApi();
  const { t } = useTranslation();

  const { handleError } = useNotificationContext();

  const [showTaskModal, setShowTaskModal] = useState(false);

  const upsert = useCallback(() => {
    setLoading(true);
    apiPost<number>("productionLog/upsert", formData)
      .then(onSuccess)
      .catch(handleError)
      .finally(() => setLoading(false));
  }, [formData, apiPost, handleError]);

  useEffect(() => {
    setFormData({
      id: 0,
      userId: 0,
      caseId: caseId,
      taskId: null,
      notes: "",
      status: ProductionLogStatus.Started
    });
  }, [caseId, showModal]);

  return (
    <ModalWrapper modalOpened={showModal} dismiss={onCancel} modal="plCreate">
      <IonModal isOpen={showModal} onDidDismiss={onCancel}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                <ButtonTextIcon button="cancel" />
              </IonButton>
            </IonButtons>
            <IonTitle>{t("workLog.add")}</IonTitle>
            <IonButtons slot="primary">
              <IonButton
                onClick={upsert}
                disabled={loading || !formData.caseId}
              >
                <ButtonTextIcon button={"add"} loading={loading} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonRow className="ion-align-items-center">
            <IonCol>
              <IonItem lines="none" hidden={!tasks.length}>
                <IonLabel position="stacked">{t("task.title")}</IonLabel>
                <IonSelect
                  value={formData.taskId}
                  placeholder="Select One"
                  interface="action-sheet"
                  cancelText={t("cancel")}
                  onIonChange={e => {
                    setFormData({
                      ...formData,
                      taskId: e.detail.value
                    });
                  }}
                >
                  <IonSelectOption key="null" value={null}>
                    {t("none")}
                  </IonSelectOption>

                  {tasks.map(d => (
                    <IonSelectOption key={d.id} value={d.id}>
                      {d.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </IonCol>
            <Can permission={Permission.TaskCreate}>
              <IonCol size="auto">
                <IonButton
                  // size="small"
                  color="secondary"
                  fill="outline"
                  className="ion-margin-top"
                  onClick={() => setShowTaskModal(true)}
                >
                  <Icon icon={faPlus} />
                  {t("task.new")}
                </IonButton>
              </IonCol>
            </Can>
          </IonRow>
          <IonRadioGroup
            hidden={!formData.taskId}
            value={formData.status}
            onIonChange={e =>
              setFormData({
                ...formData,
                status: e.detail.value!
              })
            }
          >
            <IonLabel position="stacked">{t("status")}</IonLabel>

            <IonItem>
              <IonLabel> {t("workLog.startedWorkingOnIt")}</IonLabel>
              <IonRadio slot="start" value={ProductionLogStatus.Started} />
            </IonItem>

            <IonItem>
              <IonLabel> {t("workLog.iFinishedTask")}</IonLabel>
              <IonRadio slot="start" value={ProductionLogStatus.Done} />
            </IonItem>

            <IonItem>
              <IonLabel color="danger"> {t("workLog.taskFailed")}</IonLabel>
              <IonRadio slot="start" value={ProductionLogStatus.Failed} />
            </IonItem>
          </IonRadioGroup>

          <IonItem lines="none">
            <IonLabel position="stacked">{t("note")}</IonLabel>
            <IonTextarea
              onIonChange={e =>
                setFormData({
                  ...formData,
                  notes: e.detail.value!
                })
              }
              required={!formData.taskId}
              value={formData.notes}
              placeholder={t("note")}
            />
          </IonItem>
          <IonButton
            onClick={upsert}
            className="ion-margin-top"
            expand="block"
            color="success"
            disabled={loading || !formData.caseId}
          >
            {loading ? <Icon spin icon={faSpinner} /> : <Icon icon={faPlus} />}
            {t("add")}
          </IonButton>
          <InfoBox hidden={tasks.length > 0} text={t("task.noDataInfo")} />
        </IonContent>
        <Can permission={Permission.TaskCreate}>
          <TaskUpsertModal
            initialData={{ id: 0, name: "" }}
            showTaskModal={showTaskModal}
            onSuccess={d => {
              setFormData(formData => ({ ...formData, taskId: d }));
              //fetchData();
              //showSuccessToast(t("task.added"));
              setShowTaskModal(false);
            }}
            onCancel={() => setShowTaskModal(false)}
          />
        </Can>
      </IonModal>
    </ModalWrapper>
  );
};

export default ProductionLogCreateModal;
