import {
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonSkeletonText,
  IonInput,
  useIonViewWillEnter,
  IonButton,
  IonToolbar,
  IonSegment,
  IonSegmentButton
} from "@ionic/react";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import React, { useCallback, useEffect, useState } from "react";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import { useHistory } from "react-router-dom";
import { SortDirection } from "../../models/PagedData";
import useTranslation from "../../context/LanguageProvider";
import NoResults from "../NoResults";
import { CaseListDto, CaseStatus, CasesSummaryDto } from "../../models/Case";
import CaseStatusIcon from "./CaseStatusIcon";
import { CasesUrlProps } from "../../models/UrlProps";
import useDate from "../../hooks/useDate";
import CaseFlagIcon from "./CaseFlagIcon";
import Icon from "../Icon";
import { faDownload, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { getEnumValues } from "../../data/numberHelpers";
import SelectDoctor from "../doctor/SelectDoctor";
import ItemLabel from "../ItemLabel";
import { RefresherEventDetail } from "@ionic/core/components";

import { Select } from "antd";
import PopupDateRangePicker from "../PopupDateRangePicker";
import CasesStats from "../stats/CasesStats";
import Refresher from "../Refresher";
import { useAuthContext } from "../../context/AuthProvider";

const { Option } = Select;

const useCasesTable = (
  initialUrlProps: CasesUrlProps,
  searchProp: string,
  mode: "cases" | "doctorCases"
) => {
  const [loading, setLoading] = useState(false);

  const { apiPost } = useApi();
  const { handleError } = useNotificationContext();
  const [cases, setCases] = useState<CasesSummaryDto>();
  const { t, tCaseStatus } = useTranslation();
  const { toDateString } = useDate();
  const { user } = useAuthContext();
  const [columns, setColumns] = useState<IDataTableColumn<CaseListDto>[]>([]);

  const [urlProps, setUrlProps] = useState<CasesUrlProps>({
    ...initialUrlProps,
    search: searchProp
  });

  const fetchCases = useCallback(
    (refreshEvent?: CustomEvent<RefresherEventDetail>) => {
      setLoading(true);
      apiPost<CasesSummaryDto>(`case/getFiltered`, urlProps)
        .then(data => {
          setCases(data);
        })
        .catch(handleError)
        .finally(() => {
          setLoading(false);
          if (refreshEvent) refreshEvent.detail.complete();
        });
    },
    [urlProps, apiPost, handleError]
  );

  useIonViewWillEnter(() => {
    fetchCases();
  }, [fetchCases]);

  useEffect(() => {
    fetchCases();
  }, [fetchCases]);

  useEffect(() => {
    if (searchProp !== urlProps.search)
      setUrlProps(urlProps => ({ ...urlProps, search: searchProp }));
  }, [searchProp, urlProps.search]);

  useEffect(() => {
    setColumns([
      {
        name: t("cases.pan"),
        selector: "pan",
        sortable: true,
        grow: 1,
        cell: row => (
          <IonItem
            lines="none"
            class="ion-no-padding"
            routerLink={"/case/" + row.id}
          >
            {/* <CaseStatusIcon {...row} title={tCaseStatus(row.status)} /> */}
            <IonLabel className="cases-table-col-name">
              <CaseFlagIcon flag={row.flag} />
              {row.pan ?? (row.autoName ? null : row.name)}
              <p className="case-status" hidden>
                <CaseStatusIcon {...row} />
              </p>
              <p className="ion-hide-sm-up">
                <b hidden={mode === "doctorCases"}>{row.doctor.name} </b>
                <span hidden={mode === "doctorCases" || !row.patient}>, </span>
                <span hidden={!row.patient}>{row.patient}</span>
              </p>
              <p
                className={
                  row.status === CaseStatus.Cancelled
                    ? "dangerColor"
                    : row.status === CaseStatus.Finished
                    ? "successColor"
                    : ""
                }
              >
                {tCaseStatus(row.status, user?.doctorId !== undefined)}
              </p>
              <p className="ion-hide-sm-up smaller-font margin-top-6">
                {t("dates.received") + ": " + toDateString(row.received)}
              </p>
              {row.appointmentDate && (
                <p className="ion-hide-sm-up smaller-font">
                  {t("dates.finishDate") +
                    ": " +
                    toDateString(row.appointmentDate)}
                </p>
              )}
              <p hidden={row.hasInvoice} className="smaller-font-warning">
                {t("invoices.invoiceNotCreated")}!
              </p>
            </IonLabel>
          </IonItem>
        )
      },
      {
        name: t("doctor"),
        selector: "doctor",
        sortable: true,
        grow: 1,
        cell: row => row.doctor.name,
        hide: "sm",
        omit: mode === "doctorCases"
      },
      {
        name: t("patient"),
        selector: "patient",
        sortable: true,
        grow: 1,
        cell: row => row.patient,
        hide: "sm"
      },
      {
        name: t("dates.received"),
        selector: "received",
        sortable: true,
        grow: 1,
        cell: row => toDateString(row.received),
        hide: "sm"
      },
      {
        name: t("dates.finishDate"),
        selector: "finishDate",
        sortable: true,
        grow: 1,
        cell: row => row.appointmentDate && toDateString(row.appointmentDate),
        hide: "sm"
      }
    ]);
  }, [mode, t, tCaseStatus, toDateString, user?.doctorId]);

  return { columns, cases, urlProps, loading, fetchCases, setUrlProps };
};

interface Props {
  mode: "cases" | "doctorCases";
  initialUrlProps: CasesUrlProps;
  showFilterProp: boolean;
  searchProp: string;
  exposeSaveAsPdf?: (submit: () => void) => void;
  setSavingPdf?: (v: boolean) => void;
}

const CasesTable: React.FC<Props> = ({
  mode,
  initialUrlProps,
  showFilterProp,
  searchProp,
  exposeSaveAsPdf,
  setSavingPdf
}) => {
  const [downloading, setDownloading] = useState(false);
  const history = useHistory();

  const { apiBlobDownload } = useApi();
  const { handleError } = useNotificationContext();
  const { t, tProductType, tCaseStatus } = useTranslation();
  const { user } = useAuthContext();
  const [segment, setSegment] = useState<"table" | "summary">("table");
  const { columns, cases, urlProps, loading, fetchCases, setUrlProps } =
    useCasesTable(initialUrlProps, searchProp, mode);

  const saveAsPdf = useCallback(() => {
    setDownloading(true);
    apiBlobDownload("POST", `case/SaveListAsPdf`, urlProps)
      .catch(handleError)
      .finally(() => setDownloading(false));
  }, [urlProps, apiBlobDownload, handleError]);

  useEffect(() => setSavingPdf && setSavingPdf(downloading), [downloading]);

  useEffect(() => {
    if (exposeSaveAsPdf) exposeSaveAsPdf(saveAsPdf);
  }, [saveAsPdf]);

  const handleSort = useCallback(
    (column: IDataTableColumn<CaseListDto>, sortDirection: "desc" | "asc") => {
      setUrlProps(urlProps => ({
        ...urlProps,
        sortDirection:
          sortDirection === "desc" ? SortDirection.Desc : SortDirection.Asc,
        sortBy: column.selector?.toString(),
        page: 1
      }));
    },
    [setUrlProps]
  );

  const handlePageChange = useCallback(
    (page: number) => setUrlProps(urlProps => ({ ...urlProps, page: page })),
    [setUrlProps]
  );

  const handleDoctorChange = useCallback(
    (id: number | undefined) =>
      setUrlProps(urlProps => ({
        ...urlProps,
        doctorId: id
      })),
    [setUrlProps]
  );

  return (
    <>
      <Refresher onRefresh={fetchCases} />
      <IonGrid>
        <IonRow hidden={!showFilterProp}>
          <IonCol size-xs="6" size-xl="4" hidden={mode === "doctorCases"}>
            <SelectDoctor onChange={handleDoctorChange} />
          </IonCol>
          <IonCol size-xs="6" size-xl="4" hidden={mode === "cases"}>
            <ItemLabel label={t("search")}>
              <IonInput
                className="ion-no-margin"
                debounce={200}
                placeholder={t("search")}
                onIonChange={e =>
                  setUrlProps(urlProps => ({
                    ...urlProps,
                    search: e.detail.value!
                  }))
                }
              />
            </ItemLabel>
          </IonCol>

          <IonCol size-xs="6" size-xl="4">
            <ItemLabel label={t("status")}>
              <Select
                size="large"
                // mode="multiple"
                // defaultValue={urlProps.status!== null ? urlProps : ""}
                onChange={e => {
                  setUrlProps(urlProps => ({
                    ...urlProps,
                    status: e === "" ? null : (e as CaseStatus)
                  }));
                }}
              >
                <Option value="">{t("all")}</Option>
                {getEnumValues(CaseStatus).map(t => (
                  <Option key={t} value={t}>
                    {tCaseStatus(t, user?.doctorId !== undefined)}
                  </Option>
                ))}
              </Select>
            </ItemLabel>
            {/* <IonItem lines="none" className="ion-margin-none">
              <IonLabel position="stacked">{t("status")}</IonLabel>
              <IonSelect
                interface="action-sheet"
                cancelText={t("cancel")}
                value={urlProps.status}
                onIonChange={e =>
                  setUrlProps({ ...urlProps, status: e.detail.value! })
                }
              >
                <IonSelectOption value={null}>{t("all")}</IonSelectOption>
                {getEnumValues(CaseStatus).map(t => (
                  <IonSelectOption value={t} key={t}>
                    {tCaseStatus(t, user?.doctorId !== undefined)}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem> */}
          </IonCol>
          <IonCol size-xs="12" size-xl="4">
            <ItemLabel label={t("dates.chooseDate")}>
              <PopupDateRangePicker
                onChange={({ start, end }) =>
                  setUrlProps(urlProps => ({
                    ...urlProps,
                    fromDate: start,
                    toDate: end
                  }))
                }
                start={urlProps.fromDate}
                end={urlProps.toDate}
              />
            </ItemLabel>
          </IonCol>
        </IonRow>

        <IonToolbar>
          <IonSegment value={segment} mode="ios">
            <IonSegmentButton value="table" onClick={() => setSegment("table")}>
              {t("table")}
            </IonSegmentButton>
            <IonSegmentButton
              value="summary"
              onClick={() => setSegment("summary")}
            >
              {t("summary")}
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonGrid>
      {!cases && loading && <IonSkeletonText animated title={t("loading")} />}
      {cases && (
        <>
          {segment === "table" && (
            <>
              <DataTable
                className="box lower-top-padding"
                noHeader
                pointerOnHover
                columns={columns}
                data={cases.data.data}
                progressPending={loading}
                progressComponent={<IonSkeletonText animated />}
                pagination
                paginationServer
                paginationTotalRows={cases.data.count}
                defaultSortField="id"
                defaultSortAsc={false}
                onRowClicked={row => history.push("/case/" + row.id)}
                onChangeRowsPerPage={rows =>
                  setUrlProps(urlProps => ({ ...urlProps, pageSize: rows }))
                }
                noDataComponent={<NoResults title={t("noRecords")}></NoResults>}
                sortServer
                onSort={handleSort}
                onChangePage={handlePageChange}
                paginationComponentOptions={{
                  rowsPerPageText: t("dataTable.rowsPerPageText"),
                  rangeSeparatorText: t("dataTable.rangeSeparatorText")
                }}
              />
            </>
          )}
          {segment === "summary" && cases.data.data.length === 0 && (
            <NoResults title={t("noData")} />
          )}

          {segment === "summary" && cases.data.data.length > 0 && !loading && (
            <IonRow class="box">
              <IonCol size-xs="12" size-md="6" hidden={!cases.products.length}>
                <IonRow>
                  <IonCol className="smaller-font">
                    {t("products.title")}
                  </IonCol>
                  <IonCol className="smaller-font">{t("quantity")}</IonCol>
                </IonRow>
                {cases.products.map(step => (
                  <IonRow key={step.id}>
                    <IonCol>
                      <b>{step.name}</b>
                    </IonCol>
                    <IonCol>{step.count}</IonCol>
                  </IonRow>
                ))}
              </IonCol>
              <IonCol size-xs="12" size-md="6" hidden={!cases.extras.length}>
                <IonRow>
                  <IonCol className="smaller-font">{t("extras.title")}</IonCol>
                  <IonCol className="smaller-font">{t("quantity")}</IonCol>
                </IonRow>
                {cases.extras.map(step => (
                  <IonRow key={step.id}>
                    <IonCol>
                      <b>{step.name}</b>
                    </IonCol>
                    <IonCol>{step.count}</IonCol>
                  </IonRow>
                ))}
              </IonCol>
              <IonCol
                size-xs="12"
                size-md="6"
                hidden={!cases.productTypes.length}
              >
                <IonRow>
                  <IonCol className="smaller-font">
                    {t("products.productTypes")}
                  </IonCol>
                  <IonCol className="smaller-font">{t("quantity")}</IonCol>
                </IonRow>
                {cases.productTypes.map(step => (
                  <IonRow key={step.type}>
                    <IonCol>
                      <b>{tProductType(step.type)}</b>
                    </IonCol>
                    <IonCol>{step.count}</IonCol>
                  </IonRow>
                ))}
              </IonCol>
            </IonRow>
          )}
          <CasesStats {...urlProps} hidden={segment !== "summary"} />

          {cases.data.data.length > 0 && (
            <div className="ion-text-center ion-margin-top">
              <IonButton
                fill="outline"
                onClick={saveAsPdf}
                disabled={downloading}
              >
                <Icon
                  icon={downloading ? faSpinner : faDownload}
                  spin={downloading}
                />
                {t("saveAsPdf")}
              </IonButton>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CasesTable;
