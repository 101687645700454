import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAuthContext } from "../../context/AuthProvider";
import { CaseViewDto } from "../../models/Case";
import {
  onFirestoreSnapshot,
  setFirestoreDocMerge,
  updateFirestore
} from "../../data/firebase";
import { arrayUnion } from "firebase/firestore";
import { IonButton, IonItem, IonTextarea } from "@ionic/react";
import useDate from "../../hooks/useDate";
import useTranslation from "../../context/LanguageProvider";
import InfoBox from "../InfoBox";
import useLab from "../../context/LabProvider";
import { DoctorInLabDto } from "../../models/Doctor";
import WarningBox from "../WarningBox";

interface Props {
  $case: CaseViewDto;
  doctorInLab?: DoctorInLabDto;
}

interface ChatMessage {
  message: string;
  userId: string;
  name: string;
  own: boolean;
  time: number;
}
const CaseChat: React.FC<Props> = ({ $case, doctorInLab }) => {
  const scroll = useRef<HTMLDivElement>(null);
  const { employees, dashboardData } = useLab();

  const [newMessage, setNewMessage] = useState("");
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const { toDateTimeString } = useDate();
  const { t } = useTranslation();

  const { labId, user } = useAuthContext();
  const [fbUserId, setFbUserId] = useState(user?.userId?.toString());

  useEffect(() => {
    if (user && user.userId) setFbUserId(user.userId.toString());
  }, [user, user?.userId]);

  useEffect(() => {
    if (
      $case.id &&
      user &&
      user.userId &&
      employees &&
      doctorInLab &&
      doctorInLab.userId > 0
    ) {
      return onFirestoreSnapshot("caseChat", $case.id, data => {
        const messagesData = data
          ? Object.values(data.messages).map(t => t as ChatMessage)
          : [];

        if (messagesData.length) {
          setMessages(
            messagesData.map(message => {
              const employee = employees.find(
                e => e.id.toString() === message.userId
              );

              const name = employee
                ? employee.firstName + " " + employee.lastName
                : message.userId === doctorInLab.userId.toString()
                ? doctorInLab.name
                : "UNKNOWN";
              return {
                ...message,
                name: name,
                own: message.userId === fbUserId
              };
            })
          );
          updateFirestore("caseChat", $case.id, {
            readBy: arrayUnion(fbUserId)
          }).then(() => {
            scroll.current &&
              scroll.current.scrollIntoView({ behavior: "smooth" });
          });
        }
      });
    }
  }, [$case.id, user, employees, doctorInLab, fbUserId]);

  const sendMessage = useCallback(() => {
    if (newMessage.trim().length <= 0 || !labId) return;

    updateFirestore("caseChat", $case.id, {
      messages: arrayUnion({
        message: newMessage,
        userId: fbUserId,
        time: Date.now()
      }),
      readBy: [fbUserId],
      lastMessage: newMessage,
      lastMessageAuthor: !user?.name ? "" : user.name,
      lastMessageTime: Date.now()
    }).then(() => {
      const caseInDashboard = dashboardData?.find(d => d.caseId === $case.id);
      if (caseInDashboard) {
        caseInDashboard.lastMessage = newMessage;
        caseInDashboard.lastMessageAuthor = !user?.name ? "" : user.name;
        caseInDashboard.lastMessageTime = Date.now();

        console.log(caseInDashboard, "newCaseIndash");
        setFirestoreDocMerge("dashboard", labId, {
          [$case.id]: caseInDashboard
        });
      }
      setNewMessage("");
      scroll.current && scroll.current.scrollIntoView({ behavior: "smooth" });
    });
  }, [$case.id, dashboardData, fbUserId, labId, newMessage, user?.name]);

  return (
    <>
      <InfoBox text={t("chat.infoForLab")} />
      {doctorInLab && doctorInLab.userId > 0 ? (
        <div className="chat-wrapper">
          {messages.map((m, i, mArray) => (
            <div key={i} className="msg-wrapper">
              <div
                className={
                  m.own ? "inline-container own" : "inline-container other"
                }
              >
                <span
                  className="letter-avatar"
                  hidden={m.own}
                  style={{
                    opacity:
                      i > 0 && mArray[i - 1].userId === m.userId ? 0 : 100
                  }}
                >
                  <span className="initials">
                    {m.name
                      .split(" ")
                      .map(n => n[0])
                      .join("")}
                  </span>
                </span>

                <div
                  className={m.own ? "own-message own" : "other-message other"}
                >
                  <div
                    className="msg-info"
                    hidden={
                      m.own || (i > 0 && mArray[i - 1].userId === m.userId)
                    }
                  >
                    <div className="msg-info-name">{m.name}</div>
                  </div>
                  {m.message}
                  <div className="msg-info">
                    <div />

                    <div className="msg-info-time">
                      {m.time && toDateTimeString(new Date(m.time).toString())}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <IonItem className="ion-no-padding">
            <IonTextarea
              value={newMessage}
              placeholder={t("chat.enterYourMessage")}
              onKeyDown={e => {
                if (e.code === "Enter" && !e.shiftKey)
                  newMessage.trim().length && sendMessage();
              }}
              onIonChange={e => setNewMessage(e.detail.value!)}
            ></IonTextarea>
            <IonButton
              size="large"
              className="ion-margin-top ion-margin-start"
              disabled={!newMessage.trim().length}
              onClick={_ => sendMessage()}
            >
              {t("chat.send")}
            </IonButton>
          </IonItem>
          <div ref={scroll}></div>
        </div>
      ) : (
        <WarningBox text={t("chat.noDoctorLoginWarning")} />
      )}
    </>
  );
};

export default CaseChat;
