import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonReorder,
  IonReorderGroup,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import useTranslation from "../../context/LanguageProvider";
import ButtonTextIcon from "../ButtonTextIcon";
import {
  SchedulingTemplateUpsertFormDto,
  TemplateHasTask
} from "../../models/Task";
import Icon from "../Icon";
import {
  faPlus,
  faSave,
  faSpinner,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { ItemReorderEventDetail } from "@ionic/core/components";
import ModalWrapper from "../ModalWrapper";
import useLab from "../../context/LabProvider";

interface Props {
  onSuccess: (id: number) => void;
  onCancel: () => void;
  showSchedulingTemplateModal: boolean;
  initialData: SchedulingTemplateUpsertFormDto;
}
const SchedulingTemplateUpsertModal: React.FC<Props> = ({
  onSuccess,
  onCancel,
  initialData,
  showSchedulingTemplateModal
}) => {
  const [loading, setLoading] = useState(false);

  const { apiPost } = useApi();
  const { t } = useTranslation();
  const { handleError } = useNotificationContext();

  const { employees, tasks } = useLab();
  const [template, setTemplate] = useState<SchedulingTemplateUpsertFormDto>({
    id: 0,
    name: "",
    tasks: []
  });

  useEffect(() => {
    // reset initial data when we get employees and tasks from the backend
    if (employees && tasks) {
      setTemplate({ ...initialData });
    }
  }, [initialData, employees, tasks]);

  const setTask = (ind: number, task: TemplateHasTask) => {
    setTemplate(template => ({
      ...template,
      tasks: template.tasks.map((t, i) => (ind === i ? task : t))
    }));
  };

  const reorderTasks = (event: CustomEvent<ItemReorderEventDetail>) => {
    const tasks = event.detail.complete(template.tasks);
    setTemplate(template => ({
      ...template,
      tasks
    }));
  };

  const upsert = () => {
    setLoading(true);
    apiPost<number>("schedulingTemplate/upsert", template)
      .then(id => onSuccess(id))
      .catch(handleError)
      .finally(() => setLoading(false));
  };

  const deleteTask = (i: number) => {
    if (template.tasks.length > 1) {
      const tasks = template.tasks;
      tasks.splice(i, 1);
      setTemplate(template => ({ ...template, tasks: tasks }));
    }
  };

  const addTask = () => {
    setTemplate(template => ({
      ...template,
      tasks: [
        ...template.tasks,
        {
          taskId: 0,
          userId: null
        }
      ]
    }));
  };

  return (
    <ModalWrapper
      modalOpened={showSchedulingTemplateModal}
      dismiss={onCancel}
      modal="stUpsert"
    >
      <IonModal isOpen={showSchedulingTemplateModal} onDidDismiss={onCancel}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                <ButtonTextIcon button="cancel" />
              </IonButton>
            </IonButtons>
            <IonTitle>
              {initialData.id > 0
                ? t("schedulingTemplate.edit")
                : t("schedulingTemplate.add")}
            </IonTitle>
            <IonButtons slot="primary">
              <IonButton onClick={upsert} disabled={loading}>
                <ButtonTextIcon button="save" loading={loading} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItem lines="none" className="ion-margin-top">
            <IonLabel position="stacked">
              {t("schedulingTemplate.name")}
            </IonLabel>
            <IonInput
              autocomplete="new-password"
              placeholder={t("schedulingTemplate.namePlaceholder")}
              value={template.name}
              onIonChange={e =>
                setTemplate(template => ({
                  ...template,
                  name: e.detail.value!
                }))
              }
            />
          </IonItem>

          <IonList>
            <IonReorderGroup onIonItemReorder={reorderTasks} disabled={false}>
              {tasks &&
                template.tasks.map((task, i) => (
                  <IonRow
                    key={i + 100 * task.taskId}
                    className="ion-align-items-center"
                  >
                    <IonCol size="auto">
                      <IonItem>
                        <IonReorder />
                      </IonItem>
                    </IonCol>
                    <IonCol>
                      <IonItem lines="none">
                        <IonLabel position="stacked">
                          {t("task.title")}
                        </IonLabel>
                        <IonSelect
                          value={task.taskId}
                          placeholder={t("scheduling.taskPlaceholder")}
                          interface="action-sheet"
                          cancelText={t("cancel")}
                          onIonChange={e =>
                            setTask(i, {
                              ...task,
                              taskId: e.detail.value!
                            })
                          }
                        >
                          {tasks.map(d => (
                            <IonSelectOption key={d.id} value={d.id}>
                              {d.name}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                    </IonCol>

                    <IonCol>
                      <IonItem lines="none">
                        <IonLabel position="stacked">
                          {t("technician")}
                        </IonLabel>
                        <IonSelect
                          interface="action-sheet"
                          cancelText={t("cancel")}
                          value={task.userId || null}
                          onIonChange={e =>
                            setTask(i, {
                              ...task,
                              userId: e.detail.value!
                            })
                          }
                        >
                          <IonSelectOption key={0} value={null}>
                            {t("scheduling.anyTechnician")}
                          </IonSelectOption>
                          {employees?.map(e => (
                            <IonSelectOption key={e.id} value={e.id}>
                              {`${e.firstName} ${e.lastName}`}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                    </IonCol>

                    <IonCol size="auto">
                      <IonButton
                        hidden={template.tasks.length === 1}
                        onClick={() => deleteTask(i)}
                        size="small"
                        color="danger"
                        class="no-icon-margin ion-text-center"
                        // slot="end"
                      >
                        <Icon
                          // slot="end"
                          icon={faTimes}
                          className="white-icon"
                          title={t("delete")}
                        />
                      </IonButton>
                    </IonCol>
                  </IonRow>
                ))}
            </IonReorderGroup>
          </IonList>
          <div className="ion-text-center ion-margin-top">
            <IonButton
              disabled={template.tasks.filter(t => t.taskId === 0).length > 0}
              color="secondary"
              onClick={addTask}
            >
              <Icon icon={faPlus} />
              {t("scheduling.addTask")}
            </IonButton>
          </div>
          <IonButton
            color="success"
            expand="block"
            onClick={upsert}
            disabled={loading}
            className="ion-margin-top"
          >
            {loading ? <Icon spin icon={faSpinner} /> : <Icon icon={faSave} />}
            {t("save")}
          </IonButton>
        </IonContent>
      </IonModal>
    </ModalWrapper>
  );
};

export default SchedulingTemplateUpsertModal;
