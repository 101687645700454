import React, { useCallback, useEffect, useState } from "react";
import {
  IonRouterOutlet,
  IonLabel,
  IonMenu,
  IonContent,
  IonList,
  IonMenuToggle,
  IonItem,
  IonListHeader,
  IonSplitPane,
  IonLoading,
  IonImg,
  IonSkeletonText,
  IonBadge,
  IonThumbnail
} from "@ionic/react";
import { Route, useLocation } from "react-router";
import { useAuthContext } from "../../context/AuthProvider";

/* Icons */
import Icon from "../Icon";
import {
  faCalendarCheck,
  faTeethOpen,
  faUserTie,
  faIdBadge,
  IconDefinition,
  faIdCard,
  faSignOutAlt,
  faExchangeAlt,
  faHospital,
  faHospitalAlt,
  faUserCog,
  faMoneyBillWave,
  faTasks,
  faThLarge,
  faCheckCircle,
  faChartLine,
  faUserPlus
} from "@fortawesome/free-solid-svg-icons";

/*Components*/
import AuthRoute from "./AuthRoute";
import NonAuthRoute from "./NonAuthRoute";

/*Pages*/
import Profile from "../profile/Profile";
import Cases from "../case/Cases";
import MainTabs from "../MainTabs";
import CalendarPage from "../calendar/CalendarPage";
import NewLab from "../lab/NewLab";
import NotFound from "../NotFound";
import AcceptInvitation from "../employee/AcceptInvitation";
import Doctors from "../doctor/Doctors";
import DoctorProfile from "../doctor/DoctorProfile";
import Employees from "../employee/Employees";
import EmployeeProfile from "../employee/EmployeeProfile";
import InviteEmployee from "../employee/InviteEmployee";
import ViewInvitation from "../employee/ViewInvitation";
import ChangeLab from "../lab/ChangeLab";
import Login from "../auth/Login";
import Register from "../auth/Register";
import ForgotPassword from "../auth/ForgotPassword";
import VerifyEmail from "../auth/VerifyEmail";
import { Permission } from "../../models/Permissions";
import EditLab from "../lab/EditLab";
import Case from "../case/Case";
import InvoiceView from "../invoice/InvoiceView";
import Settings from "../Settings";
import Invoices from "../invoice/Invoices";
import useTranslation, { Languages } from "../../context/LanguageProvider";
import Tasks from "../task/Tasks";
import Task from "../task/Task";
import Dashboard from "../dashboard/Dashboard";
import InitialPath from "./InitialPath";
import ProductionLogs from "../productionLog/ProductionLogs";
import SchedulingTemplates from "../schedulingTemplate/SchedulingTemplates";
import ProductView from "../product/ProductView";
import Products from "../product/Products";
import Extras from "../product/Extras";
import ExtraView from "../product/ExtraView";
import { firebaseMessaging } from "../../data/firebase";
import { onMessage } from "firebase/messaging";
import { useNotificationContext } from "../../context/NotificationProvider";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import Notifications from "../profile/Notifications";
import usePushNotifications from "../../data/pushNotifications";
import MyTasks from "../productionLog/MyTasks";
import Stats from "../stats/Stats";
import useLab from "../../context/LabProvider";
import ChangeSubscription from "../lab/ChangeSubscription";
import { ActiveCasesSummary } from "../../models/Case";
import AppPayment from "../lab/AppPayment";
import { timeInPast } from "../../data/dateHelpers";
import useUrlSearchParams from "../../hooks/useUrlSearchParams";
import Couriers from "../courier/Couriers";
import Materials from "../material/Materials";
import Material from "../material/Material";
import NewCase from "../case/NewCase";
import EditCase from "../case/EditCase";

export interface Page {
  url: string;
  icon?: IconDefinition;
  title: string;
  component: React.FC<any>;
  permission?: Permission;
}

// Edit my Profile, Add/edit Technicians, Clinic info/settings, Products, etc.
const accountPages: Page[] = [
  {
    title: "settings",
    url: "/settings",
    icon: faUserCog,
    component: Settings
  }
];

const noLabPages: Page[] = [
  {
    title: "newLab.title",
    url: "/new-lab",
    icon: faHospital,
    component: NewLab
  },
  {
    title: "joinLab.title",
    url: "invitation",
    icon: faUserPlus,
    component: AcceptInvitation
  }
];

// Tasks, Cases, doctors, etc.
const myLabPages: Page[] = [
  {
    title: "dashboard.title",
    url: "/tabs/dashboard",
    icon: faThLarge,
    component: Dashboard
    // permission: Permission.DashboardRead
  },
  {
    title: "myTasks.title",
    url: "/tabs/myTasks",
    icon: faCheckCircle,
    component: MyTasks
  },

  {
    title: "calendar",
    url: "/tabs/calendar",
    icon: faCalendarCheck,
    component: CalendarPage
    // permission: Permission.CalendarRead
  },
  {
    title: "cases.menuTitle",
    url: "/tabs/cases",
    icon: faTeethOpen,
    component: Cases,
    permission: Permission.CasesTableRead
  },
  {
    title: "workLog.title",
    url: "/work-logs",
    icon: faTasks,
    component: ProductionLogs,
    permission: Permission.ProductionLogsTableRead
  },
  {
    title: "invoices.title",
    url: "/invoices",
    icon: faMoneyBillWave,
    component: Invoices,
    permission: Permission.InvoicesTableRead
  },
  {
    title: "doctors.title",
    url: "/tabs/doctors",
    icon: faUserTie,
    component: Doctors,
    permission: Permission.DoctorsTableRead
  },
  {
    title: "employees.title",
    url: "/employees",
    icon: faIdBadge,
    component: Employees,
    permission: Permission.EmployeeRead
  },
  {
    title: "stats.title",
    url: "/stats",
    icon: faChartLine,
    component: Stats,
    permission: Permission.StatsRead
  }
];

// other pages that are not in the menus
const otherPages: Page[] = [
  {
    title: "changeLab.title",
    url: "/change-lab",
    icon: faExchangeAlt,
    component: ChangeLab
  },
  {
    title: "newLab.title",
    url: "/new-lab",
    icon: faHospital,
    component: NewLab
  },
  {
    title: "subscription.title",
    url: "/change-subscription",
    icon: faHospital,
    component: ChangeSubscription
  },
  {
    title: "subscription.title",
    url: "/app-payment",
    icon: faHospital,
    component: AppPayment
  },
  {
    title: "profile.title",
    url: "/profile",
    icon: faIdCard,
    component: Profile
  },
  {
    title: "notifications.editNotifications",
    url: "/notifications",
    icon: faBell,
    component: Notifications
  },
  {
    title: "editLab.title",
    url: "/edit-lab",
    icon: faHospitalAlt,
    component: EditLab
  },
  {
    title: "case",
    url: "/case/:id",
    component: Case
  },
  {
    title: "case",
    url: "/edit-case/:id",
    component: EditCase
  },
  {
    title: "cases.new",
    url: "/new-case",
    component: NewCase
  },
  {
    title: "invoice",
    url: "/invoice/:id",
    component: InvoiceView
  },
  {
    title: "doctor",
    url: "/doctor/:id",
    component: DoctorProfile
  },
  {
    title: "employee",
    url: "/employee/:id",
    component: EmployeeProfile
  },
  {
    title: "inviteEmployee.title",
    url: "/invite-employee",
    component: InviteEmployee
  },
  {
    title: "viewInvitation.title",
    url: "/view-invitation/:id",
    component: ViewInvitation
  },
  {
    title: "task.titlePlural",
    url: "/tasks",
    component: Tasks
  },
  {
    title: "task.title",
    url: "/task/:id",
    component: Task
  },
  {
    title: "schedulingTemplate.titlePlural",
    url: "/scheduling-templates",
    component: SchedulingTemplates
  },
  {
    title: "products.title",
    url: "/product/:id",
    component: ProductView
  },
  {
    title: "products.title",
    url: "/products",
    component: Products
  },
  {
    title: "extras.title",
    url: "/extras",
    component: Extras
  },
  {
    title: "extras.title",
    url: "/extra/:id",
    component: ExtraView
  },
  {
    title: "courier.title",
    url: "/couriers",
    component: Couriers
  },
  {
    title: "materials.title",
    url: "/materials",
    component: Materials
  },
  {
    title: "material",
    url: "/material/:id",
    component: Material
  }
];

// merge for router outlet
export const allPages: Page[] = myLabPages.concat(accountPages, otherPages);

const doctorHiddenPages: string[] = ["/tabs/myTasks"];

const Navigation: React.FC = () => {
  const location = useLocation();
  const { authenticated, loadingAuthState, user, logout } = useAuthContext();
  const { lab, loadingLab, logoImg, dashboardData, myTasksSummary } = useLab();
  const { t } = useTranslation();
  const [tabPages, setTabPages] = useState<Page[]>([]);
  const { showToast } = useNotificationContext();
  const { notificationsGranted } = usePushNotifications();
  const [activeCasesSummary, setActiveCasesSummary] =
    useState<ActiveCasesSummary>({ count: 0, missedDeadline: false });

  const urlLanguage = useUrlSearchParams("language");
  const { language: currentLanguage, changeLanguage } = useTranslation();
  useEffect(() => {
    if (urlLanguage && urlLanguage !== currentLanguage) {
      // language from the query is different from the current language
      const l = Languages.find(l => l.value === urlLanguage);
      // see if the one from the query is one of our own
      l && changeLanguage(l.value);
    }
  }, [urlLanguage, currentLanguage, changeLanguage]);

  const hasPagePermission = useCallback(
    (page: Page) => {
      if (!user) return false;
      if (!page.permission) return true; // no permission needed

      return user.hasPermission(page.permission);
    },
    [user]
  );

  const isPageAllowedForDoctor = useCallback(
    (page: Page) => {
      if (!user) return false;
      if (!user.doctorId) return true; // simple employee

      return !doctorHiddenPages.includes(page.url);
    },
    [user]
  );

  useEffect(() => {
    if (!user) setTabPages([]);
    else
      setTabPages(
        myLabPages
          .filter(page => page.url.startsWith("/tabs"))
          .filter(hasPagePermission)
          .filter(isPageAllowedForDoctor)
      );
  }, [hasPagePermission, isPageAllowedForDoctor, user]);

  useEffect(() => {
    // Handle incoming messages. Called when:
    // - a message is received while the app has focus
    // - the user clicks on an app notification created by a service worker
    //   `messaging.onBackgroundMessage` handler.
    if (!notificationsGranted) return;

    return onMessage(firebaseMessaging, payload => {
      console.log(payload);
      payload.notification?.body &&
        showToast({
          message: payload.notification.body,
          duration: 3000,
          color: "success"
        });
    });
  }, [notificationsGranted, showToast]);

  useEffect(() => {
    setActiveCasesSummary({
      count: dashboardData?.length ?? 0,
      missedDeadline:
        dashboardData &&
        dashboardData.find(
          d => d.appointmentDate && timeInPast(new Date(d.appointmentDate))
        )
          ? true
          : false
    });
  }, [dashboardData]);

  if (loadingAuthState)
    return <IonLoading isOpen message={t("loggingInMessage")} />;
  if (user && !user.emailVerified) {
    return <VerifyEmail />;
  }

  const renderListItems = (pages: Page[]) => {
    return pages
      .filter(hasPagePermission)
      .filter(isPageAllowedForDoctor)
      .map((page, index) => (
        <IonMenuToggle key={index} autoHide={false}>
          <IonItem
            className={location.pathname === page.url ? "selected" : ""}
            routerLink={page.url}
            routerDirection="root"
            lines="none"
            detail={false}
          >
            {page.icon && (
              <Icon
                slot="start"
                className="left-menu-icon"
                size="lg"
                icon={page.icon}
              />
            )}
            <IonLabel>
              {t(page.title)}{" "}
              {page.url === "/tabs/myTasks" && myTasksSummary && (
                <IonBadge
                  className="to-do-badge"
                  color={
                    myTasksSummary.missedDeadlineCount ? "danger" : "warning"
                  }
                >
                  {myTasksSummary.scheduledCount + myTasksSummary.startedCount}
                </IonBadge>
              )}
              {page.url === "/tabs/dashboard" && activeCasesSummary.count > 0 && (
                <IonBadge
                  className="to-do-badge"
                  color={
                    activeCasesSummary.missedDeadline ? "danger" : "warning"
                  }
                >
                  {activeCasesSummary.count}
                </IonBadge>
              )}
            </IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));
  };

  return (
    <IonSplitPane contentId="main">
      {authenticated && (
        <IonMenu contentId="main" swipeGesture={true} type="overlay">
          <IonContent className="ion-no-padding">
            {/* {!lab && ( */}
            <IonImg
              className="logo-menu"
              style={{ marginTop: 15, maxWidth: 100 }}
              src={"assets/img/logo.png"}
              alt=""
            />
            {lab && (
              <IonList>
                <IonListHeader className="lab-name ion-no-padding">
                  <IonItem>
                    {logoImg && (
                      <IonThumbnail slot="start">
                        <IonImg
                          src={logoImg}
                          className="lab-logo"
                          alt="assets/img/logo.png"
                        />
                      </IonThumbnail>
                    )}
                    <IonLabel>{lab.name}</IonLabel>
                  </IonItem>
                </IonListHeader>
                {renderListItems(myLabPages)}
              </IonList>
            )}
            {!lab && loadingLab && <IonSkeletonText animated />}

            <IonList lines="none">
              <IonListHeader>{t("navigation.menuAccountHeader")}</IonListHeader>
              {!lab && !loadingLab && renderListItems(noLabPages)}
              {renderListItems(accountPages)}
              <IonMenuToggle autoHide={false}>
                <IonItem onClick={logout} button>
                  <Icon
                    slot="start"
                    size="lg"
                    className="left-menu-icon"
                    icon={faSignOutAlt}
                  />
                  <IonLabel>{t("logout")}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            </IonList>
          </IonContent>
        </IonMenu>
      )}
      <IonRouterOutlet id="main">
        <AuthRoute
          path="/tabs"
          render={() => (
            <MainTabs
              myTasksSummary={myTasksSummary}
              activeCasesSummary={activeCasesSummary}
              pages={tabPages}
            />
          )}
        />
        {allPages
          .filter(page => !page.url.includes("/tabs"))
          .map((page, index) => (
            <AuthRoute
              key={index}
              path={page.url}
              component={page.component}
              exact
            />
          ))}

        <NonAuthRoute path="/login" component={Login} />
        <NonAuthRoute path="/register" component={Register} />
        <NonAuthRoute path="/forgot-password" component={ForgotPassword} />

        <Route path="/invitation" component={AcceptInvitation} />

        <Route path="/" component={InitialPath} exact />
        <Route component={NotFound} />
      </IonRouterOutlet>
    </IonSplitPane>
  );
};

export default Navigation;
