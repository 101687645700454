import React, { useState, useEffect, useCallback } from "react";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";

import {
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonButton,
  IonList,
  IonSkeletonText,
  IonRouterLink,
  IonPopover
} from "@ionic/react";
import { InvoiceViewDto, InvoiceStatus } from "../../models/Invoice";
import { RouteComponentProps, useHistory } from "react-router";
import PaymentUpsertModal from "./PaymentUpsertModal";
import PaymentDto from "../../models/Payment";
import { useAuthContext } from "../../context/AuthProvider";
import Icon from "../../components/Icon";
import {
  faDownload,
  faEdit,
  faEllipsisV,
  faExternalLinkAlt,
  faMoneyBillWave,
  faPlus,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import ValueLabel from "../ValueLabel";
import useTranslation from "../../context/LanguageProvider";
import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";
import Can from "../Can";
import InvoiceUpsertModal from "./InvoiceUpsertModal";
import {
  calculateInvoiceItemsTotals,
  calculateTotal,
  calculateTaxTotal,
  toDecimal
} from "./invoiceMath";
import useDate from "../../hooks/useDate";
import PaymentType from "../../models/PaymentType";
import ButtonTextIcon from "../ButtonTextIcon";
import useCurrency from "../../hooks/useCurrency";
import useLab, { useLabIntegrations } from "../../context/LabProvider";
import {
  BillingIntegrationViewDto,
  BillingIntegrationType
} from "../../models/Lab";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import InvoiceIntegrationUpsertModal from "./InvoiceIntegrationUpsertModal";
import SmartBillButton from "../lab/SmartBillButton";

interface Props
  extends RouteComponentProps<{
    id: string;
  }> {
  id: 0;
}

const InvoiceView: React.FC<Props> = ({ match }) => {
  const { apiGet, apiDelete, apiBlobDownload } = useApi();
  const history = useHistory();
  const { user } = useAuthContext();
  const { formatWithCurrencySign } = useCurrency();
  const { toDateString, toDateTimeString } = useDate();
  const { employees } = useLab();

  const { handleError, showAlert, showSuccessToast } = useNotificationContext();

  const [downloading, setDownloading] = useState(false);
  const [invoice, setInvoice] = useState<InvoiceViewDto>();
  const [paymentData, setPaymentData] = useState<PaymentDto>();
  const [selectedIntegration, setSelectedIntegration] =
    useState<BillingIntegrationViewDto>();
  const { integrations } = useLabIntegrations();
  const [showIntegration, setShowIntegration] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [sentBy, setSentBy] = useState("");
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined
  });
  const { t, tInvoiceStatus, tPaymentType } = useTranslation();

  const dismissPopover = useCallback(
    () => setShowPopover({ showPopover: false, event: undefined }),
    []
  );

  const countTotals = useCallback((invoice: InvoiceViewDto) => {
    const items = calculateInvoiceItemsTotals(invoice.items);
    const total = calculateTotal(items);
    const taxTotal = calculateTaxTotal(total, invoice.taxRate);

    const nInvoice = {
      ...invoice,
      items,
      subTotal: toDecimal(total - taxTotal),
      taxTotal,
      total
    };
    setInvoice(nInvoice);
  }, []);

  const getInvoice = useCallback(
    () =>
      apiGet<InvoiceViewDto>(`invoice/get?id=${match.params.id}`)
        .then(countTotals)
        .catch(handleError),
    [apiGet, countTotals, handleError, match.params.id]
  );

  const saveAsPdf = useCallback(() => {
    setDownloading(true);
    apiBlobDownload("POST", `invoice/SaveAsPdf?id=${match.params.id}`, {})
      .catch(handleError)
      .finally(() => setDownloading(false));
    dismissPopover();
  }, [apiBlobDownload, dismissPopover, handleError, match.params.id]);

  const addPayment = useCallback(() => {
    if (invoice && invoice.status !== InvoiceStatus.Paid) {
      setPaymentData({
        id: 0,
        invoiceId: invoice.id,
        doctorId: invoice.doctorId,
        amount: toDecimal(invoice.total - invoice.totalPaid),
        paymentType: PaymentType.Cash,
        notes: ""
      });
    }
  }, [invoice]);

  const startIntegration = useCallback(
    (labIntegration: BillingIntegrationViewDto) => {
      setSelectedIntegration(labIntegration);
      setShowIntegration(true);
    },
    []
  );

  useEffect(() => {
    getInvoice();
  }, [getInvoice]);

  useEffect(() => {
    if (paymentData) setShowPaymentModal(true);
  }, [paymentData]);

  useEffect(() => {
    if (employees.length && invoice?.sendingData?.sentBy) {
      const employee = employees.find(
        e => e.id === invoice.sendingData?.sentBy
      );
      setSentBy(employee ? `${employee.firstName} ${employee.lastName}` : "");
    } else {
      setSentBy("");
    }
  }, [employees, invoice?.sendingData?.sentBy]);

  const onDeleteClicked = useCallback(() => {
    invoice?.id &&
      showAlert({
        title: t("warning"),
        message: t("confirmDelete"),
        buttons: [
          {
            text: t("cancel"),
            role: "cancel"
          },
          {
            text: t("delete"),
            handler: () => {
              apiDelete(`invoice/delete?id=${invoice.id}`)
                .then(() => {
                  history.replace("/invoices");
                  showSuccessToast(t("deleteSuccess"));
                })
                .catch(handleError);
            }
          }
        ]
      });
    dismissPopover();
  }, [
    invoice?.id,
    showAlert,
    t,
    dismissPopover,
    apiDelete,
    handleError,
    history,
    showSuccessToast
  ]);

  return (
    <IonPage>
      {invoice ? (
        <>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonBackButton defaultHref="/invoices" />
              </IonButtons>
              <IonTitle>
                {t("invoice")}{" "}
                <span className="content-font">{invoice.number}</span>
              </IonTitle>
              <IonButtons slot="primary">
                <IonButton
                  className="ion-hide-sm-down"
                  onClick={saveAsPdf}
                  disabled={downloading}
                >
                  <ButtonTextIcon button="download" loading={downloading} />
                </IonButton>
                {!invoice.sendingData && (
                  <Can permission={Permission.InvoiceUpdate}>
                    <IonButton
                      className="ion-hide-sm-down"
                      onClick={() => setShowInvoiceModal(true)}
                    >
                      <ButtonTextIcon button="edit" />
                    </IonButton>
                  </Can>
                )}
                <Can permission={Permission.InvoiceDelete}>
                  <IonButton
                    className="ion-margin-start ion-hide-sm-down"
                    onClick={onDeleteClicked}
                  >
                    <ButtonTextIcon
                      xlHide
                      button="delete"
                      iconClass="dangerColor"
                    />
                  </IonButton>
                </Can>
                <IonButton
                  className="ion-hide-sm-up"
                  onClick={(e: any) => {
                    e.persist();
                    setShowPopover({ showPopover: true, event: e });
                  }}
                >
                  <Icon icon={faEllipsisV} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid>
              <IonRow className="box">
                <IonCol>
                  <h5>{t("recipient")}</h5>
                  <ValueLabel title={t("name")}>
                    <IonRouterLink
                      routerLink={"/doctor/" + invoice.doctor.id}
                      color="default"
                    >
                      {invoice.doctor.name}{" "}
                      <Icon
                        className="mediumColor"
                        size="xs"
                        icon={faExternalLinkAlt}
                      />
                    </IonRouterLink>
                  </ValueLabel>
                  <ValueLabel title={t("company")}>
                    {invoice.doctor.clinicName}
                  </ValueLabel>
                  {invoice.doctor.address && (
                    <ValueLabel title={t("address")}>
                      {invoice.doctor.address}
                    </ValueLabel>
                  )}
                  {invoice.doctor.phone && (
                    <ValueLabel title={t("phone")}>
                      {invoice.doctor.phone}
                    </ValueLabel>
                  )}
                  {invoice.doctor.website && (
                    <ValueLabel title={t("website")}>
                      {invoice.doctor.website}
                    </ValueLabel>
                  )}
                </IonCol>
                <IonCol className="invoiceHeader">
                  <h5>{t("caseInfo")}</h5>

                  <ValueLabel title={t("name")}>
                    <IonRouterLink
                      routerLink={"/case/" + invoice.case.id}
                      color="default"
                    >
                      {invoice.case.name}{" "}
                      <Icon
                        className="mediumColor"
                        size="xs"
                        icon={faExternalLinkAlt}
                      />
                    </IonRouterLink>
                  </ValueLabel>
                  <ValueLabel title={t("dates.received")}>
                    {toDateString(invoice.case.received)}
                  </ValueLabel>
                </IonCol>
                <IonCol className="invoiceHeader">
                  <h5>{t("invoices.info")}</h5>

                  <ValueLabel title={t("invoices.number")}>
                    {invoice.number}{" "}
                  </ValueLabel>
                  <ValueLabel title={t("status")}>
                    <span className={"invoiceIcon" + invoice.status}>
                      {tInvoiceStatus(invoice.status)}
                    </span>
                  </ValueLabel>
                  <ValueLabel title={t("created")}>
                    {toDateString(invoice.created)}
                  </ValueLabel>
                  <ValueLabel title={t("createdBy")}>
                    {invoice.createdByUser.firstName}{" "}
                    {invoice.createdByUser.lastName}
                  </ValueLabel>
                </IonCol>
              </IonRow>

              <IonRow class="box ion-margin-top">
                <IonCol size="12" className="ion-no-padding">
                  <h5>{t("invoices.items")}</h5>
                </IonCol>
                <IonCol className="ion-no-padding">
                  <IonGrid>
                    <IonRow className="invoiceItemsHeader">
                      <IonCol>
                        <IonLabel>{t("name")}</IonLabel>
                      </IonCol>
                      <IonCol>
                        <IonLabel>{t("quantity")}</IonLabel>
                      </IonCol>
                      <IonCol>
                        <IonLabel>{t("price")}</IonLabel>
                      </IonCol>
                      <IonCol>
                        <IonLabel>{t("discount")}</IonLabel>
                      </IonCol>
                      <IonCol class="col-align-hor">
                        <IonLabel>{t("total")}</IonLabel>
                      </IonCol>
                    </IonRow>

                    {invoice.items.map((item, i) => (
                      <IonRow key={i} className="invoiceItem">
                        <IonCol>{item.name}</IonCol>
                        <IonCol>{item.quantity}</IonCol>
                        <IonCol>{formatWithCurrencySign(item.price)}</IonCol>
                        <IonCol>{item.discount}%</IonCol>
                        <IonCol class="col-align-hor">
                          {formatWithCurrencySign(item.total)}
                        </IonCol>
                      </IonRow>
                    ))}
                  </IonGrid>
                </IonCol>
              </IonRow>
              <IonRow class="box">
                <IonCol size-xs="12" size-md="10" className="ion-no-padding">
                  <span hidden={!invoice.notes}>
                    <h5 className="ion-no-padding">{t("notes")}:</h5>
                    <p className="label-value">{invoice.notes}</p>
                  </span>
                </IonCol>
                <IonCol size-xs="12" size-md="2" pushMd="right">
                  <ValueLabel title={t("taxRate")}>
                    {invoice.taxRate}
                  </ValueLabel>
                  <ValueLabel title={t("subTotal")}>
                    {formatWithCurrencySign(invoice.subTotal)}
                  </ValueLabel>
                  <ValueLabel title={t("taxTotal")}>
                    {formatWithCurrencySign(invoice.taxTotal)}
                  </ValueLabel>
                  <ValueLabel title={t("total")}>
                    <b>{formatWithCurrencySign(invoice.total)}</b>
                  </ValueLabel>
                  <ValueLabel title={t("totalPaid")}>
                    <b>{formatWithCurrencySign(invoice.totalPaid)}</b>
                  </ValueLabel>
                  <ValueLabel title={t("balanceDue")}>
                    <b
                      className={
                        invoice.total - invoice.totalPaid > 0
                          ? "warningColor"
                          : "successColor"
                      }
                    >
                      {formatWithCurrencySign(
                        invoice.total - invoice.totalPaid
                      )}
                    </b>
                  </ValueLabel>
                </IonCol>
              </IonRow>
              {invoice.sendingData && (
                <IonRow class="box">
                  <IonCol
                    className="ion-no-padding"
                    size-xs="12"
                    size-md="2"
                    pushMd="right"
                  >
                    <ValueLabel title={t("invoices.sentOn")}>
                      {toDateString(invoice.sendingData.created)}
                    </ValueLabel>
                    <ValueLabel title={t("invoices.sentBy")}>
                      {sentBy}
                    </ValueLabel>
                    <ValueLabel title={t("integrations.provider")}>
                      {invoice.sendingData.provider}
                    </ValueLabel>
                  </IonCol>
                </IonRow>
              )}
              <Can permission={Permission.InvoiceUpdate}>
                {invoice.status !== InvoiceStatus.Paid &&
                  !invoice.sendingData &&
                  integrations.length > 0 && (
                    <IonRow class="box">
                      <IonCol className="ion-no-padding">
                        <div className="ion-text-end ion-margin-top">
                          {integrations.map(i =>
                            i.provider === BillingIntegrationType.SmartBill ? (
                              <SmartBillButton
                                key={i.id}
                                onClick={() => startIntegration(i)}
                              />
                            ) : (
                              <IonButton
                                color="secondary"
                                onClick={() => startIntegration(i)}
                                key={i.id}
                              >
                                <Icon icon={faPaperPlane} />
                                {t("send")}
                              </IonButton>
                            )
                          )}
                        </div>
                      </IonCol>
                    </IonRow>
                  )}
              </Can>
              <Can permission={Permission.PaymentsRead}>
                <IonRow class="box">
                  <IonCol className="ion-no-padding">
                    <h3
                      className="ion-margin-start"
                      hidden={invoice.payments.length === 0}
                    >
                      {t("payments.title")}
                    </h3>
                    <IonList class="ion-no-padding lines">
                      {invoice.payments.map(d => (
                        <IonItem
                          key={d.id}
                          button
                          detail
                          onClick={() => {
                            if (
                              user &&
                              user.hasPermission(Permission.PaymentsUpdate)
                            ) {
                              setPaymentData(d);
                              setShowPaymentModal(true);
                            }
                          }}
                        >
                          <Icon slot="start" icon={faMoneyBillWave} />
                          <IonLabel>
                            <span className="content-font">
                              {formatWithCurrencySign(d.amount)}
                            </span>
                            <p>
                              <i>{tPaymentType(d.paymentType)}</i>
                            </p>
                            <p hidden={!d.notes.length}>
                              <i>
                                {t("notes")}: {d.notes}
                              </i>
                            </p>
                            <p className="smaller-font margin-top-6">
                              {toDateTimeString(d.created)}
                            </p>
                          </IonLabel>
                        </IonItem>
                      ))}
                    </IonList>

                    <Can permission={Permission.PaymentsCreate}>
                      <div className="ion-text-center ion-margin-top">
                        <IonButton
                          color="secondary"
                          hidden={invoice.status === InvoiceStatus.Paid}
                          // class="ion-margin-start"
                          onClick={() => {
                            addPayment();
                            setShowPaymentModal(true);
                          }}
                        >
                          <Icon icon={faPlus} />
                          {t("payments.add")}
                        </IonButton>
                      </div>
                    </Can>
                  </IonCol>
                </IonRow>
              </Can>
            </IonGrid>
          </IonContent>
          <PaymentUpsertModal
            initialData={paymentData}
            showPaymentModal={showPaymentModal}
            onCancel={() => setShowPaymentModal(false)}
            onSuccess={() => {
              getInvoice();
              setShowPaymentModal(false);
            }}
          />
          <InvoiceUpsertModal
            id={invoice.id}
            showInvoiceModal={showInvoiceModal}
            onSuccess={() => {
              getInvoice();
              setShowInvoiceModal(false);
            }}
            onCancel={() => setShowInvoiceModal(false)}
          />
          {selectedIntegration && (
            <InvoiceIntegrationUpsertModal
              invoice={invoice}
              integration={selectedIntegration}
              show={showIntegration}
              onSuccess={() => {
                getInvoice();
                setShowIntegration(false);
              }}
              onCancel={() => setShowIntegration(false)}
            />
          )}
          <IonPopover
            event={popoverState.event}
            isOpen={popoverState.showPopover}
            onDidDismiss={dismissPopover}
          >
            <IonList className="ion-padding" lines="inset">
              <IonItem onClick={saveAsPdf} disabled={downloading}>
                <Icon icon={faDownload} />
                <IonLabel>{t("saveAsPdf")}</IonLabel>
              </IonItem>
              {!invoice.sendingData && (
                <Can permission={Permission.InvoiceUpdate}>
                  <IonItem
                    onClick={() => {
                      setShowInvoiceModal(true);
                      dismissPopover();
                    }}
                  >
                    <Icon icon={faEdit} />
                    <IonLabel>{t("edit")}</IonLabel>
                  </IonItem>
                </Can>
              )}
              <Can permission={Permission.InvoiceDelete}>
                <IonItem onClick={onDeleteClicked}>
                  <Icon icon={faTrash} className="dangerColor" />
                  <IonLabel>{t("delete")}</IonLabel>
                </IonItem>
              </Can>
            </IonList>
          </IonPopover>
        </>
      ) : (
        <IonSkeletonText animated />
      )}
    </IonPage>
  );
};

export default withPermission(InvoiceView, Permission.InvoiceRead);
