const config = {
  apiKey: "AIzaSyC2tryHwbBvAnHJBerk34_VRxfE5f0cgig",
  authDomain: "auth.dentallabguru.com",
  databaseURL: "https://dtlab-81763.firebaseio.com",
  projectId: "dtlab-81763",
  storageBucket: "dtlab-81763.appspot.com",
  messagingSenderId: "115359777579",
  appId: "1:115359777579:web:eb2810e4b5351551943b6e",
  measurementId: "G-Y0VD56SHF5"
};

export const VAPID_KEY =
  "BN9IBsuM3yYTvlQTpSA6X4ysvdmVIB931MmUn3JcA_608dpjc_drUyPu-4E0WnTnbHvbYqEWQUxN0qe2Nso_MWg";

export default config;
