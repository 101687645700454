import React from "react";
import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonLabel
} from "@ionic/react";
import { Route, Redirect } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Page } from "./navigation/Navigation";
import useTranslation from "../context/LanguageProvider";
import { ActiveCasesSummary, MyTasksSummaryDto } from "../models/Case";

interface Props {
  pages: Page[];
  myTasksSummary?: MyTasksSummaryDto;
  activeCasesSummary?: ActiveCasesSummary;
}

const MainTabs: React.FC<Props> = ({
  pages,
  myTasksSummary,
  activeCasesSummary
}) => {
  const { t } = useTranslation();
  return (
    <IonTabs>
      <IonRouterOutlet>
        {pages.map(p => (
          <Route key={p.url} path={p.url} component={p.component} exact />
        ))}
        <Redirect exact path="/tabs" to="/" />
      </IonRouterOutlet>
      <IonTabBar
        slot="bottom"
        class="ion-hide-lg-up main-tabs"
        hidden={pages.length < 2}
      >
        {pages.map(p => (
          <IonTabButton
            key={p.url}
            tab={p.url.replace("/tabs/", "")}
            href={p.url}
          >
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon icon={p.icon!} size="lg" />
              {p.url === "/tabs/dashboard" && activeCasesSummary && (
                <span
                  className={
                    activeCasesSummary.missedDeadline
                      ? "fa-layers-counter danger"
                      : "fa-layers-counter warning"
                  }
                >
                  {activeCasesSummary.count}
                </span>
              )}
              {p.url === "/tabs/myTasks" && myTasksSummary && (
                <span
                  className={
                    myTasksSummary.missedDeadlineCount
                      ? "fa-layers-counter danger"
                      : "fa-layers-counter warning"
                  }
                >
                  {myTasksSummary.scheduledCount + myTasksSummary.startedCount}
                </span>
              )}
            </span>
            <IonLabel>{t(p.title)}</IonLabel>
          </IonTabButton>
        ))}
      </IonTabBar>
    </IonTabs>
  );
};

export default MainTabs;
