import React, { useCallback } from "react";
import {
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonToggle
} from "@ionic/react";
import {
  CaseFormDto,
  CaseFlag,
  CaseStatus,
  CaseProductDto,
  CaseHasConditionDto
} from "../../models/Case";
import useTranslation from "../../context/LanguageProvider";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { useAuthContext } from "../../context/AuthProvider";
import Arches from "../arches/Arches";
import SelectDoctor from "../doctor/SelectDoctor";
import ItemLabel from "../ItemLabel";
import Icon from "../Icon";
import PopupDatePicker from "../PopupDatePicker";
import {
  getEnumValues,
  getEnumValuesUnfiltered
} from "../../data/numberHelpers";
import { distinct } from "../../data/stringHelpers";
import useLab from "../../context/LabProvider";

interface Props {
  $case: CaseFormDto;
  onCaseChange: (c: Partial<CaseFormDto>) => void;
}

const CaseForm: React.FC<Props> = ({ onCaseChange, $case }) => {
  const { user } = useAuthContext();
  const { t, tCaseStatus, tProductType } = useTranslation();
  const { products: labProducts } = useLab();

  const updateName = useCallback(
    (data: CaseProductDto[], autoName?: boolean) => {
      if (!data.length) return;
      if (!autoName) return;
      if (!labProducts) return;

      return distinct(
        data.map(
          p =>
            `${tProductType(p.productTypeId)} ${
              labProducts?.find(pr => pr.id === p.productId)?.name
            }`
        )
      ).join(", ");
    },
    [labProducts, tProductType]
  );

  const onProductsUpdated = useCallback(
    (products: CaseProductDto[]) =>
      onCaseChange({
        products,
        name: updateName(products, $case.autoName) ?? $case.name
      }),
    [$case.autoName, $case.name, onCaseChange, updateName]
  );
  const onConditionsUpdated = useCallback(
    (teethCondition: CaseHasConditionDto[]) =>
      onCaseChange({
        teethCondition
      }),
    [onCaseChange]
  );

  const handleDoctorChange = (id: number | undefined) =>
    onCaseChange({ doctorId: id ?? 0 });

  const onAutoNameChanged = useCallback(
    (value: boolean) => {
      onCaseChange({
        name: updateName($case.products, value) ?? $case.name,
        autoName: value
      });
    },
    [$case.name, $case.products, onCaseChange, updateName]
  );

  return (
    <>
      {!user?.doctorId && (
        <SelectDoctor
          creatable
          initialId={$case.doctorId}
          onChange={handleDoctorChange}
        />
      )}

      <IonItem lines="none">
        <IonLabel position="stacked">{t("patient")}</IonLabel>
        <IonInput
          autocomplete="new-password"
          placeholder={t("selectPatient")}
          value={$case.patient}
          onIonChange={e => onCaseChange({ patient: e.detail.value! })}
        />
      </IonItem>

      {/* <h3 className="form-header">{t("products")}</h3> */}

      <Arches
        $case={$case}
        onProductsUpdated={onProductsUpdated}
        onConditionsUpdated={onConditionsUpdated}
      />

      <IonRow>
        <IonCol size-xs="12" size-md="4" hidden={user?.doctorId !== undefined}>
          <IonItem lines="none">
            <IonLabel position="stacked">{t("cases.pan")}</IonLabel>
            <IonInput
              autocomplete="new-password"
              placeholder={t("cases.pan")}
              debounce={200}
              value={$case.pan}
              onIonChange={e => {
                onCaseChange({ pan: e.detail.value! });
              }}
            />
          </IonItem>
        </IonCol>
        <IonCol>
          <IonItem lines="none">
            <IonLabel position="stacked">{t("cases.name2")} *</IonLabel>
            <IonInput
              autocomplete="new-password"
              value={$case.name}
              placeholder={t("cases.namePlaceholder")}
              onIonChange={e => {
                if ($case.name !== e.detail.value)
                  onCaseChange({
                    name: e.detail.value!
                  });
              }}
              onKeyUp={() =>
                $case.autoName &&
                onCaseChange({
                  autoName: false
                })
              }
            />
          </IonItem>
          <IonItem lines="none">
            <IonLabel
              style={{
                fontSize: "smaller"
              }}
            >
              {t("cases.autoGenerateName")}
            </IonLabel>
            <IonToggle
              color="success"
              checked={$case.autoName}
              onIonChange={e => onAutoNameChanged(e.detail.checked)}
            />
          </IonItem>
        </IonCol>
      </IonRow>

      <IonItem className="wion-margin-top">
        <IonLabel position="stacked">
          <p>{t("notes")}</p>
        </IonLabel>
        <IonTextarea
          placeholder={t("enterMoreInfo")}
          value={$case.notes}
          rows={6}
          onIonChange={e => onCaseChange({ notes: e.detail.value! })}
        />
      </IonItem>
      <h3 className="form-header">{t("cases.scheduling")}</h3>

      <IonGrid>
        <IonRow>
          <IonCol hidden={user?.doctorId !== undefined}>
            <ItemLabel label={t("dates.received")}>
              <PopupDatePicker
                dateAndTime
                minuteStep={5}
                value={$case.received}
                onChange={c =>
                  onCaseChange({
                    received: c ?? ""
                  })
                }
              />
            </ItemLabel>
          </IonCol>
          <IonCol>
            <ItemLabel label={t("dates.shipDate")}>
              <PopupDatePicker
                dateAndTime
                value={$case.shipDate}
                onChange={v => {
                  onCaseChange({
                    shipDate: v
                  });
                }}
              />
            </ItemLabel>
          </IonCol>
          <IonCol>
            <ItemLabel label={t("dates.finishDate")}>
              <PopupDatePicker
                dateAndTime
                value={$case.appointmentDate}
                onChange={v => {
                  onCaseChange({
                    appointmentDate: v
                  });
                }}
              />
            </ItemLabel>
          </IonCol>
        </IonRow>
      </IonGrid>

      <div hidden={user!.doctorId !== undefined}>
        <h3 className="form-header">{t("other")}</h3>

        <IonGrid>
          <IonRow>
            <IonCol size-xs="7" size-md="6" className="ion-padding-top">
              <IonLabel>
                <p>{t("cases.flag")}</p>
              </IonLabel>
              {getEnumValuesUnfiltered(CaseFlag).map(v => (
                <Icon
                  key={v}
                  icon={faTag}
                  onClick={() =>
                    onCaseChange({
                      flag: $case.flag === v ? CaseFlag.None : v
                    })
                  }
                  className={`case-flag-${v} flag-select-icon ${
                    $case.flag === v ? "selected" : "not-selected"
                  }`}
                />
              ))}
            </IonCol>
            <IonCol size-xs="5" size-md="6">
              <IonItem>
                <IonLabel position="stacked">{t("status")}</IonLabel>
                <IonSelect
                  value={$case.status}
                  onIonChange={e => onCaseChange({ status: e.detail.value! })}
                >
                  {getEnumValues(CaseStatus)
                    .filter(s => s !== CaseStatus.OutOfLab)
                    .filter(s =>
                      user?.doctorId
                        ? s !== CaseStatus.ReadyForShippingToDr
                        : s !== CaseStatus.ReadyForShippingToLab
                    )
                    .map(status => (
                      <IonSelectOption key={status} value={status}>
                        {tCaseStatus(status, user?.doctorId !== undefined)}
                      </IonSelectOption>
                    ))}
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
};

export default CaseForm;
