import React from "react";
import { CaseFlag } from "../../models/Case";
import CaseFlagIcon from "../case/CaseFlagIcon";
import { useAuthContext } from "../../context/AuthProvider";
interface Props {
  doctorName: string;
  patient?: string;
  pan?: string;
  flag: CaseFlag;
}
const CaseName: React.FC<Props> = ({ doctorName, patient, pan, flag }) => {
  const { user } = useAuthContext();

  return (
    <>
      {user?.doctorId ? "" : " " + doctorName}
      {patient ? <i style={{ fontWeight: "normal" }}>, {patient}</i> : <> </>}
      <CaseFlagIcon flag={flag} />
      {pan && <span className="case-pan-number">{pan} </span>}{" "}
    </>
  );
};

export default CaseName;
