import React, { useCallback, useState } from "react";
import Form from "../form/Form";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonSelectOption,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import useTranslation from "../../context/LanguageProvider";
import { getEnumValues } from "../../data/numberHelpers";
import ButtonTextIcon from "../ButtonTextIcon";
import ModalWrapper from "../ModalWrapper";
import FormInput from "../form/FormIonInput";
import FormAntdDate from "../form/FormAntdDate";
import FormIonSelect from "../form/FormIonSelect";
import { InvoiceViewDto, InvoiceSendFormDto } from "../../models/Invoice";
import {
  BillingIntegrationViewDto,
  BillingIntegrationType
} from "../../models/Lab";
import FormIonCheckbox from "../form/FormIonCheckbox";
import Icon from "../Icon";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import useDate from "../../hooks/useDate";
import FormIonTextarea from "../form/FormIonTextarea";

interface Props {
  onSuccess: (id: number) => void;
  onCancel: () => void;
  show: boolean;
  invoice: InvoiceViewDto;
  integration: BillingIntegrationViewDto;
}
const InvoiceIntegrationUpsertModal: React.FC<Props> = ({
  onSuccess,
  onCancel,
  invoice,
  integration,
  show
}) => {
  const [submit, setSubmit] = useState<() => void>(() => {});
  const [isSubmitting, setSubmitting] = useState(false);
  const { apiPost } = useApi();
  const { handleError } = useNotificationContext();
  const { t, tRequired, tPlaceholder } = useTranslation();
  const { toDateString } = useDate();

  const sendInvoice = useCallback(
    (data: InvoiceSendFormDto) =>
      apiPost<number>("invoice/send", data).then(onSuccess).catch(handleError),
    [apiPost, handleError]
  );

  return (
    <ModalWrapper
      modalOpened={show}
      dismiss={onCancel}
      modal="invoiceIntegrationUpsert"
    >
      <IonModal isOpen={show} onDidDismiss={onCancel}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                <ButtonTextIcon button="cancel" />
              </IonButton>
            </IonButtons>
            <IonTitle>{t("send")}</IonTitle>
            <IonButtons slot="primary">
              <IonButton onClick={submit} disabled={isSubmitting}>
                <ButtonTextIcon button="send" loading={isSubmitting} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <Form
            initialData={{
              invoiceId: invoice.id,
              clientId: invoice.doctor.smartBillId,
              clientName: invoice.doctor.clinicName,
              issueDate: new Date().toISOString(),
              dueDate: "",
              notes: [
                invoice.case.patient,
                invoice.case.name,
                invoice.case.pan,
                `${t("dates.received")}: ${toDateString(
                  invoice.case.received
                )}`,
                invoice.notes
              ].join("\n"),
              isDraft: true,
              isProforma: true,
              provider: integration.provider
            }}
            onSubmit={sendInvoice}
            submitButtonText={t("send")}
            exposeSubmit={setSubmit}
            setSubmitting={setSubmitting}
            submitButtonIcon={<Icon slot="start" icon={faPaperPlane} />}
          >
            <FormInput name="invoiceId" label="" hidden />
            <FormInput name="clientId" label={t("integrations.clientId")} />
            <FormInput name="clientName" label={t("integrations.clientName")} />
            <FormAntdDate
              name="issueDate"
              label={t("invoices.issueDate")}
              rules={{
                required: tRequired("invoices.issueDate")
              }}
              placeholder={tPlaceholder("invoices.issueDate")}
            />
            <FormAntdDate
              name="dueDate"
              label={t("invoices.dueDate")}
              rules={{
                required: tRequired("invoices.dueDate")
              }}
              placeholder={tPlaceholder("invoices.dueDate")}
            />

            <FormIonSelect
              hidden
              name="provider"
              label={t("integrations.provider")}
              rules={{
                required: tRequired("integrations.provider"),
                valueAsNumber: true
              }}
              okText={t("ok")}
              cancelText={t("dismiss")}
              interface="action-sheet"
            >
              {getEnumValues(BillingIntegrationType).map(type => (
                <IonSelectOption key={type} value={type}>
                  {type}
                </IonSelectOption>
              ))}
            </FormIonSelect>

            <FormIonCheckbox name="isDraft" label={t("invoices.isDraft")} />
            <FormIonCheckbox
              name="isProforma"
              label={t("invoices.isProforma")}
            />

            <FormIonTextarea
              name="notes"
              label={t("notes")}
              placeholder={tPlaceholder("notes")}
            />
          </Form>
        </IonContent>
      </IonModal>
    </ModalWrapper>
  );
};

export default InvoiceIntegrationUpsertModal;
