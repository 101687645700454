import React, { useEffect, useState } from "react";
import {
  IonItem,
  IonLabel,
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  useIonViewWillEnter,
  IonButton,
  IonCol,
  IonGrid,
  IonRow,
  IonBackButton,
  IonButtons,
  IonText,
  IonRadio,
  IonRadioGroup,
  IonSkeletonText
} from "@ionic/react";
import LabDto from "../../models/Lab";
import { useHistory } from "react-router";
import useApi from "../../data/Api";
import { useAuthContext } from "../../context/AuthProvider";
import { useNotificationContext } from "../../context/NotificationProvider";
import Icon from "../Icon";
import { faHospital } from "@fortawesome/free-solid-svg-icons";
import useTranslation from "../../context/LanguageProvider";

const ChangeLab: React.FC = () => {
  const [labs, setLabs] = useState<LabDto[]>();
  const history = useHistory();
  const { apiPost, apiGet } = useApi();
  const { resetUser, labId } = useAuthContext();
  const [selectedLab, setSelectedLab] = useState<number | undefined>(labId);
  const { handleError, showToast, showSuccessToast } = useNotificationContext();
  const { t, tInterpolated } = useTranslation();

  useIonViewWillEnter(() => {
    apiGet<LabDto[]>("lab/getall")
      .then(labs => {
        if (labs.length === 0) {
          // 2) If there is no labs associated with user send him to Create a Lab Page OR to enter invitation code and then go to 3) ???
          showToast({
            message: t("changeLab.notMember")
          });
          history.replace("/new-lab");
        } else if (labs.length === 1) {
          // only one lab = do nothing
          showToast({
            message: t("changeLab.oneLab")
          });
          history.replace("");
        } else {
          // if more then one then show modal where he can choose which lab he want to use after that do 3)
          setLabs(labs);
        }
      })
      .catch(handleError);
  }, []);

  useEffect(() => {
    if (labs && labs.length <= 1) history.replace("");
  }, [labs, history]);

  const onLabSelected = async () => {
    if (!selectedLab) return;
    try {
      // 3) Send lab_id to our server and check his permission (for selected lab), set custom claims (lab_id, user_id (id from our database), permissions) and setUser in auth provider
      await apiPost<string>(`lab/SetLab?labId=${selectedLab}`, {});
      await resetUser();
      showSuccessToast(
        tInterpolated("changeLab.success", {
          lab: labs?.find(l => l.id === selectedLab)?.name
        })
      );
      history.replace("/tabs/dashboard");
    } catch (e) {
      if (e instanceof Error) handleError(e);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/settings" />
          </IonButtons>
          <IonTitle>{t("changeLab.title")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol
              size-sm="10"
              offset-sm="1"
              size-md="6"
              offset-md="3"
              size-xl="4"
              offset-xl="4"
            >
              {!labs && <IonSkeletonText animated title={t("loadingData")} />}
              {labs && labs.length > 1 && (
                <IonRadioGroup
                  value={selectedLab}
                  onIonChange={e => setSelectedLab(e.detail.value)}
                >
                  {labs.map(lab => (
                    <IonItem key={lab.id}>
                      <Icon slot="start" icon={faHospital} />
                      <IonLabel>
                        <IonText title={lab.name}>{lab.name}</IonText>
                        <IonText color="secondary">
                          <p>
                            {lab.email}
                            {lab.website ? `, ${lab.website}` : ""}
                          </p>
                        </IonText>
                      </IonLabel>
                      <IonRadio slot="end" value={lab.id} />
                    </IonItem>
                  ))}
                </IonRadioGroup>
              )}

              <IonButton
                type="submit"
                expand="block"
                disabled={!selectedLab || selectedLab === labId}
                onClick={onLabSelected}
              >
                {t("select")}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ChangeLab;
