import i18n from "i18next";
import React, { useCallback, useContext, useEffect } from "react";
import {
  useTranslation as usei18nextTranslation,
  initReactI18next
} from "react-i18next";
import HttpApi from "i18next-http-backend";
import useLocalStorage from "../hooks/useLocalStorage";
import { InvoiceStatus } from "../models/Invoice";
import { ProductionLogStatus, CaseStatus } from "../models/Case";
import PaymentType from "../models/PaymentType";
import Role from "../models/Role";
import { ProductType } from "../models/Teeth";
import { firebaseAuth } from "../data/firebase";
import { LicenseType, SubscriptionPlanDto } from "../models/Lab";

const initTranslation = () => {
  i18n
    .use(HttpApi)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      fallbackLng: "en",
      preload: ["en"],

      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });
};

interface ContextProps {
  changeLanguage: (l: Language) => void;
  t: (key: string) => string;
  tPlural: (key: string, count: number) => string;
  tInterpolated: (key: string, data: { [key: string]: any }) => string;
  tArray: (key: string) => string[];
  tError: (errorCode: string) => string;
  tCaseStatus: (status: CaseStatus, isDr: boolean) => string;
  tCaseStatusDr: (status: CaseStatus) => string;
  tProductionLogStatus: (status: ProductionLogStatus) => string;
  tInvoiceStatus: (status: InvoiceStatus) => string;
  tRole: (status: Role) => string;
  tProductType: (type: ProductType) => string;
  tPaymentType: (type: PaymentType) => string;
  tSubscriptionPlan: (type: LicenseType) => SubscriptionPlanDto;
  tPlaceholder: (key: string) => string;
  tRequired: (key: string) => string;
  language: Language;
}

const LanguageContext = React.createContext<ContextProps>({
  changeLanguage: () => {},
  t: () => "",
  tPlural: () => "",
  tInterpolated: () => "",
  tArray: () => [],
  tError: () => "",
  tCaseStatus: () => "",
  tCaseStatusDr: () => "",
  tProductionLogStatus: () => "",
  tInvoiceStatus: () => "",
  tRole: () => "",
  tProductType: () => "",
  tPaymentType: () => "",
  tSubscriptionPlan: (license: LicenseType) => ({
    title: "",
    license,
    cases: 0,
    currency: "EUR",
    monthlyPrice: 0,
    annualPrice: 0,
    features: []
  }),
  tPlaceholder: () => "",
  tRequired: () => "",
  language: "en"
});

const useTranslation = () => useContext(LanguageContext);

export type Language =
  | "en"
  | "cs"
  | "de"
  | "da"
  | "el"
  | "es"
  | "fi"
  | "fr"
  | "hr"
  | "hu"
  | "it"
  | "nb"
  | "nl"
  | "pl"
  | "pt"
  | "ro"
  | "ru"
  | "sv"
  | "sr"
  | "tr";

interface LanguageOption {
  name: string;
  value: Language;
  flag: string;
  alt?: string[];
}
const Languages: LanguageOption[] = [
  {
    name: "English",
    value: "en",
    flag: "https://flagcdn.com/gb.svg"
  },
  {
    name: "Dansk",
    value: "da",
    flag: "https://flagcdn.com/dk.svg",
    alt: ["Danish"]
  },
  {
    name: "Deutsch",
    value: "de",
    flag: "https://flagcdn.com/de.svg",
    alt: ["German"]
  },
  {
    name: "Español",
    value: "es",
    flag: "https://flagcdn.com/es.svg",
    alt: ["Spanish"]
  },
  {
    name: "Français",
    value: "fr",
    flag: "https://flagcdn.com/fr.svg",
    alt: ["French"]
  },
  {
    name: "Hrvatski",
    value: "hr",
    flag: "https://flagcdn.com/hr.svg",
    alt: ["Croatian"]
  },
  {
    name: "Italiano",
    value: "it",
    flag: "https://flagcdn.com/it.svg",
    alt: ["Italian"]
  },
  {
    name: "Magyar",
    value: "hu",
    flag: "https://flagcdn.com/hu.svg",
    alt: ["Hungarian "]
  },
  {
    name: "Nederlands",
    value: "nl",
    flag: "https://flagcdn.com/nl.svg",
    alt: ["Dutch"]
  },
  {
    name: "Norsk",
    value: "nb",
    flag: "https://flagcdn.com/no.svg",
    alt: ["Norwegian"]
  },
  {
    name: "Polski",
    value: "pl",
    flag: "https://flagcdn.com/pl.svg",
    alt: ["Polish"]
  },
  {
    name: "Português",
    value: "pt",
    flag: "https://flagcdn.com/pt.svg",
    alt: ["Portuguese"]
  },
  {
    name: "Română",
    value: "ro",
    flag: "https://flagcdn.com/ro.svg",
    alt: ["Romanian", "Românește"]
  },
  {
    name: "Svenska",
    value: "sv",
    flag: "https://flagcdn.com/se.svg",
    alt: ["Swedish"]
  },
  {
    name: "Srpski",
    value: "sr",
    flag: "https://flagcdn.com/rs.svg",
    alt: ["Serbian"]
  },
  {
    name: "Suomi",
    value: "fi",
    flag: "https://flagcdn.com/fi.svg",
    alt: ["Finnish"]
  },
  {
    name: "Türkçe",
    value: "tr",
    flag: "https://flagcdn.com/tr.svg",
    alt: ["Turkish"]
  },
  {
    name: "Čeština",
    value: "cs",
    flag: "https://flagcdn.com/cz.svg",
    alt: ["Czech"]
  },
  {
    name: "Ελληνικά",
    value: "el",
    flag: "https://flagcdn.com/gr.svg",
    alt: ["Elliniká", "Greek"]
  },
  {
    name: "Русский",
    value: "ru",
    flag: "https://flagcdn.com/ru.svg",
    alt: ["Russian"]
  }
];

const getFirebaseLanguage = (language: string) => {
  switch (language) {
    case "nb":
      return "no";
    case "pt":
      return "pt_pt";
    default:
      return language;
  }
};

export const getDatetimeLocales = (language: Language) => {
  switch (language) {
    case "en":
      return "en-US";
    case "cs":
      return "cs-CZ";
    case "da":
      return "da-DK";
    case "el":
      return "el-GR";
    case "nb":
      return "nb-NO";
    case "sr":
      return "sr-Latn-RS";
    case "sv":
      return "se-SE";
    default:
      return `${language}-${language.toUpperCase()}`;
  }
};

export const getMomentLocales = (language: Language) => {
  switch (language) {
    default:
      return `${language}`;
  }
};

export const getFullCalendarLocale = (language: Language) => {
  switch (language) {
    case "sr":
      return "sr-Latn-RS";
    default:
      return language.replace("_", "-");
  }
};

const keyTransform = (key: string) =>
  key.includes(".") ? key : `general.${key}`;

const LanguageProvider: React.FC = ({ children }) => {
  const { t: translate, i18n } = usei18nextTranslation();
  const [language, setLanguage] = useLocalStorage<Language>(
    "lng",
    i18n.language as Language
  );

  useEffect(() => {
    i18n.changeLanguage(language);
    firebaseAuth.languageCode = getFirebaseLanguage(language);
  }, [language, i18n]);

  const t = useCallback(
    (key: string) => translate(keyTransform(key)),
    [i18n.language]
  );
  const tPlural = useCallback(
    (key: string, count: number) => translate(keyTransform(key), { count }),
    [i18n.language]
  );
  const tInterpolated = useCallback(
    (key: string, data: { [key: string]: any }) =>
      translate(keyTransform(key), data),
    [i18n.language]
  );
  const tArray: (key: string) => string[] = useCallback(
    (key: string) => translate(keyTransform(key), { returnObjects: true }),
    [i18n.language]
  );
  const tError = useCallback(
    (errorCode: string) => translate([`error.${errorCode}`, ""]),
    [i18n.language]
  );

  const tCaseStatus = useCallback(
    (status: CaseStatus, isDr: boolean) => {
      if (
        isDr &&
        [CaseStatus.OutOfLab, CaseStatus.ReadyForShippingToDr].includes(status)
      )
        return t(`caseStatus.${status}dr`);
      return t(`caseStatus.${status}`);
    },
    [t]
  );
  const tCaseStatusDr = useCallback(
    (status: CaseStatus) => {
      return t(`caseStatus.${status}`);
    },
    [t]
  );
  const tProductionLogStatus = useCallback(
    (status: ProductionLogStatus) => t(`productionLogStatus.${status}`),
    [t]
  );

  const tInvoiceStatus = useCallback(
    (status: InvoiceStatus) => t(`invoiceStatus.${status}`),
    [t]
  );
  const tRole = useCallback((role: Role) => t(`roles.${role}`), [t]);
  const tProductType = useCallback(
    (type: ProductType) => t(`productType.${type}`),
    [t]
  );
  const tPaymentType = useCallback(
    (type: PaymentType) => t(`paymentType.${type}`),
    [t]
  );
  const tPlaceholder = useCallback((propName: string) => t(propName), [t]);
  const tSubscriptionPlan: (type: LicenseType) => SubscriptionPlanDto =
    useCallback(
      (type: LicenseType) => {
        const key = `subscription.plans.${type}.`;
        const title = t(`${key}title`);
        const cases = parseInt(t(`${key}cases`));
        const monthlyPrice = parseFloat(t(`${key}monthlyPrice`));
        const annualPrice = parseFloat(t(`${key}annualPrice`));

        return {
          title,
          license: type,
          cases,
          currency: "EUR",
          monthlyPrice,
          annualPrice
        };
      },
      [t]
    );
  // tInterpolated("placeholder", { prop: t(propName) });
  const tRequired = useCallback(
    (propName: string) => tInterpolated("isRequired", { prop: t(propName) }),
    [t, tInterpolated]
  );

  return (
    <LanguageContext.Provider
      value={{
        changeLanguage: setLanguage,
        t,
        tPlural,
        tInterpolated,
        tArray,
        tError,
        tCaseStatus,
        tCaseStatusDr,
        tProductionLogStatus,
        tInvoiceStatus,
        tRole,
        tProductType,
        tPaymentType,
        tSubscriptionPlan,
        tPlaceholder,
        tRequired,
        language
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export default useTranslation;
export { initTranslation, Languages, LanguageProvider };
