import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonMenuButton,
  IonCol,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonButton
} from "@ionic/react";
import React, { useState } from "react";
import useTranslation from "../../context/LanguageProvider";
import { useAuthContext } from "../../context/AuthProvider";
import { ProductionLogFormDto } from "../../models/Case";
import ProductionLogUpdateModal from "./ProductionLogUpdateModal";
import EmployeeStartedTasks from "./EmployeeStartedTasks";
import ButtonTextIcon from "../ButtonTextIcon";
import ProductionLogTable from "./ProductionLogTable";
import EmployeeScheduledTasks from "./EmployeeScheduledTasks";

const initialProdLog = {
  id: 0,
  userId: 0,
  caseId: 0,
  taskId: 0,
  status: 0
};

const MyTasks: React.FC = () => {
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const [segment, setSegment] = useState<"myTasks" | "log">("myTasks");
  const [showFilter, setShowFilter] = useState(false);
  const [productionLogInitialData, setProductionLogInitialData] =
    useState<ProductionLogFormDto>();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{t("myTasks.title")}</IonTitle>
          {segment === "log" && (
            <IonButtons slot="primary">
              <IonButton
                color={showFilter ? "primary" : ""}
                onClick={() => setShowFilter(!showFilter)}
              >
                <ButtonTextIcon button="filter" />
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
        <IonToolbar className="header_toolbar2">
          <IonSegment
            onIonChange={e => setSegment(e.detail.value as any)}
            value={segment}
            mode="md"
          >
            <IonSegmentButton value="myTasks">
              <IonLabel>{t("myTasks.title")}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="log">
              <IonLabel>{t("workLog.title")}</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {user?.userId && segment === "myTasks" && (
          <>
            <IonRow>
              <IonCol className="flex_hide_if_empty">
                <EmployeeStartedTasks
                  employeeId={user.userId}
                  editProductionLog={setProductionLogInitialData}
                />
              </IonCol>
              <IonCol>
                <EmployeeScheduledTasks
                  employeeId={user.userId}
                  editProductionLog={setProductionLogInitialData}
                />
              </IonCol>
            </IonRow>
          </>
        )}

        {segment === "log" && user?.userId && (
          <ProductionLogTable
            initialUrlProps={{
              status: null,
              taskId: null,
              employeeId: user.userId,
              page: 1,
              pageSize: 10
            }}
            mode="employeeLogs"
            showFilterProp={showFilter}
          />
        )}

        <ProductionLogUpdateModal
          showProductionLogModal={productionLogInitialData !== undefined}
          initialData={productionLogInitialData ?? initialProdLog}
          onSuccess={() => setProductionLogInitialData(undefined)}
          onCancel={() => setProductionLogInitialData(undefined)}
        />
      </IonContent>
    </IonPage>
  );
};

export default MyTasks;
