import React, { useCallback, useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonSelectOption,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import {
  BillingIntegrationViewDto,
  BillingIntegrationFormDto,
  BillingIntegrationType
} from "../../models/Lab";
import useTranslation from "../../context/LanguageProvider";
import { useNotificationContext } from "../../context/NotificationProvider";
import useApi from "../../data/Api";
import ModalWrapper from "../ModalWrapper";
import ButtonTextIcon from "../ButtonTextIcon";
import Form from "../form/Form";
import FormIonSelect from "../form/FormIonSelect";
import { getEnumValues } from "../../data/numberHelpers";
import FormInput from "../form/FormIonInput";

interface Props {
  onSuccess: (id: number) => void;
  onCancel: () => void;
  provider?: BillingIntegrationType;
  integrations: BillingIntegrationViewDto[];
}

const BillingIntegrationModal: React.FC<Props> = ({
  onSuccess,
  onCancel,
  provider,
  integrations
}) => {
  const [submit, setSubmit] = useState<() => void>(() => {});
  const [isSubmitting, setSubmitting] = useState(false);
  const [isExisting, setIsExisting] = useState(false);
  const [initialData, setInitialData] = useState<BillingIntegrationFormDto>();
  const { apiPost } = useApi();
  const { t } = useTranslation();
  const { handleError } = useNotificationContext();

  useEffect(() => {
    if (!provider) {
      setInitialData(undefined);
      setIsExisting(false);
    } else {
      const existingIntegration = integrations.find(
        i => i.provider === provider
      );
      setInitialData(
        existingIntegration
          ? { ...existingIntegration, secret: "" }
          : {
              id: 0,
              provider: provider,
              clientId: "",
              secret: ""
            }
      );
      setIsExisting(existingIntegration !== undefined);
    }
  }, [provider, integrations]);

  const upsert = useCallback(
    (data: BillingIntegrationFormDto) =>
      apiPost<number>("billingIntegration/upsert", data)
        .then(onSuccess)
        .catch(handleError),
    [apiPost, handleError, onSuccess]
  );

  return (
    <ModalWrapper
      modalOpened={provider !== undefined}
      dismiss={onCancel}
      modal="billingIntegrationModal"
    >
      <IonModal isOpen={provider !== undefined} onDidDismiss={onCancel}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                <ButtonTextIcon button="cancel" />
              </IonButton>
            </IonButtons>
            <IonTitle>{t("integrations.title")}</IonTitle>
            <IonButtons slot="primary">
              <IonButton onClick={submit} disabled={isSubmitting}>
                <ButtonTextIcon
                  button={isExisting ? "edit" : "save"}
                  loading={isSubmitting}
                />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {initialData && (
            <Form
              initialData={initialData}
              onSubmit={upsert}
              submitButtonText={t(isExisting ? "edit" : "save")}
              exposeSubmit={setSubmit}
              setSubmitting={setSubmitting}
            >
              <FormInput hidden name="id" label="" />
              <FormIonSelect
                hidden
                name="provider"
                label={t("integrations.provider")}
              >
                {getEnumValues(BillingIntegrationType).map(type => (
                  <IonSelectOption key={type} value={type}>
                    {type}
                  </IonSelectOption>
                ))}
              </FormIonSelect>
              <FormInput name="clientId" label={t("integrations.clientId")} />
              <FormInput
                name="secret"
                label={t("integrations.clientSecret")}
                type="password"
              />
            </Form>
          )}
        </IonContent>
      </IonModal>
    </ModalWrapper>
  );
};

export default BillingIntegrationModal;
