import React, { useCallback, useState } from "react";
import PaginateSelect from "../PaginateSelect";
import { Permission } from "../../models/Permissions";
import { useAuthContext } from "../../context/AuthProvider";
import useApi from "../../data/Api";
import DoctorDto from "../../models/Doctor";
import useTranslation from "../../context/LanguageProvider";
import { useNotificationContext } from "../../context/NotificationProvider";
import PagedData from "../../models/PagedData";
import { IonButton } from "@ionic/react";
import Can from "../Can";
import Icon from "../Icon";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import DoctorUpsertModal from "./DoctorUpsertModal";
import SelectAndButton from "../SelectAndButton";
import useLab from "../../context/LabProvider";

interface Props {
  initialId?: number;
  creatable?: boolean;
  hideLabel?: boolean;
  onChange: (id: number | undefined) => void;
}

const SelectDoctor: React.FC<Props> = ({
  initialId,
  creatable,
  hideLabel,
  onChange
}) => {
  const { t } = useTranslation();
  const { apiGet } = useApi();
  const { showErrorToast, showSuccessToast } = useNotificationContext();
  const { user } = useAuthContext();
  const [showAddDoctorModal, setShowAddDoctorModal] = useState(false);
  const [newDoctorName, setNewDoctorName] = useState("");
  const [newDoctorId, setNewDoctorId] = useState<number>();
  const { doctors } = useLab();

  const onCreateDoctor = useCallback(
    (inputValue: string) => {
      if (!user?.hasPermission(Permission.DoctorCreate)) {
        showErrorToast(t("noPermissionError"));
        return 0;
      }
      setNewDoctorName(inputValue);
      setShowAddDoctorModal(true);
    },
    [showErrorToast, t, user]
  );

  const pageSize = 10;
  const loadDoctors = useCallback(
    (search: string, page: number) =>
      apiGet<PagedData<DoctorDto>>(
        `doctor/getAll?page=${page}&pageSize=${pageSize}&search=${search}`
      ),
    [apiGet]
  );

  const getDoctor = useCallback(
    (id: number) => apiGet<DoctorDto>(`doctor/get?id=${id}`),
    [apiGet]
  );

  return (
    <>
      <SelectAndButton
        label={hideLabel ? "" : t("doctor")}
        select={
          <PaginateSelect
            placeholder={t("selectDoctor")}
            pageSize={pageSize}
            value={newDoctorId}
            initialId={initialId}
            defaultOptions={doctors}
            getOption={getDoctor}
            loadOptions={loadDoctors}
            create={creatable ? onCreateDoctor : undefined}
            onChange={onChange}
            getId={d => d.id}
            getLabel={d =>
              d.clinicName ? `${d.name} (${d.clinicName})` : d.name
            }
          />
        }
        button={
          creatable && (
            <Can permission={Permission.DoctorCreate}>
              <IonButton
                color="secondary"
                fill="outline"
                className="no-icon-margin"
                onClick={() => setShowAddDoctorModal(true)}
              >
                <Icon icon={faPlus} />
              </IonButton>
            </Can>
          )
        }
      />
      <DoctorUpsertModal
        initialData={{
          id: 0,
          name: newDoctorName,
          clinicName: "",
          email: ""
        }}
        showDoctorModal={showAddDoctorModal}
        onSuccess={id => {
          showSuccessToast(t("doctors.added"));
          setShowAddDoctorModal(false);
          setNewDoctorName("");
          setNewDoctorId(id);
        }}
        onCancel={() => {
          setShowAddDoctorModal(false);
          setNewDoctorName("");
          setNewDoctorId(undefined);
        }}
      />
    </>
  );
};

export default SelectDoctor;
