import React, { useCallback, useState, useEffect } from "react";
import { IonButton, IonItem, IonLabel } from "@ionic/react";
import useTranslation from "../../context/LanguageProvider";
import { CaseProductDto } from "../../models/Case";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import Icon from "../Icon";
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import useDentalNotation from "../../hooks/useDentalNotation";
import { ProductType } from "../../models/Teeth";
import useLab from "../../context/LabProvider";

interface Props {
  products: CaseProductDto[];
  showActions: boolean;
  onEdit?: (id: number) => void;
  onDelete?: (id: number) => void;
}
interface TableProps {
  products: CaseProductDto[];
  columns: IDataTableColumn<CaseProductDto>[];
  noTableHead?: boolean;
}

const ProductsTable: React.FC<TableProps> = React.memo(
  ({ products, columns, noTableHead }) => {
    const { t } = useTranslation();

    return (
      <DataTable
        keyField="id"
        data={products}
        columns={columns}
        className="l2ower-top-padding"
        noHeader
        noTableHead={noTableHead ? true : false}
        noDataComponent={t("noRecords")}
        paginationComponentOptions={{
          rowsPerPageText: t("dataTable.rowsPerPageText"),
          rangeSeparatorText: t("dataTable.rangeSeparatorText")
        }}
      />
    );
  },
  (pe, ne) => pe.products === ne.products && pe.columns === ne.columns
);

const ProductList: React.FC<Props> = ({
  products,
  showActions,
  onEdit,
  onDelete
}) => {
  const { t, tProductType } = useTranslation();
  const { printCaseProductTeeth, printToothIds } = useDentalNotation();
  const { products: labProducts } = useLab();

  const printExtras = useCallback(
    (row: CaseProductDto) =>
      row.extras
        .map(
          e =>
            `${e.extras.name} (${printToothIds(row.productTypeId, e.toothIds)})`
        )
        .join(", "),
    [printToothIds]
  );

  const [columns, setColumns] = useState<IDataTableColumn<CaseProductDto>[]>(
    []
  );

  useEffect(() => {
    const columns: IDataTableColumn<CaseProductDto>[] = [
      {
        name: t("products.title"),
        selector: "productId",
        sortable: true,
        grow: 3,
        cell: row => (
          <IonItem
            key={row.id}
            lines="none"
            class="ion-no-padding"
            // routerLink={"/" + row.id}
          >
            <IonLabel>
              {labProducts &&
                labProducts.find(p => p.id === row.productId)?.name}
              <p className="bold ion-hide-sm-up">{printExtras(row)}</p>
              <p className="ion-hide-sm-up">{printCaseProductTeeth(row)}</p>
              <p>{tProductType(row.productTypeId)}</p>
              <p className="ion-hide-sm-up">
                {row.shade ? (
                  row.shade
                ) : (
                  <span className="warningColor">
                    {t("cases.colorNotDefined")}
                  </span>
                )}
              </p>
            </IonLabel>
          </IonItem>
        )
      },
      {
        name: t("cases.color"),
        selector: "shade",
        sortable: true,
        grow: 1,
        cell: row =>
          row.shade || row.productTypeId === ProductType.Visil ? (
            row.shade
          ) : (
            <span className="warningColor">{t("cases.colorNotDefined")}</span>
          ),
        hide: "sm"
      },
      {
        name: t("teeth"),
        selector: "toothIds",
        sortable: true,
        grow: 1,
        cell: row => printCaseProductTeeth(row),
        hide: "sm"
      },
      {
        name: t("extras.title"),
        selector: "extras",
        sortable: false,
        grow: 1,
        cell: row => printExtras(row),
        hide: "sm"
      },
      {
        name: "",
        // selector: "",
        // sortable: true,
        grow: 1,
        cell: row => (
          <>
            <IonButton
              className="no-icon-margin"
              color="danger"
              fill="clear"
              onClick={() => onDelete && onDelete(row.id)}
            >
              <Icon icon={faTimes} />
            </IonButton>
            <IonButton
              className="no-icon-margin"
              fill="clear"
              onClick={() => onEdit && onEdit(row.id)}
            >
              <Icon icon={faEdit} />
            </IonButton>
          </>
        ),
        omit: !showActions
      }
    ];
    setColumns(columns);
  }, [
    labProducts,
    onDelete,
    onEdit,
    printCaseProductTeeth,
    printExtras,
    showActions,
    t,
    tProductType
  ]);

  return products.length ? (
    <ProductsTable
      products={products}
      columns={columns}
      noTableHead={showActions}
    />
  ) : (
    <></>
  );
};

export default ProductList;
