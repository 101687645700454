import React, { useCallback, useState, useEffect } from "react";
import { IonButton, IonItem, IonLabel } from "@ionic/react";
import useTranslation from "../../context/LanguageProvider";
import { CaseHasConditionDto } from "../../models/Case";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import Icon from "../Icon";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import useDentalNotation from "../../hooks/useDentalNotation";
import { ProductType, ToothCondition } from "../../models/Teeth";

interface TableProps {
  conditions: CaseHasConditionDto[];
  columns: IDataTableColumn<CaseHasConditionDto>[];
  noTableHead?: boolean;
}

const ConditionTable: React.FC<TableProps> = React.memo(
  ({ conditions, columns, noTableHead }) => {
    const { t } = useTranslation();

    return (
      <DataTable
        keyField="toothId"
        data={conditions}
        columns={columns}
        className="l2ower-top-padding"
        noHeader
        noTableHead={noTableHead}
        noDataComponent={t("noRecords")}
        paginationComponentOptions={{
          rowsPerPageText: t("dataTable.rowsPerPageText"),
          rangeSeparatorText: t("dataTable.rangeSeparatorText")
        }}
      />
    );
  },
  (pe, ne) => pe.conditions === ne.conditions && pe.columns === ne.columns
);

interface Props {
  conditions: CaseHasConditionDto[];
  showActions: boolean;
  onDelete?: (id: number) => void;
}

const ConditionList: React.FC<Props> = ({
  conditions,
  showActions,
  onDelete
}) => {
  const { t } = useTranslation();
  const { printToothIds } = useDentalNotation();

  const [columns, setColumns] = useState<
    IDataTableColumn<CaseHasConditionDto>[]
  >([]);

  const printCondition = useCallback(
    (condition: ToothCondition) => {
      switch (condition) {
        case ToothCondition.NoTooth:
          return t("arches.noTooth");
        case ToothCondition.DeadTooth:
          return t("arches.deadTooth");
        case ToothCondition.Implant:
          return t("arches.implant");
        case ToothCondition.Hook:
          return t("arches.hook");
        default:
          return "";
      }
    },
    [t]
  );

  useEffect(() => {
    const columns: IDataTableColumn<CaseHasConditionDto>[] = [
      {
        name: t("arches.condition"),
        selector: "conditionId",
        sortable: false,
        grow: 2,
        cell: row => (
          <IonItem
            key={row.toothId}
            lines="none"
            class="ion-no-padding"
            // routerLink={"/" + row.id}
          >
            <IonLabel>
              {printCondition(row.conditionId)}
              <p className="ion-hide-sm-up">
                {printToothIds(ProductType.Crown, [row.toothId])}
              </p>
            </IonLabel>
          </IonItem>
        )
      },
      {
        name: t("teeth"),
        selector: "toothId",
        sortable: false,
        grow: 1,
        cell: row => printToothIds(ProductType.Crown, [row.toothId]),
        hide: "sm"
      },
      {
        name: "",
        grow: 1,
        cell: row => (
          <>
            <IonButton
              className="no-icon-margin"
              color="danger"
              fill="clear"
              onClick={() => onDelete && onDelete(row.toothId)}
            >
              <Icon icon={faTimes} />
            </IonButton>
          </>
        ),
        omit: !showActions
      }
    ];
    setColumns(columns);
  }, [onDelete, printToothIds, showActions, t]);

  return conditions.length ? (
    <ConditionTable
      conditions={conditions}
      columns={columns}
      noTableHead={showActions}
    />
  ) : (
    <></>
  );
};

export default ConditionList;
