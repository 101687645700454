import { IonBadge, IonLabel, IonSegmentButton } from "@ionic/react";
import React from "react";
import { useAuthContext } from "../../context/AuthProvider";
import useTranslation from "../../context/LanguageProvider";
import { CaseStatus } from "../../models/Case";
import DashboardDto from "../../models/DashboardDto";
import CaseStatusIcon from "../case/CaseStatusIcon";

interface Props {
  statuses: CaseStatus[];
  value: string;
  data: DashboardDto[];
}

const DashboardSegmentButton: React.FC<Props> = ({ statuses, value, data }) => {
  const { tCaseStatus } = useTranslation();
  const { user } = useAuthContext();

  return (
    <IonSegmentButton
      value={value}
      hidden={!data?.filter(d => statuses.includes(d.status)).length}
    >
      <IonLabel>
        <CaseStatusIcon
          status={statuses[0]}
          id={0}
          underConstruction={false}
          hideTitle
        />
        <span className="dashboard-label">
          {tCaseStatus(statuses[0], user?.doctorId !== undefined)}{" "}
        </span>
        <IonBadge>
          {data?.filter(d => statuses.includes(d.status)).length}
        </IonBadge>
      </IonLabel>
    </IonSegmentButton>
  );
};

export default DashboardSegmentButton;
