import { IonButton, IonImg } from "@ionic/react";
import React from "react";

interface Props {
  onClick: () => void;
}

const SmartBillButton: React.FC<Props> = ({ onClick }) => (
  <IonButton
    type="submit"
    size="default"
    className="smartbill-color"
    onClick={onClick}
  >
    <IonImg src={"assets/icons/smartbill.svg"} alt="" />
  </IonButton>
);

export default SmartBillButton;
