import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent,
  IonButton,
  IonMenuButton
} from "@ionic/react";
import React, { useState } from "react";
import useTranslation from "../../context/LanguageProvider";
import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";
import { ProductionLogTableUrlProps } from "../../models/UrlProps";
import ButtonTextIcon from "../ButtonTextIcon";
import ProductionLogTable from "./ProductionLogTable";

const ProductionLogs: React.FC = () => {
  const [showFilter, setShowFilter] = useState(true);
  const [triggerSaveAsPdf, setTriggerSaveAsPdf] = useState<number>(0);
  const [savingPdf, setSavingPdf] = useState(false);
  const [urlProps] = useState<ProductionLogTableUrlProps>({
    status: null,
    taskId: null,
    employeeId: null,
    page: 1,
    pageSize: 10
  });

  const { t } = useTranslation();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{t("workLog.title")}</IonTitle>
          <IonButtons slot="primary">
            <IonButton
              color={showFilter ? "primary" : ""}
              onClick={() => setShowFilter(!showFilter)}
            >
              <ButtonTextIcon
                button="filter"
                iconClass={showFilter ? "primaryColor" : ""}
              />
            </IonButton>
            <IonButton
              onClick={() => {
                setTriggerSaveAsPdf(Date.now());
              }}
              disabled={savingPdf}
            >
              <ButtonTextIcon button="download" loading={savingPdf} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ProductionLogTable
          initialUrlProps={urlProps}
          mode="allLogs"
          showFilterProp={showFilter}
          triggerSaveAsPdf={triggerSaveAsPdf}
          setSavingPdf={setSavingPdf}
        />
      </IonContent>
    </IonPage>
  );
};

export default withPermission(
  ProductionLogs,
  Permission.ProductionLogsTableRead
);
