import { IonList, IonSkeletonText, useIonViewDidEnter } from "@ionic/react";
import { RefresherEventDetail } from "@ionic/core/components";
import React, { useCallback, useEffect, useRef, useState } from "react";
import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";
import FullCalendar, {
  CalendarOptions,
  DateSelectArg
} from "@fullcalendar/react";

import enLocale from "@fullcalendar/core/locales/en-gb";
import daLocale from "@fullcalendar/core/locales/da";
import deLocale from "@fullcalendar/core/locales/de";
import esLocale from "@fullcalendar/core/locales/es";
import frLocale from "@fullcalendar/core/locales/fr";
import hrLocale from "@fullcalendar/core/locales/hr";
import itLocale from "@fullcalendar/core/locales/it";
import nbLocale from "@fullcalendar/core/locales/nb";
import nlLocale from "@fullcalendar/core/locales/nl";
import seLocale from "@fullcalendar/core/locales/sv";
import srLocale from "@fullcalendar/core/locales/sr";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";

import interactionPlugin from "@fullcalendar/interaction";
import useTranslation, {
  getFullCalendarLocale
} from "../../context/LanguageProvider";
import NoResults from "../NoResults";
import useDate from "../../hooks/useDate";
import CalendarEvent from "./CalendarEvent";
import CalendarListItem from "./CalendarListItem";
import { ProductionLogFormDto } from "../../models/Case";
import ProductionLogUpdateModal from "../productionLog/ProductionLogUpdateModal";
import Refresher from "../Refresher";
import { CalendarType, CalendarView, PlUrlProps } from "./useCalendar";
import useLocalStorage from "../../hooks/useLocalStorage";
import useCalendar from "./useCalendar";
import CalendarSettings from "./CalendarSettings";
import useLab from "../../context/LabProvider";

interface Props {
  showFilter: boolean;
  onTitleChange: (title: string) => void;
  nextClicked?: string;
  prevClicked?: string;
}

const fcOptions: CalendarOptions = {
  locales: [
    enLocale,
    daLocale,
    deLocale,
    esLocale,
    frLocale,
    hrLocale,
    itLocale,
    nbLocale,
    nlLocale,
    seLocale,
    srLocale
  ],
  expandRows: true,
  plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],

  headerToolbar: false,
  defaultTimedEventDuration: 1,
  selectable: true,
  eventTimeFormat: {
    hour: "numeric",
    minute: "2-digit",
    meridiem: false
  },
  height: "auto",
  navLinks: true,
  fixedWeekCount: false,
  showNonCurrentDates: false,
  firstDay: 1,
  dayMaxEventRows: true,
  views: {
    dayGridMonth: {
      dayMaxEventRows: 4
    },
    timeGridWeek: { dayMaxEventRows: 4, nowIndicator: true }
  }
};

const Calendar: React.FC<Props> = ({
  showFilter,
  onTitleChange,
  nextClicked,
  prevClicked
}) => {
  const filteredEventsRef = useRef<HTMLIonListElement>(null);
  const { language } = useTranslation();
  const { isMtLab } = useLab();

  const [productionLogInitialData, setProductionLogInitialData] =
    useState<ProductionLogFormDto>();
  const [showProductionLogModal, setShowProductionLogModal] = useState(false);

  useEffect(() => {
    if (productionLogInitialData) setShowProductionLogModal(true);
    else setShowProductionLogModal(false);
  }, [productionLogInitialData]);

  const { t } = useTranslation();
  const { toDateString } = useDate();
  const [fcLocale, setFcLocale] = useState("en");
  const [calendarType, setCalendarType] = useState<CalendarType>("cases");
  const [showGroupEvents, setShowGroupEvents] = useLocalStorage(
    "calendarGroupEvents",
    isMtLab
  );
  const [plUrlProps, setPlUrlProps] = useState<PlUrlProps>({});
  const [view, setView] = useLocalStorage<CalendarView>(
    "calendarView",
    "dayGridMonth"
  );

  const {
    showDates,
    calendarRef,
    selectedDate,
    loading,
    events,
    groupEvents,
    filteredEvents,
    fetchEvents,
    currentMonthUpdate,
    onShowDatesChange,
    fireSelectDate,
    setSelectedDate,
    changeEventStatus
  } = useCalendar();

  useEffect(() => {
    setFcLocale(getFullCalendarLocale(language));
  }, [language]);

  const setTitle = useCallback(() => {
    const title = calendarRef.current?.getApi().getCurrentData().viewTitle;
    title && onTitleChange(title);
  }, []);

  useEffect(() => {
    if (nextClicked) {
      calendarRef.current?.getApi().next();
      setTitle();
    }
  }, [nextClicked]);
  useEffect(() => {
    if (prevClicked) {
      calendarRef.current?.getApi().prev();
      setTitle();
    }
  }, [prevClicked]);

  const scrollToEventsList = useCallback(() => {
    if (calendarType === "cases") return;
    filteredEventsRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [calendarType]);

  const refreshEvents = useCallback(
    (refreshEvent?: CustomEvent<RefresherEventDetail>) => {
      console.log("refreash");
      const calendarDay = calendarRef.current
        ?.getApi()
        .view.activeStart?.toISOString();
      calendarDay &&
        fetchEvents(
          calendarDay,
          showDates,
          calendarType,
          plUrlProps,
          refreshEvent
        );
    },
    [calendarRef, calendarType, fetchEvents, plUrlProps, showDates]
  );

  useIonViewDidEnter(() => {
    currentMonthUpdate(showDates, calendarType, plUrlProps);
    calendarRef.current?.getApi().today();
    setTitle();
  }, [currentMonthUpdate]);

  return (
    <>
      <Refresher onRefresh={refreshEvents} />
      {showFilter && (
        <CalendarSettings
          calendarType={calendarType}
          setCalendarType={setCalendarType}
          showDates={showDates}
          onShowDatesChange={s =>
            onShowDatesChange(s, calendarType, plUrlProps)
          }
          showGroupEvents={showGroupEvents}
          setShowGroupEvents={setShowGroupEvents}
          plUrlProps={plUrlProps}
          setPlUrlProps={setPlUrlProps}
          view={view}
          setView={v => {
            setView(v);
            calendarRef.current?.getApi().changeView(v);
          }}
        />
      )}

      <FullCalendar
        {...fcOptions}
        initialView={view}
        allDaySlot={false}
        slotMinTime="05:00:00"
        slotMaxTime="23:00:00"
        ref={calendarRef}
        locale={fcLocale}
        stickyHeaderDates={false}
        select={({ start }: DateSelectArg) => {
          setSelectedDate(start);
          scrollToEventsList();
        }}
        eventClick={({ event: { start } }) => start && fireSelectDate(start)}
        eventContent={e => (
          <CalendarEvent {...e} onStatusChange={changeEventStatus} />
        )}
        events={
          calendarType === "cases" && showGroupEvents ? groupEvents : events
        }
        datesSet={({ end }) =>
          currentMonthUpdate(showDates, calendarType, plUrlProps, end)
        }
        navLinkDayClick={fireSelectDate}
        moreLinkClick={({ date }) => fireSelectDate(date)}
      />
      {loading && <IonSkeletonText animated title={t("loading")} />}
      <IonList
        class="box"
        id="events"
        className="cal_list_items ion-hide-sm-up"
        hidden={!filteredEvents}
        ref={filteredEventsRef}
      >
        <h3 className="content-font">
          {toDateString(selectedDate.toString())}
        </h3>
        {filteredEvents?.map(fe => (
          <IonList key={fe.time}>
            <h4 className={"date-header"}>{fe.time}</h4>
            {fe.events.map((ev, i) => (
              <CalendarListItem
                key={i}
                {...ev}
                editProductionLog={setProductionLogInitialData}
                onStatusChange={changeEventStatus}
              />
            ))}
          </IonList>
        ))}

        {!filteredEvents?.length && (
          <NoResults title={t("calendar.noEvents")} />
        )}
      </IonList>
      {productionLogInitialData && (
        <ProductionLogUpdateModal
          showProductionLogModal={showProductionLogModal}
          initialData={productionLogInitialData}
          onSuccess={() => {
            setShowProductionLogModal(false);
            refreshEvents();
          }}
          onCancel={() => setShowProductionLogModal(false)}
        />
      )}
    </>
  );
};

export default withPermission(Calendar, Permission.CaseRead);
