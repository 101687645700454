import React, { useCallback, useEffect, useState } from "react";
import { distinct } from "../data/stringHelpers";

export const colorPalette = [
  "#5B8FF9",
  "#CDDDFD",
  "#5AD8A6",
  "#CDF3E4",
  "#5D7092",
  "#CED4DE",
  "#F6BD16",
  "#FCEBB9",
  "#6F5EF9",
  "#D3CEFD",
  "#6DC8EC",
  "#D3EEF9",
  "#945FB9",
  "#DECFEA",
  "#FF9845",
  "#FFE0C7",
  "#1E9493",
  "#BBDEDE",
  "#FF99C3",
  "#FFE0ED",
  "#B8E1FF",
  "#9AC5FF",
  "#7DAAFF",
  "#3D76DD",
  "#085EC0",
  "#0047A5",
  "#00318A",
  "#001D70"
];

const useColorPalette: <T>(
  valueGetter: (v: T) => string
) => [
  (value: any) => string | undefined,
  React.Dispatch<React.SetStateAction<T[]>>
] = <T>(valueGetter: (v: T) => string) => {
  const [colorsMap, setColorsMap] = useState<{ name: string; color: string }[]>(
    []
  );

  const [values, setValues] = useState<T[]>([]);

  useEffect(() => {
    const m = distinct(values.map(valueGetter)).map((name, i) => ({
      name: name,
      color: colorPalette[i]
    }));

    setColorsMap(m);
  }, [values]);

  const getColor = useCallback(
    (value: T) => {
      const name = valueGetter(value);
      return colorsMap.find(cm => cm.name === name)?.color;
    },
    [colorsMap]
  );

  return [getColor, setValues];
};

export default useColorPalette;
