import React from "react";
import { IonRow, IonCol, IonGrid } from "@ionic/react";
import Logo from "../../components/Logo";
import LanguageSelect from "../LanguageSelect";

interface Props {}

const AuthLayout: React.FC<Props> = ({ children }) => {
  return (
    <IonGrid>
      <IonRow className="ion-align-item-center">
        <IonCol
          size-sm="10"
          offset-sm="1"
          size-lg="8"
          offset-lg="2"
          size-xl="6"
          offset-xl="3"
          className="ion-align-self-center"
        >
          <IonGrid>
            <IonRow className="auth-box">
              <IonCol className="ion-align-self-center">
                <Logo style={{ width: 100, margin: "0 auto" }} />
                <div className="ion-margin-top ion-text-center">
                  <IonCol></IonCol>
                  <div className="ion-text-center">
                    <LanguageSelect darkBg />
                  </div>
                </div>
              </IonCol>
              <IonCol className="right ion-align-self-center">
                <div>{children}</div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default AuthLayout;
