import React, { useState, useContext, useCallback, useEffect } from "react";
import useApi from "../data/Api";
import {
  getFirestoreDoc,
  onFirestoreSnapshot,
  onFirestoreSnapshotArray,
  updateFirestore
} from "../data/firebase";
import { MyTasksSummaryDto } from "../models/Case";
import { CourierListDto } from "../models/Courier";
import DashboardDto from "../models/DashboardDto";
import DoctorDto from "../models/Doctor";
import Employee from "../models/Employee";
import LabDto, {
  BillingIntegrationViewDto,
  SubscriptionDto
} from "../models/Lab";
import { MaterialDto } from "../models/Materials";
import ProductDto, { ExtraDto } from "../models/Product";
import { TaskListDto } from "../models/Task";
import { useAuthContext } from "./AuthProvider";
import { useNotificationContext } from "./NotificationProvider";

interface ContextProps {
  lab?: LabDto;
  isMtLab: boolean;
  couriers: CourierListDto[];
  subscription?: SubscriptionDto;
  loadingLab: boolean;
  logoImg?: string;
  loadingLogo: boolean;
  myTasksSummary?: MyTasksSummaryDto;
  dashboardData?: DashboardDto[];
  doctors: DoctorDto[];
  employees: Employee[];
  tasks: TaskListDto[];
  products: ProductDto[];
  extras: ExtraDto[];
  materials: MaterialDto[];
  resetLab: (lab: LabDto) => void;
  fetchSubscription: () => Promise<void>;
}

const LabContext = React.createContext<ContextProps>({
  lab: undefined,
  isMtLab: false,
  couriers: [],
  subscription: undefined,
  loadingLab: false,
  logoImg: undefined,
  loadingLogo: false,
  doctors: [],
  employees: [],
  tasks: [],
  products: [],
  extras: [],
  materials: [],
  resetLab: () => {},
  fetchSubscription: () => Promise.resolve()
});

const LabConsumer = LabContext.Consumer;
const useLab = () => useContext(LabContext);

const LabProvider: React.FC = ({ children }) => {
  const [lab, setLab] = useState<LabDto>();
  const [couriers, setCouriers] = useState<CourierListDto[]>([]);
  const [subscription, setSubscription] = useState<SubscriptionDto>();
  const [loadingLab, setLoadingLab] = useState(false);
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [logoImg, setLogoImg] = useState<string>();

  const { labId, user } = useAuthContext();
  const { apiGet, apiGetImage } = useApi();
  const { handleError } = useNotificationContext();

  const [myTasksSummary, setMyTasksSummary] = useState<MyTasksSummaryDto>();
  const [dashboardData, setDashboardData] = useState<DashboardDto[]>();
  const [doctors, setDoctors] = useState<DoctorDto[]>([]);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [products, setProducts] = useState<ProductDto[]>([]);
  const [extras, setExtras] = useState<ExtraDto[]>([]);
  const [tasks, setTasks] = useState<TaskListDto[]>([]);
  const [materials, setMaterials] = useState<MaterialDto[]>([]);

  const fetchLab = useCallback(
    () =>
      apiGet<LabDto>("lab/get")
        .then(setLab)
        .catch(e => {
          handleError(e);
          setLab(undefined);
        }),
    [apiGet, handleError]
  );

  const fetchSubscription = useCallback(
    () =>
      apiGet<SubscriptionDto>("subscription/get")
        .then(setSubscription)
        .catch(e => {
          handleError(e);
          setSubscription(undefined);
        }),
    [apiGet, handleError]
  );

  const refreashData = useCallback(() => {
    if (!labId) {
      setLoadingLab(false);
      setLab(undefined);
      setSubscription(undefined);
      setDoctors([]);
      setEmployees([]);
      setTasks([]);
      setProducts([]);
      setExtras([]);
      setMaterials([]);
      setCouriers([]);
      return;
    }

    setLoadingLab(true);
    Promise.all([fetchLab(), fetchSubscription()]).finally(() =>
      setLoadingLab(false)
    );
  }, [fetchLab, fetchSubscription, labId]);

  useEffect(() => {
    refreashData();
  }, [refreashData, labId]);

  useEffect(() => {
    setLoadingLogo(true);
    if (labId) {
      apiGetImage("lab/getLogo")
        .then(logo => setLogoImg(logo ? URL.createObjectURL(logo) : undefined))
        .catch(handleError)
        .finally(() => setLoadingLogo(false));
    } else {
      setLogoImg(undefined);
      setLoadingLogo(false);
    }
  }, [apiGetImage, handleError, labId]);

  const resetLab = useCallback((lab: LabDto) => {
    setLab(lab);
    setLogoImg(lab.logo ? URL.createObjectURL(lab.logo) : undefined);
  }, []);

  useEffect(() => {
    if (!labId || !user?.userId) return;

    return onFirestoreSnapshotArray<MyTasksSummaryDto>("mytasks", labId, docs =>
      setMyTasksSummary(docs.find(d => d.userId === user.userId))
    );
  }, [labId, user?.userId]);

  useEffect(() => {
    if (
      dashboardData &&
      dashboardData.length > 0 &&
      !("lastMessage" in dashboardData[0])
    ) {
      const newData: Record<number, DashboardDto> = {};
      dashboardData.forEach((d, i) => {
        getFirestoreDoc("caseChat", d.caseId).then(chatDoc => {
          const chat = chatDoc.data();
          newData[d.caseId] =
            chat && chat.lastMessage
              ? {
                  ...d,
                  lastMessage: chat.lastMessage,
                  lastMessageAuthor: chat.lastMessageAuthor,
                  lastMessageTime: chat.lastMessageTime
                }
              : { ...d, lastMessage: "" };

          if (dashboardData.length === i + 1 && labId) {
            updateFirestore("dashboard", labId, newData);
          }
        });
      });
    }
  }, [dashboardData, labId]);

  useEffect(() => {
    if (!labId) return;

    return onFirestoreSnapshotArray<DashboardDto>("dashboard", labId, data =>
      // get only cases that are for this doctor
      setDashboardData(
        user?.doctorId ? data.filter(d => d.doctorId === user.doctorId) : data
      )
    );
  }, [labId, user?.doctorId]);

  useEffect(() => {
    if (!labId) return;

    return onFirestoreSnapshot("lab", labId, dbData => {
      setDoctors(dbData?.doctors ?? []);
      setTasks(dbData?.tasks ?? []);
      setCouriers(
        dbData?.couriers.filter((d: CourierListDto) => d.active === true) ?? []
      );
      setEmployees(dbData?.employees ?? []);
      setProducts(dbData?.products ?? []);
      setExtras(dbData?.extras ?? []);
      setMaterials(dbData?.materials ?? []);
    });
  }, [labId]);

  return (
    <LabContext.Provider
      value={{
        lab,
        isMtLab: labId === 84,
        couriers,
        subscription,
        loadingLab,
        logoImg,
        loadingLogo,
        myTasksSummary,
        dashboardData,
        doctors,
        employees,
        tasks,
        products,
        extras,
        materials,
        resetLab,
        fetchSubscription
      }}
    >
      {children}
    </LabContext.Provider>
  );
};

const useLabIntegrations = () => {
  const { apiGet } = useApi();
  const { handleError } = useNotificationContext();
  const [loading, setLoading] = useState(false);

  const [integrations, setIntegrations] = useState<BillingIntegrationViewDto[]>(
    []
  );

  const fetchIntegrations = useCallback(() => {
    setLoading(true);
    return apiGet<BillingIntegrationViewDto[]>("billingIntegration/getAll")
      .then(setIntegrations)
      .catch(handleError)
      .finally(() => setLoading(false));
  }, [apiGet, handleError]);

  useEffect(() => {
    fetchIntegrations();
  }, [fetchIntegrations]);

  return { integrations, loading, fetchIntegrations };
};

export { LabProvider, LabConsumer, useLabIntegrations };
export default useLab;
