import React, { useCallback, useEffect, useState } from "react";
import {
  faEdit,
  faLink,
  faPlus,
  faUserTie
} from "@fortawesome/free-solid-svg-icons";
import { RefresherEventDetail } from "@ionic/core/components";
import { IonButton, IonCheckbox, IonItem, IonLabel } from "@ionic/react";
import useTranslation from "../../context/LanguageProvider";
import DoctorDto, { DoctorInLabDto } from "../../models/Doctor";
import { Permission } from "../../models/Permissions";
import Can from "../Can";
import Icon from "../Icon";
import MiddleBox from "../MiddleBox";
import Refresher from "../Refresher";
import ValueLabel from "../ValueLabel";
import { APP_URL } from "../../data/Constants";
import { useNotificationContext } from "../../context/NotificationProvider";

interface Props {
  fetchDoctor: (
    refreshEvent?: CustomEvent<RefresherEventDetail>
  ) => Promise<void> | undefined;
  loading: boolean;
  doctorData: DoctorDto;
  doctorAccess: DoctorInLabDto | undefined;
  setShowEditModal: (v: boolean) => void;
  setShowInvitationModal: (v: boolean) => void;
}

const DoctorInfo: React.FC<Props> = ({
  fetchDoctor,
  setShowEditModal,
  setShowInvitationModal,
  loading,
  doctorData,
  doctorAccess
}) => {
  const { handleError, showSuccessToast } = useNotificationContext();
  const { t, tInterpolated } = useTranslation();
  const [invitationLink, setInvitationLink] = useState("");

  useEffect(
    () =>
      setInvitationLink(
        `${APP_URL}invitation?code=${doctorAccess?.invitationCode}`
      ),
    [doctorAccess?.invitationCode]
  );

  const copyInvitationCodeToClipboard = useCallback(
    () =>
      navigator.clipboard
        .writeText(invitationLink)
        .then(() =>
          showSuccessToast(
            tInterpolated("copied", {
              prop: invitationLink
            })
          )
        )
        .catch(handleError),
    [handleError, showSuccessToast, tInterpolated, invitationLink]
  );

  return (
    <>
      <Refresher onRefresh={fetchDoctor} />
      <MiddleBox className="box" hidden={loading}>
        <div className="ion-padding">
          <div className="ion-text-center ion-margin-top">
            <Icon icon={faUserTie} size="4x" className="secondaryColor" />
            <h3>{doctorData.name}</h3>
            <ValueLabel title={t("clinicName")}>
              {doctorData.clinicName}
            </ValueLabel>
            {doctorData.email && (
              <ValueLabel title={t("email")}>{doctorData.email}</ValueLabel>
            )}
            {doctorData.phone && (
              <ValueLabel title={t("phone")}>{doctorData.phone}</ValueLabel>
            )}
            {doctorData.address && (
              <ValueLabel title={t("address")}>{doctorData.address}</ValueLabel>
            )}

            {doctorData.smartBillId && (
              <ValueLabel title={t("integrations.smartBillId")}>
                {doctorData.smartBillId}
              </ValueLabel>
            )}

            <Can permission={Permission.DoctorUpdate}>
              <IonButton
                className="ion-margin-bottom"
                color="secondary"
                onClick={() => setShowEditModal(true)}
              >
                <Icon icon={faEdit} /> {t("edit")}
              </IonButton>
              {doctorAccess && !doctorAccess.userId && (
                <>
                  {doctorAccess.invitationCode ? (
                    <ValueLabel title={t("invitation.code")}>
                      <h3>
                        {doctorAccess.invitationCode}
                        {"\t"}
                        <Icon
                          size="1x"
                          icon={faLink}
                          onClick={copyInvitationCodeToClipboard}
                          title={invitationLink}
                        />
                      </h3>
                    </ValueLabel>
                  ) : (
                    <IonButton
                      expand="block"
                      color="secondary"
                      onClick={() => setShowInvitationModal(true)}
                    >
                      <Icon icon={faPlus} /> {t("doctors.createUserAccess")}
                    </IonButton>
                  )}
                </>
              )}
            </Can>
          </div>
        </div>
      </MiddleBox>
      {doctorAccess && doctorAccess.userId > 0 && (
        <MiddleBox className="box">
          <IonItem>
            <IonCheckbox
              disabled
              slot="start"
              color="primary"
              checked={doctorAccess.allowAccess}
            />
            <IonLabel> {t("doctors.allowAccess")}</IonLabel>
          </IonItem>
          <IonItem>
            <IonCheckbox
              disabled
              slot="start"
              color="primary"
              checked={doctorAccess.canCreateCase}
            />
            <IonLabel> {t("doctors.allowCreateCase")}</IonLabel>
          </IonItem>
          <IonItem>
            <IonCheckbox
              disabled
              slot="start"
              color="primary"
              checked={doctorAccess.canViewInvoices}
            />
            <IonLabel> {t("doctors.allowViewInvoices")}</IonLabel>
          </IonItem>
          <IonItem>
            <IonCheckbox
              disabled
              slot="start"
              color="primary"
              checked={doctorAccess.canViewProductionLogs}
            />
            <IonLabel> {t("doctors.allowViewProductionLogs")}</IonLabel>
          </IonItem>
          <IonButton
            class="ion-margin-top"
            color="warning"
            expand="block"
            onClick={() => setShowInvitationModal(true)}
          >
            <Icon icon={faEdit} />
            {t("edit")}
          </IonButton>
        </MiddleBox>
      )}
    </>
  );
};

export default DoctorInfo;
