import React from "react";
import useLab from "../../context/LabProvider";

interface Props {
  labs: number[];
  children: React.ReactNode;
}

const ShowForLabs: React.FC<Props> = ({ labs, children }) => {
  const { lab } = useLab();

  // return component if it is not a doctor
  if (lab && labs.includes(lab.id)) return <>{children}</>;

  return null;
};

export default ShowForLabs;
